import React, { useRef } from "react";
import { Button, Typography, Carousel, Row, Image, Card, Tag, Col } from "antd";
import { Content } from "antd/lib/layout/layout";

const { Title } = Typography;
const { pubmats } = require("../../Data/pubmats.json");


export const CarouselItem = ({item}) => {

  return (
    <div>
      <Row justify={"center"} align={"middle"} style={{padding: "2%"}}>
        <Card
          hoverable
          cover={
            <Image
              src={require(`../../Assets/Images/datahub/pubmats/${item.logo}.png`)}
              alt="airmove-logo"
              preview={false}
            />
          }
        >
          {item.tags.map((tag)=><Tag>{tag}</Tag>)}
        </Card>
      </Row>
    </div>
  )
};


export const ContentThree = () => {
  const carouselRef = useRef(null);
  
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  const prevSlide = () => {
    carouselRef.current.prev();
  };

  const nextSlide = () => {
    carouselRef.current.next();
  };

  return (
    <Content className="landing-third">
      <Title level={3} className="landing-label-white" style={{fontWeight: "bolder"}}>
        Sustainable Development Goals
      </Title>
      {/* <Title level={5} className="landing-label-white" style={{fontWeight: "bolder"}}>
        Insert description for SDGs
      </Title> */}
      <Row justify={"center"}>
        <Col xl={{span: 8}} sm={{span: 20}}>
          <Carousel
            ref={carouselRef}
            afterChange={onChange}
            style={{paddingTop: "2%", paddingBottom: "5%"}}
          >
            {pubmats.map((item) => {
              // console.log("pubmat item", item)
              return (
                <CarouselItem item={item}/>
              )
            })}
          </Carousel>
          <Button onClick={prevSlide} style={{ position: 'absolute', top: '50%', left: -40, transform: 'translateY(-50%)' }}>
            {"<"}
          </Button>
          <Button onClick={nextSlide} style={{ position: 'absolute', top: '50%', right: -40, transform: 'translateY(-50%)' }}>
            {">"}
          </Button>
        </Col>
      </Row>
    </Content>
  )
};
