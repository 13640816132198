import { DownloadOutlined } from "@ant-design/icons";
import { Typography, Row, Col, Space, Table, Tooltip } from "antd";
import { Content } from "antd/lib/layout/layout";

const { Title, Text } = Typography;


export const ContentFour = () => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <Title className="landing-label-primary" level={4}>{text}</Title>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: "70%",
      render: (text) => <Text className="landing-label-primary" style={{fontSize: 12}}>{text}</Text>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title={`Download ${record.name}`}>
            <a class={!record.filename && "disabled-link"} onClick={()=>window.open(`../../Pdfs/${record.filename}`, '_blank')}>
              <DownloadOutlined  style={{fontSize: "16px"}} />
            </a>
          </Tooltip>
          {/* <DownloadButton/> */}
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: '1',
      name: 'The Airmove Web Platform: Technical Manual and User Guide',
      // description: "The Technical Manual and User Guide includes the design and development of Airmove's Web Platform, information about data that are contained in it and provides clear instructions on the operation of the platform. How",
      description: <div>
        <div>The Technical Manual and User Guide includes the design and development of Airmove's Web Platform, information about data that are contained in it and provides clear instructions on the operation of the platform. </div>
        <div>How to cite: 
          <strong> UP TCAGP (2024). The Airmove Web Platform - Technical Manual and User Guide.pdf</strong>
        </div></div>,
      filename: "The Airmove Web Platform - Technical Manual and User Guide.pdf"
      // tags: ['nice', 'developer'],
    },
    {
      key: '2',
      name: 'Exercise 1: Google Earth Engine and GIS Data Portals',
      description:<div>
      <div>Training Manual - Laboratory Exercise 1</div>
      <div>How to cite: 
        <strong> UP TCAGP (2024). Ex1_Google Earth Engine GIS Data Portals.pdf</strong>
      </div></div>,
      filename: "Ex1_Google Earth Engine GIS Data Portals.pdf"
    },
    {
      key: '2',
      name: 'Exercise 2: QGIS for RS and GIS Data Processing',
      description:<div>
      <div>Training Manual - Laboratory Exercise 2</div>
      <div>How to cite: 
        <strong> UP TCAGP (2024). Ex2_QGIS for RS and GIS Data Processing.pdf</strong>
      </div></div>,
      filename: "Ex2_QGIS for RS and GIS Data Processing.pdf"
    },
    {
      key: '3',
      name: 'Exercise 3: Visualizing Air Quality Data',
      description:<div>
      <div>Training Manual - Laboratory Exercise 3</div>
      <div>How to cite: 
        <strong> UP TCAGP (2024). Data Visualization.pdf</strong>
      </div></div>,
      filename: "Ex3_Data Visualization.pdf"
    },
    {
      key: '4',
      name: 'Exercise 4: Satellite Image Processing for Sentinel-5P and MODIS',
      description:<div>
      <div>Training Manual - Laboratory Exercise 4</div>
      <div>How to cite: 
        <strong> UP TCAGP (2024). Ex4_SIP for S5PMODIS.pdf</strong>
      </div></div>,
      filename: "Ex4_SIP for S5PMODIS.pdf"
    },
    {
      key: '5',
      name: 'Exercise 5: Navigating the AiRMoVE WebGIS',
      description:<div>
      <div>Training Manual - Laboratory Exercise 5</div>
      <div>How to cite: 
        <strong> UP TCAGP (2024). Ex5_AiRMoVE WebGIS.pdf</strong>
      </div></div>,
      filename: "Ex5_AiRMoVE WebGIS.pdf"
    },
  ];
  
  return (
    <Content className="landing-second">
      <Title level={3} className="landing-label-primary" style={{fontWeight: "bolder"}}>
        Technical Manual and Training Manuals
      </Title>
      <Title level={5} className="landing-label-primary" style={{fontWeight: "bolder"}}>
        The AiRMoVE Technical Documentation contains detailed informaton about the Project.
        It includes several step-by-step guides on our products and the toolkits that the project used in processing its outputs.
      </Title>
      <Row gutter={50} justify={"center"} style={{paddingTop: "2%"}}>
        <Col lg={{span: 20}} md={{span: 24}}>
          <Table
            style={{width: "100%"}}
            columns={columns}
            dataSource={data}
            pagination={false}
            showHeader={false}
            bordered
            scroll={{
              x: 500,
            }}
            // onRow={(record, index) => ({
            //   style: {
            //       // background: index === 0 ? 'red' : 'blue',
            //       padding: index === 0 ? '2%' : 0,
            //   }
            // })}
            // rowClassName={'table-row-dark'}
            // expandable={{
            //   expandedRowRender,
            //   defaultExpandedRowKeys: ['0'],
            // }}
          />
        </Col>
      </Row>
    </Content>
  )
};
