import React, { createContext, useState } from 'react';
import { getWindowDimensions } from '../Components/Layout/Functions';

export const WindowContext = createContext();

const WindowContextProvider = (props) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [datasetsOpen, setDatasetsOpen] = useState(false)
    const [legendsOpen, setLegendsOpen] = useState(false)

    return (
        <WindowContext.Provider 
            value={{
                windowDimensions, setWindowDimensions,
                datasetsOpen, setDatasetsOpen,
                legendsOpen, setLegendsOpen,
            }}
        >
            {props.children}
        </WindowContext.Provider>
    );
}

export default WindowContextProvider;