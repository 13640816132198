import React, { useContext, useEffect, useState } from "react";
import {
  Marker,
  Tooltip as MapTooltip,
} from 'react-leaflet';
import { Row, Col, Typography, Card } from 'antd';

// import constants and functions, context, components
import { transformLocation, roundTo } from "../Functions";
import { MapContext } from "../../../Contexts/MapContext";
import '../../../Assets/Styles/StationMarker.css';
import _ from 'lodash';
import * as url from "../../../Urls"
import { defaultIcon, scaledDefaultIcon } from "../../../Components/MapIconSquare";
// import { SensorIcon } from "../../../Components/MapIconSquare";


const CARE_API_URL = url.CARE_API_URL

const LowCostSensorMarker = ({data}) => {
  // console.log("data", data)
  const { 
    viewDownloadableFileData, setViewDownloadableFileData,
    lowCostSensorsData,
  } = useContext(MapContext)
  const project_name = data.mapping[0]["database_name"]
  const source = data.mapping[0]["source"]
  const [cardInfo, setCardInfo] = useState({})

  let isViewed = _.isEqual(data.location_name, viewDownloadableFileData?.location_name)
  const loadCareEndpoints = async (project_name, source) => {
    const CARE_URL = CARE_API_URL + "sensorinsights/" + project_name + `/data?source=${source}`
    // console.log("CARE_URL", CARE_URL)

    fetch(CARE_URL)
    .then((res) => {
      if (!res.ok) {
        throw new Error(
          // catches error for non-existent endpoints
          `This is an HTTP error: The status is ${res.status}`
        );
      }
      return res.json();
    })
    .then((body) => {
      // console.log("CARE_URL LCS", body)
      setCardInfo(body)
      
    })
    .catch((e) => {
      console.log("error", e)
    })
    .finally(() => {
      // setLoading(false);
    });
  };
  useEffect(() => {
    loadCareEndpoints(project_name, source)
  }, [])

  const CardContent = ({cardData}) => {
    // console.log("cardData:, ", cardData)
    return (
      <>
        {cardData.dataCount > 0 && Object.entries(cardData.data[0]).map(([key, value]) => {
          if (["location_id", "org_id", "mapping"].includes(key)) { return }
          if (!value) { return }
            return (
              <Row className="card-row" key={key}>
                <Col><Typography>{key.charAt(0).toUpperCase() + key.slice(1)}</Typography></Col>
                <Col>
                  <Typography>
                    {key == "location" ? roundTo(transformLocation(value)[0], 3) + ", " + roundTo(transformLocation(value)[1], 3) : value}
                  </Typography>
                </Col>
              </Row>
            )
          })
        }
      </>
    )
  };

  return (
    data.latitude && 
    data.longitude && 
    <Marker
      position={[data.latitude, data.longitude]} 
      icon={isViewed? scaledDefaultIcon: defaultIcon}
      key={data.location_name}
      eventHandlers={{
        click: (e) => {
          if (isViewed) {
            setViewDownloadableFileData()
          } else {
            setViewDownloadableFileData(lowCostSensorsData.results.find(m => m.location_name === data.location_name))
          }
        }
      }}
    >
      {/* MapTooltip is for guide only */}
      <MapTooltip direction="right" offset={[0, 0]}>
        <Card title={data.location_name}>
          <div className="card-div">
            {Object.keys(cardInfo).length > 0 && <CardContent cardData={cardInfo}/>}
          </div>
        </Card>
      </MapTooltip>
    </Marker>
  )
};

export default LowCostSensorMarker;
