import { Typography, Col, Image, Row, Card } from "antd";
import { Content } from "antd/lib/layout/layout";
import Ramos from "../../Assets/Images/team/ramos.jpg"
import Cayetano from "../../Assets/Images/team/cayetano.png"
import Calzado from "../../Assets/Images/team/yumul.jpg"
import Tamondong from "../../Assets/Images/team/tamondong.jpg"
import Carcellar from "../../Assets/Images/team/carcellar.jpg"
import Torres from "../../Assets/Images/team/torres.jpg"
import Panlilio from "../../Assets/Images/team/panlilio.png"
import Recto from "../../Assets/Images/team/recto.jpg"
import StaAna from "../../Assets/Images/team/staana.jpg"
import Tinio from "../../Assets/Images/team/tinio.jpg"

const { Title,Text } = Typography;
const { Meta } = Card;

/*
 *** card items
 */
 const CardItems = [
  {
    id: 1,
    title: <Text className="pre-line-name">Engr. Roseanne V. Ramos</Text>,
    description: "Project Leaders",
    imgSrc: Ramos,
    emailAddress: "rvramos@up.edu.ph",
  },
  {
    id: 2,
    title: <Text className="pre-line-name">Dr. Mylene G. Cayetano</Text>,
    description: "Project Staff",
    imgSrc: Cayetano,
    emailAddress: "mcayetano@iesm.upd.edu.ph"
  },
  {
    id: 3,
    title: <Text className="pre-line-name">Teodora Yumul-Calzado</Text>,
    description: "Project Development Officer",
    imgSrc: Calzado,
    emailAddress: "tpyumul@up.edu.ph"
  },
  {
    id: 4,
    title: <Text className="pre-line-name">Ayin Tamondong, Ph.D.</Text>,
    description: "Project Staff",
    imgSrc: Tamondong,
    emailAddress: "amtamondong@up.edu.ph"
  },
  {
    id: 5,
    title: <Text className="pre-line-name">Engr. Bienvenido Carcellar, Ms Eng, MSc</Text>,
    description: "Project Staff",
    imgSrc: Carcellar,
    emailAddress: "bgcarcellar@up.edu.ph"
  },
  {
    id: 6,
    title: <Text className="pre-line-name">Engr. Rodyvito Angelo B. Torres</Text>,
    description: "Senior Science Research Specialist",
    imgSrc: Torres,
    emailAddress: "torresrodyvito@gmail.com"
  },
  {
    id: 7,
    title: <Text className="pre-line-name">Engr. Katelene P. Panlilio</Text>,
    description: "Science Research Specialist II",
    imgSrc: Panlilio,
    emailAddress: "kppanlilio@gmail.com"
  },
  {
    id: 8,
    title: <Text className="pre-line-name">Engr. Bernadette Anne B. Recto</Text>,
    description: "Project Staff",
    imgSrc: Recto,
    emailAddress: "bbrecto@up.edu.ph"
  },
  {
    id: 9,
    title: <Text className="pre-line-name">Engr. Raymund Rhommel C. Sta. Ana</Text>,
    description: "Remote Sensing and GIS Specialist",
    imgSrc: StaAna,
    emailAddress: "raasdafsa@gmail.com"
  },
  {
    id: 10,
    title: <Text className="pre-line-name">Ma. Louella P. Tinio</Text>,
    description: "Visual Communication Specialist",
    imgSrc: Tinio,
    emailAddress: "mptinio@up.edu.ph"
  },
  
];

const LandingCard = (props) => {
  let title = props.title
  let description = props.description
  let imgSrc = props.imgSrc
  let emailAddress = props.emailAddress

  return (
    <Col lg={{span: 6}} md={{span: 12}} xs={{span: 24}}
      style={{
        maxWidth: 300,
        padding: "1rem",
        marginBlock: "1rem"
      }}
    >
      <Card
        hoverable
        style={{
          maxWidth: 300,
          height: "100%",
        }}
        cover={<img alt={description} src={imgSrc}/>}
      >
        <Meta title={title} description={description}/>
        <Text>{emailAddress}</Text>
      </Card>
    </Col>
  )
};

export const MeetTheTeam = () => {
  
  return (
    <Content className="landing-second">
      <Title level={3} className="landing-label-primary" style={{fontWeight: "bolder", textAlign: "center"}}>
        Meet The Team
      </Title>
      {/* <Title level={5} className="landing-label-primary" style={{fontWeight: "bolder"}}>
        The AiRMoVE Technical Documentation contains detailed informaton about the Project.
        It includes several step-by-step guides on our products and the toolkits that the project used in processing its outputs.
      </Title> */}
      <Row justify="space-around">
        {CardItems.map((item) => {
          return (
            <LandingCard
              key={item.id}
              title={item.title}
              description={item.description}
              imgSrc={item.imgSrc}
              emailAddress={item.emailAddress}
            />
          )
        })}
      </Row>
    </Content>
  )
};
