import React from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import { Layout, Tooltip, BackTop } from "antd";
import 'antd/dist/antd.css';
import './index.css';
import Landing from "./Pages/Landing";
import Map from "./Pages/MapComponent";
import NavComponent from "./Components/Layout/NavComponent";
import DataHubComponent from "./Pages/DataHubComponent";
import AboutUsComponent from "./Pages/AboutUsComponent";

function LayoutsWithNavbar() {
  return (
    <Layout>
      {/* Your navbar component */}
      <Tooltip title="Back to top">
        <BackTop/>
      </Tooltip>
      <NavComponent />
      
      {/* This Outlet is the place in which react-router will render your components that you need with the navbar */}
      <Outlet />
    </Layout>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutsWithNavbar />}>
          <Route path="/" element={<Landing />} />
          <Route path="/map" element={<Map />} />
          <Route path="/location" element={<div>Location View</div>} />
          <Route path="/datahub" element={<DataHubComponent/>} />
          <Route path="/aboutus" element={<AboutUsComponent/>} />
          {/* <Route path="/somethingProtected" element={<YourCustomProtectedRoute component={Someone}/>} />
          <Route path="/something/:id" element={<ProtectedRoute id component={SomethingById}/>} /> */}
        </Route>

        {/* Routes without a navbar you can add them here as normal routes */}
        {/* <Route
          path="/idontneednavbar"
          element={<ProtectedRoute component={Home}/>}
        /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
