import React from "react";
import "../../Assets/Styles/Landing.css";
import { ContentOne } from "./ContentOne";
import { Partnerships } from "./Partnerships";
import { LandingFooter } from "../Landing/LandingFooter";
import { GetInTouch } from "./GetInTouch";
import { MeetTheTeam } from "./MeetTheTeam";

export const DataHubComponent = () => {
  return (
    <>
      {/* <ContentOne/> */}
      {/* <Partnerships/> */}
      <MeetTheTeam/>
      <GetInTouch/>
      <LandingFooter/>
    </>
  );
};

export default DataHubComponent;
