import React, { useState, useEffect, useContext } from "react";
import L from 'leaflet';
import {
  MapContainer,
  LayersControl,
  WMSTileLayer,
  ZoomControl,
  LayerGroup,
} from 'react-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch'

// import contexts
import { MapContext } from "../../Contexts/MapContext";
import { WindowContext } from "../../Contexts/WindowContext";
import { SatelliteImagesContext } from "../../Contexts/SatelliteImagesContext";
import { GeographicalParametersContext } from "../../Contexts/GeographicalParametersContext";

// import components
import BasemapLayer from "../../Components/BasemapLayer";
import DatasetsButton from "./MapButtonsElements/DatasetsButton";
import Geolocation from "./MapButtonsElements/Geolocation";
import LeafletDraw from "./MapButtonsElements/LeafletDraw";
import LogoOnMap from "./MapButtonsElements/LogoOnMap";
import PrintMap from "./MapButtonsElements/PrintMap";
import PrintMapTitle from "./MapButtonsElements/PrintMapTitle";
import Search from "./MapButtonsElements/Search";
import ShowLegendsButton from "./MapButtonsElements/ShowLegendsButton";
import { featureInfoControl } from "./MapButtonsElements/FeatureInfo";
import { northArrowControl, northArrowControl_2 } from "./MapButtonsElements/NorthArrow";
import {
  legendRGS, legendRGS_2,
  legendLandCoverNcr, legendLandCoverNcr_2,
  legendRoadNetwork, legendRoadNetwork_2,
  legendNCRBoundary, legendNCRBoundary_2,
} from "./MapMarkers/RGSLegend";
import RGSMarker from "./MapMarkers/RGS";
import WeatherStationMarker from "./MapMarkers/WS";
import LowCostSensorMarker from "./MapMarkers/LCS";

import * as url from "../../Urls"
import "../../Assets/Styles/MapComponent.css";

const GEOSERVER_URL = url.GEOSERVER_URL
const API_URL = url.REACT_APP_API_URL

const MapComponent = ({ mapStyle }) => {
  const [stationsDataShow, setStationsDataShow] = useState({})
  const [weatherStationsDataShow, setWeatherStationsDataShow] = useState({})
  const [lowCostSensorsDataShow, setLowCostSensorsDataShow] = useState({})
  const { 
    stationsData,
    weatherStationsData,
    mapRef, setMapRef,
    viewDownloadableFileData,
    isGeoserverRunning, setIsGeoserverRunning,
    toggleValue,
    setMenu,
    lowCostSensorsData,
  } = useContext(MapContext)
  const { legendsOpen } = useContext(WindowContext);
  const { satelliteImagesData, sliderValueSatelliteImages } = useContext(SatelliteImagesContext)
  const { geographicalParametersData, sliderValueGeographicalParameters } = useContext(GeographicalParametersContext)
  const [workspaces, setWorkspaces] = useState({})


  /**
   * This useMemo updates the display of data depending on the state of the checkbox (allStations)
   * Default is set to true
   * 
   * Note:
   * We use useMemo, instead of useEffect, because 
   * useEffect is called AFTER the map is rendered.
   * 
   * The consequence of using useEffect is that the "shown" property of the points on the map
   * are by default set to false,
   * when what we actually want is to set them to true by default.
   */
  // useEffect(() => {
  //   if (stationsData && stationsData.results) {
  //     if (Object.keys(allStations).length > 0 && Object.keys(manualStations).length > 0) {
  //       const bothStations = {
  //         ...allStations,
  //         ...manualStations
  //       }
  //       var filteredStationsData = stationsData.results.filter(function(item) {
  //         let data_label = item.id + " - " + item.name
  //         return bothStations[data_label]
  //       })
  //       setStationsDataShow({
  //         count: filteredStationsData.length,
  //         results: filteredStationsData,
  //         next: stationsData.next,
  //         previous: stationsData.previous,
  //       })
  //     } else {
  //       setStationsDataShow({
  //         count: stationsData.results.length,
  //         results: stationsData.results,
  //         next: stationsData.next,
  //         previous: stationsData.previous,
  //       })
  //     }
  //   }
  // }, [stationsData, allStations, manualStations]);

  useEffect(() => {
    let controller = new AbortController();
    const signal = controller.signal;

    const loadMenus = async () => {
      fetch(API_URL + "/configuration/menu", {
        signal: signal,
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            // catches error for non-existent endpoints
            `This is an HTTP error: The status is ${res.status}`
          );
        }
        return res.json();
      })
      .then((body) => {
        setMenu(body)
      })
    }
    loadMenus();

    const loadWorkspaces = async () => {
      fetch(API_URL + "/configuration/workspace", {
        signal: signal,
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            // catches error for non-existent endpoints
            `This is an HTTP error: The status is ${res.status}`
          );
        }
        return res.json();
      })
      .then((body) => {
        setWorkspaces(body)
      })
    }
    loadWorkspaces();
    return () => {
      controller?.abort();
    }
    
  }, [])

  useEffect(() => {
    if (stationsData && stationsData.results) {
      if (Object.keys(toggleValue).length > 0) {
        var filteredStationsData = stationsData.results.filter(function(item) {
          let data_parent = JSON.parse(JSON.stringify(item.station_type)).replace(/\s+/g, "")
          let data_label = data_parent + item.id + " - " + item.name
          return toggleValue[data_label]
        })
        setStationsDataShow({
          count: filteredStationsData.length,
          results: filteredStationsData,
          next: stationsData.next,
          previous: stationsData.previous,
        })
      } else {
        setStationsDataShow({
          count: stationsData.results.length,
          results: stationsData.results,
          next: stationsData.next,
          previous: stationsData.previous,
        })
      }
    }
  }, [stationsData, toggleValue]);

  useEffect(() => {
    if (weatherStationsData && weatherStationsData.results) {
      if (Object.keys(toggleValue).length > 0) {
        var filteredStationsData = weatherStationsData.results.filter(function(item) {
          let data_parent = "WeatherStationsDailyData"
          let data_label = data_parent + item.id + " - " + item.name
          return toggleValue[data_label]
        })
        // console.log("useEffect", filteredStationsData)
        setWeatherStationsDataShow({
          count: filteredStationsData.length,
          results: filteredStationsData,
          next: weatherStationsData.next,
          previous: weatherStationsData.previous,
        })
      } else {
        setWeatherStationsDataShow({
          count: weatherStationsData.results.length,
          results: weatherStationsData.results,
          next: weatherStationsData.next,
          previous: weatherStationsData.previous,
        })
      }
    }
  }, [weatherStationsData, toggleValue]);

  useEffect(() => {
    if (lowCostSensorsData && lowCostSensorsData.results) {
      if (Object.keys(toggleValue).length > 0) {
        // console.log("toffle:", toggleValue)
        var filteredStationsData = lowCostSensorsData.results.filter(function(item) {
          let data_parent = "SmallSensors"
          // let data_label = data_parent + item.mapping[0]["database_name"] + " - " + item.mapping[0]["source"]
          let data_label = data_parent + item.location_name
          // console.log("data_label", data_label)
          return toggleValue[data_label]
        })
        // console.log("useEffect", filteredStationsData)
        setLowCostSensorsDataShow({
          count: filteredStationsData.length,
          results: filteredStationsData,
        })
      } else {

        setLowCostSensorsDataShow({
          count: lowCostSensorsData.results.length,
          results: lowCostSensorsData.results,
        })
      }
    }
  }, [lowCostSensorsData, toggleValue]);

  useEffect(() => {
    if (mapRef) {
      if (isGeoserverRunning) {
        // for legends from GeoServer
        // adding legend on map
        if (legendsOpen) {
          legendNCRBoundary.addTo(mapRef);
          legendRoadNetwork.addTo(mapRef);
          legendLandCoverNcr.addTo(mapRef);
          legendRGS.addTo(mapRef);
          // adding legend on printMap
          mapRef.on(L.BrowserPrint.Event.Print, function(e){
            legendNCRBoundary_2.addTo(e.printMap);
            legendRoadNetwork_2.addTo(e.printMap);
            legendLandCoverNcr_2.addTo(e.printMap);
          });
        } else {
          legendNCRBoundary.remove(mapRef);
          legendRoadNetwork.remove(mapRef);
          legendLandCoverNcr.remove(mapRef);
          legendRGS.remove(mapRef);
        }
      } else {
        // for legends of refGradeStations
        // adding scale, legend, north arrow on map
        featureInfoControl.addTo(mapRef)
        // mousePositionControl.addTo(mapRef)
        L.control.scale({position:'bottomleft'}).addTo(mapRef)
        northArrowControl.addTo(mapRef)

        // adding scale, legend, north arrow on printMap
        mapRef.on(L.BrowserPrint.Event.PrintStart, function(e){
          L.control.scale({position:'bottomleft'}).addTo(e.printMap)
          legendRGS_2.addTo(e.printMap);
          northArrowControl_2.addTo(e.printMap)
        });
      } 
    }
  }, [mapRef, isGeoserverRunning, legendsOpen])

  /**
   * checks if geoserver_url is running
   */
  useEffect(() => {
    fetch(GEOSERVER_URL + "/wms")
    .then((res) => {
      if (res.ok) {
        setIsGeoserverRunning(true)
        // console.log("res", res)
      }
    })
    .catch((error) => {
      if (error.name) {
        setIsGeoserverRunning(false)
        // alert(error.message + ": Geoserver")
        console.log("error", error.name)
      }
    })
  }, [])

  // useEffect(() => {
  //   console.log("weatherStationsData", weatherStationsData)
  // }, [weatherStationsData])

  // useEffect(() => {
  //   console.log("lowCostSensorsDataShow", lowCostSensorsDataShow)
  // }, [lowCostSensorsDataShow])

  return (
    <MapContainer
      style={mapStyle}
      center={[14.6014382,121.0498376]}
      zoom={11}
      // scrollWheelZoom={false}
      zoomControl={false}
      ref={setMapRef}
    >
      {satelliteImagesData && workspaces.count > 0 &&
        workspaces.results.map((workspace) => 
          satelliteImagesData[workspace.name] && 
          satelliteImagesData[workspace.name]["layers"]["layer"].map((item) => {
            if (item.checked) {
              // console.log("workspace", workspace)
              return (
                <LayerGroup key={item.name}>
                  <WMSTileLayer
                    layers={workspace.name + ":" + item.name}
                    url={GEOSERVER_URL+"/wms"}
                    transparent={true}
                    format={"image/png"}
                    zIndex={9}
                    opacity={sliderValueSatelliteImages && Object.keys(sliderValueSatelliteImages).length > 0 ? sliderValueSatelliteImages[item.name] / 100.0 : 1 }
                  />
                </LayerGroup>
              )
            }
          })
        )
      }
      {geographicalParametersData && workspaces.count > 0 &&
        workspaces.results.map((workspace) => 
        geographicalParametersData[workspace.name] && 
        geographicalParametersData[workspace.name]["layers"]["layer"].map((item) => {
            if (item.checked) {
              // console.log("sliderValueSatelliteImages", sliderValueSatelliteImages)
              return (
                <LayerGroup key={item.name}>
                  <WMSTileLayer
                    layers={workspace.name + ":" + item.name}
                    url={GEOSERVER_URL+"/wms"}
                    transparent={true}
                    format={"image/png"}
                    zIndex={9}
                    opacity={sliderValueGeographicalParameters && Object.keys(sliderValueGeographicalParameters).length > 0 ? sliderValueGeographicalParameters[item.name] / 100.0 : 1 }
                  />
                </LayerGroup>
              )
            }
          })
        )
      }
      
      <LayersControl style={{zIndex: 10}}>
        <BasemapLayer/>
      </LayersControl>

      {/* <Polygon positions={geoserver.layer5}/> */}
      {/* <Logo/> */}
      <LogoOnMap position="topleft"/>
      <DatasetsButton position="topleft"/>
      <LeafletDraw position="topleft"/>

      <ZoomControl position="topright"/>
      <Search provider={new OpenStreetMapProvider()} position="topright"/>
      <Geolocation position="topright" map={mapRef}/>
      <PrintMap position="topright" map={mapRef}/>
      {viewDownloadableFileData?
        <PrintMapTitle/>
      :null}

      {stationsDataShow.count > 0 && 
        stationsDataShow.results.map((data) => {
          return (
            <RGSMarker key={data.id + " - " + data.name} data={data}/>
          )
        })
      }

      {weatherStationsDataShow.count > 0 && 
        weatherStationsDataShow.results.map((data) => {
          return (
            <WeatherStationMarker key={data.id + " - " + data.name} data={data}/>
          )
        })
      }
      {lowCostSensorsDataShow.count > 0 && 
        lowCostSensorsDataShow.results.map((data, index) => {
          // console.log("lowCostSensorsDataShowdata", data, data.mapping[0])
          return (
            <LowCostSensorMarker key={data.mapping[0]["source"]} data={data}/>
          )
        })
      }
      <ShowLegendsButton position="bottomright"/>
    </MapContainer>
  )
}

export default MapComponent;
