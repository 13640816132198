import { useEffect } from "react";
import { useMap } from 'react-leaflet'
import L from 'leaflet';
import "/node_modules/leaflet-geosearch/dist/geosearch.css";
import 'leaflet.browser.print/dist/leaflet.browser.print';
import "../../../Assets/Styles/Geolocation.css";


// make new leaflet element
const PrintMap = (props) => {
    const position = props.position || "topleft"

    const map = useMap() // access to leaflet map
    // const print = () => {
    //     /**
    //      * for custom printing
    //      */
    //     console.log("print")
    //     alert("do something before printing map")
    //     window.print()
    // }
    
    useEffect(() => {
        const printMapControl = L.control.browserPrint({
            position: position, 
            // documentTitle: 'airmove-print-map',
            printModes: [
                "Landscape",
                "Portrait",
            ],
            // printFunction: print
        })
        printMapControl.addTo(map)

        return () => {
            if (map && printMapControl instanceof L.Control) { 
                map.removeControl(printMapControl);
            }
        }
    }, [props, map])

    return null // don't want anything to show up from this comp
}
export default (PrintMap);
