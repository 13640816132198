import { Typography, Row, Col, Image, Button } from "antd";
import { Content } from "antd/lib/layout/layout";
import AirQuality from "../../Assets/Images/landing/air-quality.png"
import EmissionsInventory from "../../Assets/Images/landing/emissions-inventory.png"
import GeographicalParameters from "../../Assets/Images/landing/geographical-parameters.png"
import MeteorologicalData from "../../Assets/Images/landing/meteorological-data.png"

const { Title } = Typography;

/*
 *** landing card items
 */
const LandingCardItems = [
  {
    title: "Air Quality",
    description: "description",
    imgSrc: AirQuality
  },
  {
    title: "Meteorological Data",
    description: "description",
    imgSrc: MeteorologicalData
  },
  {
    title: "Geographical Parameters",
    description: "description",
    imgSrc: GeographicalParameters
  },
  {
    title: "Emissions Inventory",
    description: "description",
    imgSrc: EmissionsInventory
  },
];

const LandingCard = (props) => {
  let title = props.title
  let description = props.description
  let imgSrc = props.imgSrc

  return (
    <Col lg={{span: 6}} sm={{span: 12}} xs={{span: 24}} className="landing-card-third">
      <Image
        src={imgSrc}
        alt={title}
        preview={false}
      />
      <Title level={4} className="landing-label-white landing-card-title">
        {title}
      </Title>
      <Typography className="landing-label-white _landing_third_sub2_">
        {description}
      </Typography>
    </Col>
  )
};

export const ContentThree = () => {

  return (
    <Content className="landing-third">
      <Row justify={"center"}>
        <Col>
          <Title level={3} className="landing-label-white landing-label-center" style={{fontWeight: "bolder"}}>
            Features
          </Title>
          <Title level={5} className="landing-label-white landing-label-center" style={{fontWeight: "bolder"}}>
            These are what we can do to serve you
          </Title>
        </Col>
      </Row>
      <Row>
        {LandingCardItems.map((item) => {
          return (
            <LandingCard key={item.title} title={item.title} description={item.description} imgSrc={item.imgSrc}/>
          )
        })}
      </Row>
      {/* <Row justify={"center"}>
        <Button
          shape="round"
          type="primary"
          style={{background: "#0072A4", borderColor: "#0072A4"}}
        >
          Know More
        </Button>
      </Row> */}
    </Content>
  )
};
