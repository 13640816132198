import React from "react";
import "../../Assets/Styles/Landing.css";
import { ContentOne } from "./ContentOne";
import { ContentThree } from "./ContentThree";
import { LandingFooter } from "./LandingFooter";
import { ContentTwo } from "./ContentTwo";
import { MediaFiles } from "./MediaFiles";

export const Landing = () => {
  return (
    <>
      <ContentOne/>
      <ContentTwo/>
      {/* note that ContentTwo is just the same as the Overview.js */}
      <ContentThree/>
      <MediaFiles/>
      <LandingFooter/>
    </>
  );
};

export default Landing;
