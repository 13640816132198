import { useContext, useEffect, useState } from "react";
import { useMap } from 'react-leaflet'
import L from 'leaflet';
import $ from 'jquery';
import { WindowContext } from "../../../Contexts/WindowContext";
import "/node_modules/leaflet-geosearch/dist/geosearch.css";

// make new leaflet element
const DatasetsButton = (props) => {
    const position = props.position || "topleft"
    const map = useMap(); // access to leaflet map
    const { datasetsOpen, setDatasetsOpen } = useContext(WindowContext)
    
    useEffect(() => {
       
        const control = L.control({ position: position });
        control.onAdd = () => {
            const container = L.DomUtil.create("button", "leaflet-control leaflet-bar title-and-logo-on-map");
            datasetsOpen? $(container).append("<i> Close Datasets</i>") : $(container).append("<i> Datasets</i>")
            container.onclick = function(e) {
                e.stopPropagation()
                setDatasetsOpen(!datasetsOpen)
            }
            return container;
        }
        if (!datasetsOpen) {
            control.addTo(map)
        }

        return () => {
            if (map && control instanceof L.Control) { 
                map.removeControl(control);
            }
        }
    }, [props])

    return null // don't want anything to show up from this comp
}
export default (DatasetsButton);
