import L from "leaflet";
import { Icon } from 'leaflet';

// var MapIcon = (color, selected) => new L.Icon({
//     iconUrl: `https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|${color}&chf=a,s,ee00FFFF`,
//     iconSize: selected? [31.5, 51] : [21, 34],
//     iconAnchor: selected? [15.75, 34] : [10.5, 17],
//     // popupAnchor: [1, -34],
// });

// export var mapIconSquare = (color, selected) => new L.Icon({
//   iconUrl: require(`../Assets/Images/square-${color}.png`),
//   // require("../../Assets/Images/logo.png")
//   iconSize: selected? [15, 15] : [7.5, 7.5],
//   iconAnchor: selected? [5, 5] : [3.75, 3.75],
//   popupAnchor: [1, -7.5],
// });

// source: https://www.geoapify.com/create-custom-map-marker-icon
export const MapIconCircle = (color, selected) => new L.divIcon({
  className: 'custom-div-icon',
  html: selected? `<div style='background-color:${color};'class='circle-pin marker-pin-selected marker-pin-scaled'></div>`: `<div style='background-color:${color};'class='marker-pin circle-pin'></div>`,
  popupAnchor: [1, -7.5],
});

// source: https://www.geoapify.com/create-custom-map-marker-icon
export const MapIconSquare = (color, selected) => new L.divIcon({
  className: 'custom-div-icon',
  html: selected? `<div style='background-color:${color};'class='marker-pin-selected marker-pin-scaled'></div>`: `<div style='background-color:${color};'class='marker-pin'></div>`,
  popupAnchor: [1, -7.5],
});

export const CloudIconSquare = (color="red", selected) => new L.divIcon({
  className: 'custom-div-icon',
  // html: selected? `<div style='background-color:${color};'class='marker-pin-selected'></div>`: `<div style='background-color:${color};'class='marker-pin'></div>`,
  html: selected? `<i class='marker-pin-scaled material-icons cloud-icon' style='font-size:20px'>cloud</i>`:`<i class='material-icons cloud-icon' style='font-size:20px'>cloud</i>`,
  popupAnchor: [1, -7.5],
});

// source: https://www.geoapify.com/create-custom-map-marker-icon
// export const SensorIcon = (color, selected) => new L.divIcon({
//   // className: 'custom-div-icon',
//   html: selected? `<div style='background-color:${color};'class=marker-pin-scaled'></div>`: `<div style='background-color:${color};></div>`,
//   popupAnchor: [1, -7.5],
// });

// Get the default icon
export const defaultIcon = new Icon.Default();

// Scale the default icon to two times its original size
export const scaledDefaultIcon = new Icon({
  iconUrl: defaultIcon._getIconUrl('icon'),
  iconSize: [defaultIcon.options.iconSize[0] * 1.2, defaultIcon.options.iconSize[1] * 1.2],
});