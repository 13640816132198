import React, { useContext, useEffect } from "react";
import { Row, Col, Tooltip } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";

// import constants and functions, context, components
import { MapContext } from "../../Contexts/MapContext";
import { WindowContext } from "../../Contexts/WindowContext";
import Sidebar from "./Sidebar";
import MapComponent from "./MapComponent";
import SSReferenceGradeStations from "./SidebarSecondary/ReferenceGradeStations";
import SSWeatherStations from "./SidebarSecondary/WeatherStations";
import SSSatelliteImages from "./SidebarSecondary/SatelliteImages";
import SSLowCostSensors from "./SidebarSecondary/LowCostSensors";


const Map = () => {
  const {
    viewDownloadableFileData,
    mapRef,
  } = useContext(MapContext);
  const {
    datasetsOpen,
    setDatasetsOpen
  } = useContext(WindowContext);

  useEffect(() => {
    setTimeout(
      setDatasetsOpen(true),
      4000
    )
  }, [])

  // useEffect(() => {
  //   console.log("viewDownloadableData", viewDownloadableFileData)
  // }, [viewDownloadableFileData])
  return (
    <Row>
      <Col style={{ backgroundColor: "#FFF" }}
        lg={{span: datasetsOpen && viewDownloadableFileData ? 6 : datasetsOpen && !viewDownloadableFileData ? 6 : 0}}
        sm={{span: datasetsOpen && viewDownloadableFileData ? 6 : datasetsOpen && !viewDownloadableFileData ? 12 : 0}}
        xs={{span: datasetsOpen && viewDownloadableFileData ? 24 : datasetsOpen && !viewDownloadableFileData ? 24 : 0}}
      >
        {datasetsOpen &&
          <Tooltip title="Close" placement="bottomRight">
            <CloseCircleFilled
              style={{
                position: "absolute",
                paddingLeft: `calc(90% + 5px)`,
                paddingTop: "6px",
                paddingBottom: "10px",
                backgroundColor: "white",
                width: `calc(100% - 5px)`,
                fontSize: "16px",
                color: "gray",
                zIndex: 1,
              }}
              onClick={()=>{
                setDatasetsOpen(!datasetsOpen);
                setTimeout(function(){ mapRef.invalidateSize()}, 400);
              }}
            />
          </Tooltip>
        }
        <Sidebar/>
      </Col>
      <Col
        lg={{span: datasetsOpen && viewDownloadableFileData ? 6 : !datasetsOpen && viewDownloadableFileData ? 6 : 0}}
        sm={{span: datasetsOpen && viewDownloadableFileData ? 6 : !datasetsOpen && viewDownloadableFileData ? 12 : 0}}
        xs={{span: datasetsOpen && viewDownloadableFileData ? 24 : !datasetsOpen && viewDownloadableFileData ? 24 : 0}}
      >
        {
          viewDownloadableFileData && viewDownloadableFileData.hasOwnProperty("aq_station_daily") ?
          <SSReferenceGradeStations layer={viewDownloadableFileData}/>:
          viewDownloadableFileData && viewDownloadableFileData.hasOwnProperty("met_station_daily") ?
          <SSWeatherStations layer={viewDownloadableFileData}/>:
          viewDownloadableFileData && viewDownloadableFileData.hasOwnProperty("layers") ?
          <SSSatelliteImages layer={viewDownloadableFileData}/>:
          viewDownloadableFileData && viewDownloadableFileData.hasOwnProperty("mapping") &&
          <SSLowCostSensors layer={viewDownloadableFileData}/>
        }
      </Col>
      <Col
        lg={{span: datasetsOpen && viewDownloadableFileData ? 12 : datasetsOpen || viewDownloadableFileData ? 18 : 24}}
        sm={{span: datasetsOpen && viewDownloadableFileData ? 12 : datasetsOpen || viewDownloadableFileData ? 12 : 24}}
        xs={{span: datasetsOpen && viewDownloadableFileData ? 0 : datasetsOpen || viewDownloadableFileData ? 0 : 24}}
      >
        <MapComponent
          // mapStyle={{ height: "calc(100vh - 64px)" }}
          mapStyle={{ height: "100vh", zIndex: 1 }}
        />
      </Col>
    </Row>
  )
}

export default Map;
