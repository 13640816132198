// GeoServerService.js
import * as url from "../../Urls"


const GEOSERVER_URL = url.GEOSERVER_URL

const WCSGeneralParameters = {
  "service": "WCS",
  "version": "2.0.0",
}

const getBboxAndSrsFromXml = (xmlString) => {
  // Replace 'xmlString' with the actual XML response from the GetCapabilities request
  // var xmlString = "<!-- XML response from GetCapabilities request -->";
  // console.log("xmlString", xmlString)
  var parser = new DOMParser();
  var xmlDoc = parser.parseFromString(xmlString, "text/xml");

  // console.log("xmlDoc", xmlDoc)
  // Find the lowerCorner and upperCorner elements
  const envelopeElement = xmlDoc.querySelector("Envelope");
  const srsName = envelopeElement.getAttribute("srsName").split("/");
  // const srsNameString = envelopeElement.getAttribute("srsName");
  // const srsNameList = srsNameString.split("/")
  const srsNameListLength = srsName.length
  const srsNameValue = srsName[srsNameListLength-3] + ":" + srsName[srsNameListLength-1]

  const lowerCornerElement = xmlDoc.querySelector("lowerCorner").textContent.trim().split(' ').map(Number);
  const upperCornerElement = xmlDoc.querySelector("upperCorner").textContent.trim().split(' ').map(Number);

  // Log the result
  // console.log("Envelope:", envelopeElement);
  // console.log("SRS Name:", srsNameValue);
  // console.log("Lower Corner:", lowerCornerElement);
  // console.log("Upper Corner:", upperCornerElement);
  
  let bboxAndSrs = {
    "minX": lowerCornerElement[1],
    "minY": lowerCornerElement[0],
    "maxX": upperCornerElement[1],
    "maxY": upperCornerElement[0],
    "srsName": srsNameValue,
  }
  
  return bboxAndSrs
}

const generateGetCapabilitiesUrl = (layerName) => {
  WCSGeneralParameters["request"] = "GetCapabilities"
  const queryString = new URLSearchParams(WCSGeneralParameters).toString();
  const GET_CAPABILITIES_URL = `${GEOSERVER_URL}/wcs?${queryString}`
  return GET_CAPABILITIES_URL
};

const generateDescribeCoverageUrl = (layerName) => {
  WCSGeneralParameters["request"] = "DescribeCoverage"
  WCSGeneralParameters["coverageId"] = layerName
  const queryString = new URLSearchParams(WCSGeneralParameters).toString();
  const DESCRIBE_COVERAGE_URL = `${GEOSERVER_URL}/wcs?${queryString}`
  return DESCRIBE_COVERAGE_URL
};

const generateGetCoverageUrl = (workspace, layerName, bboxAndSrs) => {
  WCSGeneralParameters["request"] = "GetCoverage"
  WCSGeneralParameters["format"] = "geotiff"
  WCSGeneralParameters["content-disposition"] = "attachment"
  WCSGeneralParameters["filename"] = layerName + ".tiff"
  WCSGeneralParameters["coverageId"] = workspace + ":" + layerName
  WCSGeneralParameters["SRS"] = bboxAndSrs["srsName"]
  WCSGeneralParameters["BoundingBox"] = `${bboxAndSrs["minX"]},${bboxAndSrs["minY"]},${bboxAndSrs["maxX"]},${bboxAndSrs["maxY"]}`
  const queryString = new URLSearchParams(WCSGeneralParameters).toString();
  const GET_COVERAGE_URL = `${GEOSERVER_URL}/wcs?${queryString}`
  return GET_COVERAGE_URL
};


const GeoServerService = {
  // let GET_CAPABILITIES_URL = generateGetCapabilitiesUrl()
  // console.log("GET_CAPABILITIES_URL", GET_CAPABILITIES_URL)
  getBoundingBox: async (name) => {
    // Uses the DescribeCoverage of Web Coverage Service to get the bounding box of a GeoServer layer
    let DESCRIBE_COVERAGE_URL = generateDescribeCoverageUrl(name)
    console.log("DESCRIBE_COVERAGE_URL", DESCRIBE_COVERAGE_URL)

    return fetch(DESCRIBE_COVERAGE_URL)
    .then((res) => {
      if (res.ok) {
        return res.text()
      }
    })
    .then(xmlString => {
      return getBboxAndSrsFromXml(xmlString, name)
    })
  },

  downloadTiff: async (workspace, name) => {
    // Uses the GetCoverage of Web Coverage Service to download a GeoServer layer
    const bboxAndSrs = await GeoServerService.getBoundingBox(name)
    // console.log("bboxAndSrs", bboxAndSrs)
    let GET_COVERAGE_URL = generateGetCoverageUrl(workspace, name, bboxAndSrs)
    console.log("GET_COVERAGE_URL", GET_COVERAGE_URL)
    return GET_COVERAGE_URL
  },
};

export default GeoServerService;
