import React, { createContext, useState } from 'react';
import moment from "moment";

export const SidebarSecondaryContext = createContext();

const SidebarSecondaryContextProvider = (props) => {
    const dateFormat = "YYYY-MM-DD HH:mm";
    const [params, setParams] = useState({
        period_start: moment().subtract(10, "years").startOf('day').format(dateFormat),
        period_end: moment().startOf('day').format(dateFormat),
        granularity: "daily",
        show_pollutant: "",
    });
    const [metParams, setMetParams] = useState({
        period_start: moment().subtract(10, "years").startOf('day').format(dateFormat),
        period_end: moment().startOf('day').format(dateFormat),
    });
    const [satelliteParams, setSatelliteParams] = useState({
        period_start: moment().subtract(10, "years").startOf('day').format(dateFormat),
        period_end: moment().startOf('day').format(dateFormat),
    });
    const [lowCostParams, setLowCostParams] = useState({
        period_start: moment("2023-08-01").format(dateFormat),
        period_end: moment("2023-08-03").format(dateFormat),
    });
    // const [satelliteParams, setSatelliteParams] = useState({});

    return (
        <SidebarSecondaryContext.Provider 
            value={{
                params, setParams,
                metParams, setMetParams,
                dateFormat,
                satelliteParams, setSatelliteParams,
                lowCostParams, setLowCostParams,
            }}
        >
            {props.children}
        </SidebarSecondaryContext.Provider>
    );
}

export default SidebarSecondaryContextProvider;