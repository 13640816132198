import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Table, Divider, Image } from "antd";
import { Content } from "antd/lib/layout/layout";
import { IoMdLocate } from "react-icons/io"
import { IoBarChartSharp, IoThermometer } from "react-icons/io5"
import { RiContrastDropLine } from "react-icons/ri"
import MapComponent from "./MapComponent";
import PM25Icon from "../../Assets/Images/landing/section-two-pm25.png"
import PM10Icon from "../../Assets/Images/landing/section-two-pm10.png"
import SO2NO2Icon from "../../Assets/Images/landing/section-two-so2no2.png"
import * as url from "../../Urls";

const AQI_LEGEND = url.REACT_APP_AQI_LEGEND_URL;
const { Title, Text } = Typography;
/**
 * 
 * @returns table representation of legend values
 */
const LegendTable = ({legendValues}) => {
  /**
   * 
   * @param {*} key
   * @param {*} value 
   * @returns the color based on the key and the value of that key
   */
  function getColorByKey(key, value) {
    const foundObj = legendValues.find(obj => obj[key] === value);
    if (foundObj) {
      let colors = []
      colors.push(foundObj.fill_color)
      colors.push(foundObj.text_color)
      return colors;
    }
    return null;
  };

  /**
   * columns of the legend table
   */
  const legendColumns = [
    {
      title: "Daily AQI Color",
      dataIndex: "color",
      key: "color",
      render(text) {
        return {
          props: {
            style: {backgroundColor: `${getColorByKey("color", text)[0]}`,},
          },
          children: <Text style={{color: getColorByKey("color", text)[1]}}>{text}</Text>,
        };
      },
    },
    {
      title: "Levels of Concern",
      dataIndex: "label",
      key: "label",
      render(text) {
        return {
          props: {
            style: {backgroundColor: `${getColorByKey("label", text)[0]}`},
          },
          children: <Text style={{color: getColorByKey("label", text)[1]}}>{text}</Text>,
        };
      },
    },
    {
      title: "Values of Index",
      dataIndex: "values",
      key: "values",
      render(text) {
        return {
          props: {
            style: {backgroundColor: `${getColorByKey("values", text)[0]}`},
          },
          children: <Text style={{color: getColorByKey("values", text)[1]}}>{text}</Text>,
        };
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render(text) {
        return {
          props: {
            style: {
              backgroundColor: `${getColorByKey("description", text)[0]}`,
              textAlign: "left"
            },
          },
          children: <Text style={{color: getColorByKey("description", text)[1]}}>{text}</Text>,
        };
      },
    },
  ];

  return (
    <Table
      dataSource={legendValues}
      columns={legendColumns}
      bordered={true}
      pagination={false}
      className="overlayview-table overview-column-grid"
    />
  );
};

/*
 *** card items
 */
const CardItems = [
  {
    title: 128,
    description: "PM 2.5",
    icon: <IoThermometer/>
    // icon: PM25Icon
  },
  {
    title: 65,
    description: "PM 10",
    icon: <IoThermometer/>
    // icon: PM10Icon
  },
  {
    title: 2,
    description: "SO2",
    icon: <RiContrastDropLine/>
    // icon: SO2NO2Icon
  },
  {
    title: 10,
    description: "NO2",
    icon: <RiContrastDropLine/>
    // icon: SO2NO2Icon
  },
];

const ContentTwoCard = (props) => {
  let title = props.title
  let description = props.description
  let icon = props.icon

  return (
    <Col xl={{span: 6}} lg={{span: 12}} md={{span: 6}} sm={{span: 6}} xs={{span: 24}} className="landing-card">
      <Title level={1} className="landing-label-grey landing-card-title">
        {title}
      </Title>
      <Typography
        className="landing-label-black _landing_third_sub2_" 
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {icon}
        {/* <Image
          src={icon}
          alt={title}
          preview={false}
        /> */}
        <span style={{paddingLeft: "0.5em"}}>
          {description}
        </span>
      </Typography>
    </Col>
  )
};

const ContentTwoSectionHeader = (props) => {
  let sectionIcon = props.sectionIcon
  let sectionTitle = props.sectionTitle
  let sectionDesription = props.sectionDesription
  let sectionCaption = props.sectionCaption

  return (
    <Row>
      <Col>
        {sectionIcon}
      </Col>
      <Col style={{paddingLeft: "0.5em"}}>
        <Row><Text className="overview-label">{sectionTitle}</Text></Row>
        <Row><Text className="overview-description">{sectionDesription}</Text></Row>
        <Row><Text className="overview-caption">{sectionCaption}</Text></Row>
      </Col>
    </Row>
  )
};

export const ContentTwo = () => {
  const [legendValues, setLegendValues] = useState()
  
  const loadAQILegend = async () => {
    fetch(AQI_LEGEND)
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            // catches error for non-existent endpoints
            `This is an HTTP error: The status is ${res.status}`
          );
        }
        return res.json();
      })
      .then((body) => {
        var legends = body.results
        // Use the forEach method to add the new key-value pair to each object
        if (legends) {
          legends.forEach(obj => {
            obj["key"] = obj["label"];
          });
        }
        setLegendValues(legends)
      })
      .catch((e) => {
        console.log("error", e)
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    loadAQILegend()
  }, [])
  
  return (
    <Content className="landing-second">
      <Title level={3} className="landing-label-primary" style={{fontWeight: "bolder"}}>
        Explore the Air
      </Title>
      <Title level={5} className="landing-label-primary" style={{fontWeight: "bolder"}}>
        See the air quality on your city
      </Title>
      <Row gutter={50}>
        <Col lg={{span: 10}} xs={{span: 24}}>

          <Divider/>
          
          <ContentTwoSectionHeader
            sectionIcon={<IoMdLocate color="#000" style={{marginTop: "6px"}}/>}
            sectionTitle="Location"
            sectionDesription="Metro Manila"
            sectionCaption="As of October 28, 2022,  3:40 PM"
          />
          
          <Row>
            {CardItems.map((item) => {
              return (
                <ContentTwoCard key={item.description} title={item.title} description={item.description} icon={item.icon}/>
              )
            })}
          </Row>

          <Divider/>

          <ContentTwoSectionHeader
            sectionIcon={<IoBarChartSharp color="#000" style={{marginTop: "6px"}}/>}
            sectionTitle="AQI Basics for Ozone and Particle Pollution"
            sectionDesription="AQI Basics for Ozone and Particle Pollution"
          />
          <LegendTable legendValues={legendValues}/>
        </Col>

        <Col lg={{span: 14}} xs={{span: 24}}>
          <MapComponent mapStyle={{height: "73vh", minHeight: "100%"}}/>
        </Col>
      </Row>
    </Content>
  )
};
