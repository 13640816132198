import React from "react";
import "../../Assets/Styles/Landing.css";
import { ContentOne } from "./ContentOne";
import { ContentThree } from "./ContentThree";
import { LandingFooter } from "../Landing/LandingFooter";
import { ContentTwo } from "./ContentTwo";
import { ContentFour } from "./ContentFour";
import { ContentFive } from "./ContentFive";

export const DataHubComponent = () => {
  return (
    <>
      <ContentOne/>
      <ContentTwo/>
      <ContentThree/>
      <ContentFour/>
      <ContentFive/>
      <LandingFooter/>
    </>
  );
};

export default DataHubComponent;
