import { useEffect } from "react";
import { useMap } from 'react-leaflet'
import L from 'leaflet';
import $ from 'jquery';
import "/node_modules/leaflet-geosearch/dist/geosearch.css";
import airmoveLogo from "../../../Assets/Images/logo.png"
import "../../../Assets/Styles/LogoOnMap.css";

// make new leaflet element
const LogoOnMap = (props) => {
    const position = props.position || "topleft"
    const map = useMap(); // access to leaflet map

    useEffect(() => {
       
        const control = L.control({ position: position });
        control.onAdd = () => {
        const container = L.DomUtil.create("button", "leaflet-control leaflet-bar title-and-logo-on-map");
            $(container).append("<img width='50' alt='airmove-logo' preview={false} src=" + airmoveLogo + " class=logo-on-map><span class='tooltiptext'>Home</span></img>")
            container.onclick = function() {
                window.location = "/"
            }
            return container;
        }
        control.addTo(map)

        return () => {
            if (map && control instanceof L.Control) { 
                map.removeControl(control);
            }
        }
    }, [props])

    return null // don't want anything to show up from this comp
}
export default (LogoOnMap);
