import React, { useState } from 'react';
import { Typography, Row, Col, Layout, Button, Form, Input, Modal } from "antd";
import MapComponent from './MapComponent';
import { CardItems } from './Constants';
import * as url from "../../Urls"

const API_URL = url.REACT_APP_API_URL
const SEND_EMAIL_URL = `${API_URL}/reports/send_email/`

const { TextArea } = Input;
const { Content } = Layout;
const { Title, Text } = Typography;

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const DetailCard = (props) => {
  let title = props.title
  let description = props.description
  let icon = props.icon
  
  return (
    <Col xl={{span: 5}} lg={{span: 8}} md={{span: 8}} sm={{span: 12}} style={{width: "100%", marginBottom: "2rem"}}>
      <Content
        style={{
          padding: 24,
          background: "#eee",
        }}
      >
        <Row>
          <Col xl={{span: 5}} xs={{span: 8}}>
            {icon}
          </Col>
          <br/>
          <br/>
          <Col>
            <Title level={5} className="landing-label-primary" style={{fontWeight: "bolder", textAlign: "center"}}>
              {title}
            </Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text level={5} className="landing-label-primary">
              {description}
            </Text>
          </Col>
        </Row>
      </Content>
    </Col>
  )
}


export const GetInTouch = () => {

  const ContactUsForm = () => {
    const errorMessage = "This is a required field"
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [name, setName] = useState({
      value: '',
      error: false
    })
    const [email, setEmail] = useState({
        value: '',
        error: false
    })
    const [message, setMessage] = useState({
        value: '',
        error: false
    })
  

    const handleOk = (name, email, message) => {
      setConfirmLoading(true);
      let formData = new FormData();
      formData.append('name', name.value);
      formData.append('email', email.value);
      formData.append('message', message.value);

      fetch(SEND_EMAIL_URL, {
        method: "POST", 
        body: formData,
      })
      .then((res) => {
        console.log("kadyan", res)
        // setMessageRequestSent(res.ok)
        if (!res.ok) {
          throw new Error(
            // catches error for non-existent endpoints
            `This is an HTTP error: The status is ${res.status}`
          );
        }
        return res.json();
      })
      .catch((e) => {
        console.log("error", e)
        
      })
      .finally(() => {
        setConfirmLoading(false);
        setOpen(false)
      })
    };
  
    const handleCancel = () => {
      console.log('Clicked cancel button');
      setOpen(false);
    };

    // const sendToEmail = async () => {
    //   // console.log("SEND_EMAIL_URL", SEND_EMAIL_URL)
    //   // console.log("sendMessage", messageRequest)
    //   let formData = new FormData();
    //   formData.append('name', name.value);
    //   formData.append('email', email.value);
    //   formData.append('message', message.value);

    //   fetch(SEND_EMAIL_URL, {
    //     method: "POST", 
    //     body: formData,
    //   })
    //   .then((res) => {
    //     console.log("kadyan", res)
    //     // setMessageRequestSent(res.ok)
    //     if (!res.ok) {
    //       throw new Error(
    //         // catches error for non-existent endpoints
    //         `This is an HTTP error: The status is ${res.status}`
    //       );
    //     }
    //     return res.json();
    //   })
    //   .catch((e) => {
    //     console.log("error", e)
    //   })
    // };

    // sendToEmail();

    const sendMessage = (name, email, message) => {
      // source: https://medium.com/geekculture/how-to-send-emails-from-a-form-in-react-emailjs-6cdd21bb4190
      if (!name.value) {
        setName({
          ...name,
          error: true
        })
      }
      if (!email.value || !emailRegex.test(email.value)) {
        setEmail({
          ...email,
          error: true
        })
      }
      if (!message.value) {
        setMessage({
          ...message,
          error: true
        })
      }
      if (
        name.value && 
        email.value && emailRegex.test(email.value) &&
        message.value
      ) {
        setOpen(true);
      }
    }

    return (
      <Form
        layout={"vertical"}
        style={{
          maxWidth: 600,
        }}
      >
        <Form.Item label="Name" required style={{fontWeight: "bolder"}}>
          <Input
            placeholder={name.error ? errorMessage : "Enter your name"}
            value={name.value || ''}
            status={name.error ? "error" : undefined}
            onChange={(e)=>{setName({value: e.target.value, error: e.target.value? false: true})}}
          />
        </Form.Item>
        <Form.Item label="Email" required style={{fontWeight: "bolder"}}>
          <Input
            placeholder={email.error ? errorMessage : "Enter a valid email address"}
            value={email.value || ''}
            status={email.error ? "error" : undefined}
            onChange={(e)=>setEmail({value: e.target.value, error: e.target.value? false: true})}
          />
        </Form.Item>
        <Form.Item label="How can we help?" required style={{fontWeight: "bolder"}}>
          <TextArea 
            rows={5}
            placeholder={message.error ? errorMessage : "Message..."}
            value={message.value || ''}
            status={message.error ? "error" : undefined}
            onChange={(e)=>setMessage({value: e.target.value, error: e.target.value? false: true})}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            style={{background: "#0072A4", borderColor: "#0072A4"}}
            onClick={()=>sendMessage(name, email, message)}
          >
            Submit
          </Button>
          <Modal
            title="Are you sure you want to send the following?"
            open={open}
            onOk={()=>handleOk(name, email, message)}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <p>Name: <strong><span>{name.value}</span></strong></p>
            <p>Email: <strong><span>{email.value}</span></strong></p>
            <p>Message:</p>
            <strong><p>{message.value}</p></strong>
          </Modal>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Content className="landing-third">
      <Title level={3} className="landing-label-white" style={{fontWeight: "bolder", textAlign: "center"}}>
        Get in Touch!
      </Title>
      <Title level={5} className="landing-label-white" style={{fontWeight: "bolder", textAlign: "center"}}>
        We are here to assist you.
      </Title>

      <Row gutter={50} align={"center"} justify={"center"} style={{paddingTop: "2%"}}>
        {CardItems.map((item) => {
         return (
          <DetailCard key={item.id} title={item.title} description={item.description} icon={item.icon}/>
         ) 
        })}
      </Row>

      <Row gutter={50} justify={"center"} style={{paddingTop: "2%"}}>
        <Col lg={{span: 10}} md={{span: 24}} style={{width: "100%"}}>
          <Content
            style={{
              // padding: 24,
              margin: 0,
              // minHeight: 453,
              background: "#fff",
            }}
          >
            {/* map */}
            <MapComponent mapStyle={{height: "65vh", minHeight: "100%"}}/>
          </Content>
        </Col>
        <Col lg={{span: 10}} sm={{span: 24}} style={{width: "100%"}}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: "65vh",
              background: "#fff",
            }}
          >
            <ContactUsForm/>
          </Content>
        </Col>
      </Row>
    </Content>
  )
};
