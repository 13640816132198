import React, { useContext, useEffect, useState } from "react";
import {
  Marker,
  Tooltip as MapTooltip,
} from 'react-leaflet';
import { Row, Col, Typography, Tag, Card } from 'antd';
import _ from 'lodash';

// import constants and functions, context, components
import { transformLocation, roundTo } from "../Functions";
import { MapContext } from "../../../Contexts/MapContext";
import { MapIconSquare, MapIconCircle } from "../../../Components/MapIconSquare";
import '../../../Assets/Styles/StationMarker.css';
import * as url from "../../../Urls";

const AQI_LEGEND = url.REACT_APP_AQI_LEGEND_URL;
const DENR_API_URL = url.REACT_APP_DENR_API_URL;

const { Meta } = Card;


const StationMarker = ({data}) => {
  const [denrData, setDenrData] = useState({})
  const [defaultValue, setDefaultValue] = useState()
  const [legendValues, setLegendValues] = useState()
  const { 
    viewDownloadableFileData, setViewDownloadableFileData,
    stationsData,
  } = useContext(MapContext)

  var isViewed = _.isEqual(data, viewDownloadableFileData)
  var path_name = data.path_name;

  const loadAQILegend = async () => {
    fetch(AQI_LEGEND)
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            // catches error for non-existent endpoints
            `This is an HTTP error: The status is ${res.status}`
          );
        }
        return res.json();
      })
      .then((body) => {
        var legends = body.results
        // Use the forEach method to add the new key-value pair to each object
        if (legends) {
          legends.forEach(obj => {
            obj["key"] = obj["label"];
          });
        }
        setLegendValues(legends)
      })
      .catch((e) => {
        console.log("error", e)
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const cleanUp = (data) => {
    // console.log("start", data.Station)
    const checkNegativeValue = (key, key_2) => {
      // if data["RawPM10"] is negative, set Remarks to OFFLINE
      if (data.hasOwnProperty(key)) {
        // console.log(key, data[key])
        const rawpm = parseFloat(data[key])
        if (rawpm < 0) {
          data[key_2] = defaultValue.label
        }
      }
    }
    
    const assignFillColor = (key) => {
      // assign fill_color based on data[Remarks]
      const fillColorKey = `fill_color${key.replace("Remarks", "")}`
      data[fillColorKey] = defaultValue.fill_color
      const legendValue = legendValues.find((value) => {
        return value.label === data[key]
      })
      if (legendValue && legendValue.hasOwnProperty("fill_color")) {
        data[fillColorKey] = legendValue.fill_color
      }
    }
    
    if (Object.keys(data).length > 0) {
      checkNegativeValue("RawPM10", "RemarksPM10")
      checkNegativeValue("RawPM25", "RemarksPM25")
      assignFillColor("RemarksPM10")
      assignFillColor("RemarksPM25")
      // console.log("cleanedup", data)
      setDenrData(data)
    }
  };
  
  const loadData = async () => {
    fetch(DENR_API_URL + "/" + path_name)
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            // catches error for non-existent endpoints
            `This is an HTTP error: The status is ${res.status} for ${path_name}`
          );
        }
        return res.json();
      })
      .then((body) => {
        if (typeof(body) == 'object') {
          // console.log("body", body)
          setDenrData(body)
          cleanUp(body)
        }
      })
      .catch((e) => {
        console.log("error", e)
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    loadAQILegend()
  }, [])

  useEffect(() => {
    if (path_name) {
      loadData()
    }
  }, []);

  useEffect(() => {
    if (legendValues) {
      setDefaultValue(
        legendValues.find((value) => {
          return value.label === "Offline"
        })
      )
    }
  }, [legendValues])

  const CardContent = ({denrData}) => {
    return (
      <>
        {Object.entries(denrData).map(([key, value]) => {
          if (["RawPM10", "RawPM25", "RemarksPM10", "RemarksPM25"].includes(key)) { return }
          if (["AQIPM10", "AQIPM25"].includes(key)) {
            return (
              <Row className="card-row" key={value}>
                <Col><Typography>{key.replace("AQI","")}</Typography></Col>
                <Col>
                  <Tag
                    className="tag-content"
                    style={{borderColor: denrData[`fill_color${key.replace("AQI","")}`]}}
                  >
                    <Typography>
                      {parseFloat(value) > 0 ? roundTo(value, 3) : defaultValue.label}
                    </Typography>
                  </Tag>
                </Col>
              </Row>
            )}
          })
        }
        <Meta
          description={"Data as of " + denrData["DateTime"].split("T")[0] + " " + denrData["DateTime"].split("T")[1].split(".")[0]}
          className="meta-content"
        />
      </>
    )
  };

  return (
    legendValues && defaultValue &&
    <Marker
      position={transformLocation(data.location)} 
      // icon={Object.keys(denrData).length > 0 ? 
      //   data.station_id == 1 ?
      //   MapIconSquare(denrData.FillColorPM10, isViewed): MapIconSquare(defaultValue.fill_color, isViewed) :
      //   data.station_id == 2 ?
      //   MapIconCircle(denrData.FillColorPM10, isViewed): MapIconCircle(defaultValue.fill_color, isViewed)
      // }
      icon={Object.keys(denrData).length > 0 ? 
        data.station_id == 1 ?
        MapIconSquare(denrData.FillColorPM10, isViewed):
        data.station_id == 2 ?
        MapIconCircle(denrData.FillColorPM10, isViewed): MapIconCircle(denrData.FillColorPM10, isViewed) : 
        data.station_id == 1 ? 
        MapIconSquare(defaultValue.fill_color, isViewed):
        data.station_id == 2 ?
        MapIconCircle(defaultValue.fill_color, isViewed): MapIconCircle(defaultValue.fill_color, isViewed)
      }

      // icon={Object.keys(denrData).length > 0 ? MapIconSquare(denrData.FillColorPM10, isViewed): MapIconSquare(defaultValue.fill_color, isViewed)}
      key={data.id}
      eventHandlers={{
        click: (e) => {
          if (isViewed) {
            setViewDownloadableFileData()
          } else {
            setViewDownloadableFileData(stationsData.results.find(d => d.id === data.id))
          }
        }
      }}
    >
      <MapTooltip direction="right" offset={[0, 0]}>
        <Card title={`${data.id} - ${data.name}`}>
          <div className="card-div">
            {Object.keys(denrData).length > 0 ? <CardContent denrData={denrData}/> : <Col><Typography>{defaultValue.label}</Typography></Col>}
          </div>
        </Card>
      </MapTooltip>
    </Marker>
  )
};

export default StationMarker;
