import React, { memo } from "react";
import { Table } from "antd";
import moment from "moment";

  
const CsvTable = (props) => {
  const dateFormat = "YYYY-MM-DD HH:mm";
  let dataSource
  let columns = props.columns
  if (props.dataSource) {
    props.dataSource.forEach((data, index) => {
      // console.log("data", index)
      data.key = data.local_time + "-" + index
      data.local_time = moment(data.local_time).format(dateFormat)
      // console.log("data item", moment(data.local_time).format(dateFormat))
    })
    dataSource = props.dataSource
  }

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      size="small"
      style={{
        //   height: "100%",
          zIndex: 1000
      }}
      scroll={{ y: "100%" }}
      {...props}
    />
  );
};

export default memo(CsvTable);
