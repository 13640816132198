import React, { memo, useContext, useEffect } from "react";
import _ from 'lodash';
import moment from "moment";
import {
  Space,
  // Checkbox,
  Tooltip,
  Typography,
  // Collapse,
  // Slider,
} from "antd";
import { 
  FileSearchOutlined,
  LoadingOutlined,
  // ZoomInOutlined,
  // DownloadOutlined,
  // MinusCircleFilled,
  // PlusOutlined,
} from "@ant-design/icons";
import { MapContext } from "../../../Contexts/MapContext";
import { SatelliteImagesContext } from "../../../Contexts/SatelliteImagesContext";
import "../../../Assets/Styles/CheckboxFilter.css";
import * as url from "../../../Urls"


const GEOSERVER_URL = url.GEOSERVER_URL

const CheckboxSatelliteImages = ({ filters }) => {
  const parent = JSON.parse(JSON.stringify(filters.name)).replace(/\s+/g, "")
  const { satelliteImagesData, setSatelliteImagesData } = useContext(SatelliteImagesContext)
  const { viewDownloadableFileData, setViewDownloadableFileData } = useContext(MapContext)
  const isViewed = parent === viewDownloadableFileData?.workspace

  useEffect(() => {
    console.log("fetching", parent)
    let controller = new AbortController();
    const signal = controller.signal;
    const username = "admin";
    const password = "airmovegeoserver";

    function appendDateTime(layer) {
      var layerWithDate = layer.map(item => {
        //dateStr formatted as YYYY-MM-DD
        // console.log("split layerWithDate item[name]", item["name"].split("_"))
        var [satelliteName, date, month, year, time] = item["name"].split("_")
        // console.log("split satelliteName", satelliteName)
        // console.log("layerWithDate, split date", date, month, year, time)
        var dateStr = year + "-" + month + "-" + date
        if (time) {
          var formattedTime = time.slice(0, 2) + ":" + time.slice(2, 4) + ":" + time.slice(4);
          if (formattedTime) {
            dateStr = dateStr + "T" + formattedTime
          }
        }
        return {
          ...item,
          "date": moment(dateStr),
          "satellite": satelliteName,
        }
      })
      return layerWithDate
    }

    function loadDataFromWorkspace (workspaceName) {
      const SATELLITE_IMAGES_URL = GEOSERVER_URL + "/rest/workspaces/" + workspaceName + "/layers.json"
      console.log("fetching from SATELLITE_IMAGES_URL", SATELLITE_IMAGES_URL)
      // console.log("fetching from workspaceName", workspaceName)
      // console.log("checking satelliteImagesData from workspaceName", satelliteImagesData)
      fetch(SATELLITE_IMAGES_URL, {
        signal: signal,
        headers: new Headers({
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        })
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            // catches error for non-existent endpoints
            `This is an HTTP error: The status is ${res.status}`
          );
        }
        return res.json();
      })
      .then((body) => {
        // body["workspace"] = workspaceName
        // console.log("body", body)
        // setSatelliteImagesData(satelliteImagesData => ({
        //   ...satelliteImagesData,
        //   [workspaceName]: body
        // }));
        try {
          var layerWithDate = appendDateTime(body["layers"]["layer"])
          var bodyWithDate = {
            "layers": {
              "layer": layerWithDate
            },
            "workspace": workspaceName
          }
          // console.log("bodyWithDate", bodyWithDate)
          setSatelliteImagesData(satelliteImagesData => ({
            ...satelliteImagesData,
            [workspaceName]: bodyWithDate
          }));
        } catch (err) {
          console.log("err", err)
        }
        
      })
      .catch((e) => {
        console.log("error", e)
      })
      .finally(() => {
        // setLoading(false);
      });
    };

    loadDataFromWorkspace(parent);
    return () => {
      controller?.abort();
    }
  }, [parent]);


  function selectDataPoint(parent) {
    if (isViewed) {
      setViewDownloadableFileData()
    } else {
      try {
        // catch error: undefined
        setViewDownloadableFileData(satelliteImagesData[parent])
      } catch (err) {
        console.log("error", err)
      }
    }
  }

  return (
    <Space direction="vertical" style={{width: "100%"}}>
      <Space style={{width: "100%", justifyContent: "space-between", paddingBlock: "10px"}}>
        <Typography>
          {parent.replace(/_/g, " ")}
        </Typography>
        {satelliteImagesData && satelliteImagesData.hasOwnProperty(parent) ?
          <Tooltip placement="top" title="View downloadable files">
            <FileSearchOutlined
              onClick={() => selectDataPoint(parent)}
              style={{color: isViewed ? "#1890ff": "gray" }}
            />
          </Tooltip>
          :
          <Tooltip placement="top" title="Loading...">
            <LoadingOutlined/>
          </Tooltip>
        }
      </Space>
    </Space>
  );
};

export default memo(CheckboxSatelliteImages);
