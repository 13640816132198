import React from "react";
import L from 'leaflet';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css'
import { roundTo } from "../Functions";


const LeafletDraw = (props) => {
    const position = props.position || "topleft"

    var drawOptions = {
        polyline: {
          shapeOptions: {
            color: "red"
          }
        },
        polygon: {
          shapeOptions: {
            color: "red",
            fillColor: "pink",
          }
        },
        rectangle: {
          shapeOptions: {
            color: "red",
            fillColor: "pink",
          }
        },
        circle: {
          shapeOptions: {
            color: "red",
            fillColor: "pink",
          }
        },
        circlemarker: false,
    }
    
    // Helper method to format LatLng object (x.xxxxxx, y.yyyyyy)
    var strLatLng = function(latlng) {
        return "Lat: " + roundTo(latlng.lat, 6) + ", Lng: " + roundTo(latlng.lng, 6);
    };

    /**
     * 
     * @param {*} layer 
     * @returns HTML string, or null if unknown object
     */
    var getPopupContent = function(layer) {
        // Marker - add lat/long
        if (layer instanceof L.Marker) {
            // console.log("getPopupContent", layer.layerType)
            return strLatLng(layer.getLatLng());
        // Circle - lat/long, radius
        } else if (layer instanceof L.CircleMarker) {
            var center = layer.getLatLng(),
                radius = layer.getRadius();
            return "Center: "+ strLatLng(center) + "<br/>"
                + "Radius: " + roundTo(radius, 2) + " m";
        // Rectangle/Polygon - area
        } else if (layer instanceof L.Polygon) {
            var latlngs = layer._defaultShape ? layer._defaultShape() : layer.getLatLngs(),
                area = L.GeometryUtil.geodesicArea(latlngs);
            return "Area: " + L.GeometryUtil.readableArea(area, true);
        // Polyline - distance
        } else if (layer instanceof L.Polyline) {
            var latlngs = layer._defaultShape ? layer._defaultShape() : layer.getLatLngs(),
                distance = 0;
            if (latlngs.length < 2) {
                return "Distance: N/A";
            } else {
                for (var i = 0; i < latlngs.length-1; i++) {
                    distance += latlngs[i].distanceTo(latlngs[i+1]);
                }
                return "Distance: " + roundTo(distance, 2) + " m";
            }
        }
        return null;
    };

    const _created = (e) => {
        // console.log("onCreated", e.layer)
        var layer = e.layer;
        var content = getPopupContent(layer);
        // console.log("content", content)
        if (content !== null) {
            layer.bindPopup(content);
        }
    };

    const _edited = (e) => {
        var layers = e.layers,
        content = null;
        layers.eachLayer(function(layer) {
            content = getPopupContent(layer);
            if (content !== null) {
                layer.setPopupContent(content);
            }
        });
    }

    return (
        <FeatureGroup>
            <EditControl
                position={position}
                onCreated={_created}
                onEdited={_edited}
                draw={drawOptions}
            />
        </FeatureGroup>
    )
}

export default LeafletDraw;
