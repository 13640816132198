import React, { memo, useContext } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { MapContext } from "../../../../Contexts/MapContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const CsvChart = (props) => {
  const { chartRefs } = useContext(MapContext)
  let dataSource = props.dataSource
  let columns = props.columns
  let layerName = props.layer?.name

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      // title: {
      //   display: false,
      //   text: layerName,
      // },
    },
  };
  
  // Arrays to store dates, pm25 values, and pm10 values
  var dates = [];
  var pm25_avg = [];
  var pm25_min = [];
  var pm25_max = [];
  var pm25_std = [];
  var pm10_avg = [];
  var pm10_min = [];
  var pm10_max = [];
  var pm10_std = [];
  
  if (dataSource) {
      // Iterate over the array of objects
    for (var i = 0; i < dataSource.length; i++) {
      var obj = dataSource[i];
      // console.log("obj", obj)
      var date = obj.date;
      var pm25_avg_value = obj.pm25_avg;
      var pm25_min_value = obj.pm25_min;
      var pm25_max_value = obj.pm25_max;
      var pm25_std_value = obj.pm25_std;
      var pm10_avg_value = obj.pm10_avg;
      var pm10_min_value = obj.pm10_min;
      var pm10_max_value = obj.pm10_max;
      var pm10_std_value = obj.pm10_std;
  
      // Push values to respective arrays
      dates.push(date);
      pm25_avg.push(pm25_avg_value);
      pm25_min.push(pm25_min_value);
      pm25_max.push(pm25_max_value);
      pm25_std.push(pm25_std_value);
      pm10_avg.push(pm10_avg_value);
      pm10_min.push(pm10_min_value);
      pm10_max.push(pm10_max_value);
      pm10_std.push(pm10_std_value);
    }
  }

  const newDataSource = []
  newDataSource.push({
    "pm25_avg": pm25_avg,
    "pm25_min": pm25_min,
    "pm25_max": pm25_max,
    "pm25_std": pm25_std,
    "pm10_avg": pm10_avg,
    "pm10_min": pm10_min,
    "pm10_max": pm10_max,
    "pm10_std": pm10_std,
  })

  // Create references for each chart
  const createRef = (chart) => {
    if (chart && !chartRefs.current.includes(chart)) {
      chartRefs.current.push(chart);
    }
  };


  return (
    <div>
      {newDataSource[0]["pm10_avg"].length > 0 && columns.map((column) => {
        if (!column.title.includes("PM")) {
          return null
        }
        const data = {
          labels: dates,
          datasets: [
            {
              label: layerName + " - " + column.title,
              data: newDataSource[0][column.key],
              backgroundColor: '#263F6B',
            },
          ],
        };
          
        return (
          data && 
          <div style={{marginBottom:"10%"}} key={column.key}>
            <Bar ref={createRef} options={options} data={data}/>
          </div>
        )
      })}
    </div>
  )
}
export default memo(CsvChart);
