import { DownloadOutlined } from "@ant-design/icons";
import { Typography, Row, Col, Space, Table, Tooltip } from "antd";
import { Content } from "antd/lib/layout/layout";

const { Title, Text } = Typography;


export const ContentFive = () => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <Title className="landing-label-primary" level={4}>{text}</Title>,
    },
    // {
    //   title: 'Description',
    //   dataIndex: 'description',
    //   key: 'description',
    //   width: "70%",
    //   render: (text) => <Text className="landing-label-primary" style={{fontSize: 12}}>{text}</Text>,
    // },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {/* <Tooltip title={`Download ${record.name}`}>
            <a class={!record.paperUrl && "disabled-link"} onClick={()=>window.open(`../../Pdfs/${record.paperUrl}`, '_blank')}>
              <DownloadOutlined  style={{fontSize: "16px"}} />
            </a>
          </Tooltip> */}
          <Tooltip title={`Read on ${record.title}`}>
            <a target="_blank" href={record.paperUrl} class={!record.paperUrl && "disabled-link"}><DownloadOutlined disabled style={{fontSize: "16px"}}/></a>
          </Tooltip>
          {/* <DownloadButton/> */}
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: '1',
      name: 
      <div>
        <div>
          HOTSPOT ANALYSIS AND COMPARISON BETWEEN SATELLITE-DERIVED AEROSOL OPTICAL DEPTH AND GROUND-BASED PARTICULATE MATTER MEASUREMENTS IN METRO MANILA
        </div>
        <Text style={{fontSize: 12}}>
          B. A. B. Recto, R. A. B. Torres, R. V. Ramos, A. M. Tamondong, M. G. Cayetano, and B. J. D. Jiao
        </Text>
      </div>,
      title: "HOTSPOT ANALYSIS AND COMPARISON BETWEEN SATELLITE-DERIVED AEROSOL OPTICAL DEPTH AND GROUND-BASED PARTICULATE MATTER MEASUREMENTS IN METRO MANILA",
      // description: "The Technical Manual and User Guide includes the design and development of Airmove's Web Platform, information about data that are contained in it and provides clear instructions on the operation of the platform. How",
      // description: <div>
      //   <div>The Technical Manual and User Guide includes the design and development of Airmove's Web Platform, information about data that are contained in it and provides clear instructions on the operation of the platform. </div>
      //   <div>How to cite: 
      //     <strong> UP TCAGP (2024). The Airmove Web Platform - Technical Manual and User Guide.pdf</strong>
      //   </div></div>,
      paperUrl: "https://isprs-annals.copernicus.org/articles/X-4-W1-2022/639/2023/",
      // tags: ['nice', 'developer'],
    },
    {
      key: '2',
      name: 
      <div>
        <div>
          ESTIMATING AIRBORNE PARTICULATE MATTER IN THE NATIONAL CAPITAL REGION, PHILIPPINES USING MULTIPLE LINEAR REGRESSION AND GRADIENT BOOSTING ALGORITHM ON MODIS MAIAC AEROSOL OPTICAL DEPTH
        </div>
        <Text style={{fontSize: 12}}>
          R. A. B. Torres, R. V. Ramos, B. A. B. Recto, A. M. Tamondong, B. J. D. Jiao, and M. G. Cayetano
        </Text>
      </div>,
      title: 'ESTIMATING AIRBORNE PARTICULATE MATTER IN THE NATIONAL CAPITAL REGION, PHILIPPINES USING MULTIPLE LINEAR REGRESSION AND GRADIENT BOOSTING ALGORITHM ON MODIS MAIAC AEROSOL OPTICAL DEPTH',
      // description:<div>
      // <div>Training Manual - Laboratory Exercise 1</div>
      // <div>How to cite: 
      //   <strong> UP TCAGP (2024). Ex1_Google Earth Engine GIS Data Portals.pdf</strong>
      // </div></div>,
      paperUrl: "https://isprs-annals.copernicus.org/articles/X-4-W1-2022/729/2023/"
    },
    {
      key: '3',
      name: 
      <div>
        <div>
          ANALYSIS OF KRIGING INTERPOLATION MODELS FOR PARTICULATE MATTER LEVELS IN THE NATIONAL CAPITAL REGION, PHILIPPINES
        </div>
        <Text style={{fontSize: 12}}>
          R. A. B. Torres, R. V. Ramos, B. A. B. Recto, A. M. Tamondong
        </Text>
      </div>,
      title: 'ANALYSIS OF KRIGING INTERPOLATION MODELS FOR PARTICULATE MATTER LEVELS IN THE NATIONAL CAPITAL REGION, PHILIPPINES',
      // description:<div>
      // <div>Training Manual - Laboratory Exercise 1</div>
      // <div>How to cite: 
      //   <strong> UP TCAGP (2024). Ex1_Google Earth Engine GIS Data Portals.pdf</strong>
      // </div></div>,
      paperUrl: "https://isprs-annals.copernicus.org/articles/X-4-W1-2022/729/2023/isprs-annals-X-4-W1-2022-729-2023.pdf"
    },
    {
      key: '4',
      name: 
      <div>
        <div>
          A PRELIMINARY ASSESSMENT OF GEMS NO2 COMPARED WITH SENTINEL 5P TROPOMI FOR MONITORING AIR POLLUTION IN THE NATIONAL CAPITAL REGION, PHILIPPINES
        </div>
        <Text style={{fontSize: 12}}>
          B. A. B. Recto, R. A. B. Torres, R. V. Ramos, A. M. Tamondong, A. J. Sabuito
        </Text>
      </div>,
      title: 'A PRELIMINARY ASSESSMENT OF GEMS NO2 COMPARED WITH SENTINEL 5P TROPOMI FOR MONITORING AIR POLLUTION IN THE NATIONAL CAPITAL REGION, PHILIPPINES',
      // description:<div>
      // <div>Training Manual - Laboratory Exercise 1</div>
      // <div>How to cite: 
      //   <strong> UP TCAGP (2024). Ex1_Google Earth Engine GIS Data Portals.pdf</strong>
      // </div></div>,
      // paperUrl: "https://isprs-annals.copernicus.org/articles/X-4-W1-2022/729/2023/isprs-annals-X-4-W1-2022-729-2023.pdf"
    },
    {
      key: '5',
      name: 
      <div>
        <div>
          DESIGN AND DEVELOPMENT FRAMEWORK FOR AN AIR QUALITY MONITORING WEBGIS
        </div>
        <Text style={{fontSize: 12}}>
          K. P. Panlilio, R. R. C. Sta Ana, R. V. Ramos, B. A. B. Recto, R. A. B. Torres
        </Text>
      </div>,
      title: 'DESIGN AND DEVELOPMENT FRAMEWORK FOR AN AIR QUALITY MONITORING WEBGIS',
      // description:<div>
      // <div>Training Manual - Laboratory Exercise 1</div>
      // <div>How to cite: 
      //   <strong> UP TCAGP (2024). Ex1_Google Earth Engine GIS Data Portals.pdf</strong>
      // </div></div>,
      // paperUrl: "https://isprs-annals.copernicus.org/articles/X-4-W1-2022/729/2023/isprs-annals-X-4-W1-2022-729-2023.pdf"
    },
    {
      key: '6',
      name: 
      <div>
        <div>
          DEVELOPMENT OF A WEB PLATFORM FOR AIR QUALITY MONITORING
        </div>
        <Text style={{fontSize: 12}}>
          R. R. C. Sta Ana, K. P. Panlilio, R. V. Ramos, R. A. B. Torres, B. A. B. Recto
        </Text>
      </div>,
      title: 'DEVELOPMENT OF A WEB PLATFORM FOR AIR QUALITY MONITORING',
      // description:<div>
      // <div>Training Manual - Laboratory Exercise 1</div>
      // <div>How to cite: 
      //   <strong> UP TCAGP (2024). Ex1_Google Earth Engine GIS Data Portals.pdf</strong>
      // </div></div>,
      paperUrl: "https://isprs-archives.copernicus.org/articles/XLVIII-4-W8-2023/431/2024/isprs-archives-XLVIII-4-W8-2023-431-2024.pdf"
    },
    {
      key: '7',
      name: 
      <div>
        <div>
          DEVELOPMENT OF LAND-USE REGRESSION MODELS FOR PARTICULATE MATTER ESTIMATION IN NATIONAL CAPITAL REGION, PHILIPPINES
        </div>
        <Text style={{fontSize: 12}}>
          R. A. B. Torres, R. V. Ramos, B. A. B. Recto, and A. M. Tamondong
        </Text>
      </div>,
      title: 'DEVELOPMENT OF LAND-USE REGRESSION MODELS FOR PARTICULATE MATTER ESTIMATION IN NATIONAL CAPITAL REGION, PHILIPPINES',
      // description:<div>
      // <div>Training Manual - Laboratory Exercise 1</div>
      // <div>How to cite: 
      //   <strong> UP TCAGP (2024). Ex1_Google Earth Engine GIS Data Portals.pdf</strong>
      // </div></div>,
      paperUrl: "https://isprs-archives.copernicus.org/articles/XLVIII-4-W8-2023/437/2024/isprs-archives-XLVIII-4-W8-2023-437-2024.pdf"
    },
  ];
  
  return (
    <Content className="landing-first">
      <Title level={3} className="landing-label-white" style={{fontWeight: "bolder"}}>
        Publications
      </Title>
      {/* <Title level={5} className="landing-label-white" style={{fontWeight: "bolder"}}>
        The AiRMoVE Technical Documentation contains detailed informaton about the Project.
        It includes several step-by-step guides on our products and the toolkits that the project used in processing its outputs.
      </Title> */}
      <Row gutter={50} justify={"center"} style={{paddingTop: "2%"}}>
        <Col lg={{span: 20}} md={{span: 24}}>
          <Table
            style={{width: "100%"}}
            columns={columns}
            dataSource={data}
            pagination={false}
            showHeader={false}
            bordered
            scroll={{
              x: 500,
            }}
          />
        </Col>
      </Row>
    </Content>
  )
};
