import { DownloadOutlined } from "@ant-design/icons";
import { Typography, Row, Col, Space, Table, Tooltip } from "antd";
import { Content } from "antd/lib/layout/layout";


const { Title, Text } = Typography;


export const ContentTwo = () => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <Title className="landing-label-primary" level={4}>{text}</Title>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: "70%",
      render: (text) => <Text className="landing-label-primary" style={{fontSize: 12}}>{text}</Text>,
    },
    // {
    //   title: 'Tags',
    //   key: 'tags',
    //   dataIndex: 'tags',
    //   render: (_, { tags }) => (
    //     <>
    //       {tags.map((tag) => {
    //         let color = tag.length > 5 ? 'geekblue' : 'green';
    //         if (tag === 'loser') {
    //           color = 'volcano';
    //         }
    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title={`Download ${record.name}`}>
            <a target="_blank" href = {`${record.link}`} class={!record.toolbox_name}><DownloadOutlined disabled style={{fontSize: "16px"}}/></a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: '1',
      name: 'Downloading Toolkit',
      description: "The Data Processing Tools component of the platform is composed of command-line-based software developed to automate multiple repetitive tasks needed by the team to complete research tasks. These scripts have been tested under Debian 11 Bullseye, but they should be runnable under other operating systems with minimal modifications. They are written in Python and are scheduled to run inside a Debian-based workstation using cron. Cron is a job scheduler application found in most Unix-style operating systems.",
      toolbox_name: "sample_toolbox_1",
      link: "https://github.com/projectairmove/airmove-processing-tools",
      // tags: ['nice', 'developer'],
    },
    {
      key: '2',
      name: 'Pre-processing Toolkit',
      description: "The Airmove Data Notebooks are a set of Jupyter Notebook-based scripts that can be used to generate data relevant to Airmove research. Prepares a CSV file containing station readings from EMB. This file will be in the format expected by Airmove API.",
      toolbox_name: "sample_toolbox_2",
      link: "https://github.com/projectairmove/notebook-airmove-data",
      // tags: ['loser'],
    },
  ];

  // const expandedRowRender = () => {
  //   const columns = [
  //     {
  //       title: 'Date',
  //       dataIndex: 'date',
  //       key: 'date',
  //     },
  //     {
  //       title: 'Name',
  //       dataIndex: 'name',
  //       key: 'name',
  //     },
  //     {
  //       title: 'Status',
  //       key: 'state',
  //       render: () => <Badge status="success" text="Finished" />,
  //     },
  //     {
  //       title: 'Upgrade Status',
  //       dataIndex: 'upgradeNum',
  //       key: 'upgradeNum',
  //     },
  //     // {
  //     //   title: 'Action',
  //     //   dataIndex: 'operation',
  //     //   key: 'operation',
  //     //   render: () => (
  //     //     <Space size="middle">
  //     //       <a>Pause</a>
  //     //       <a>Stop</a>
  //     //       <Dropdown
  //     //         menu={{
  //     //           items,
  //     //         }}
  //     //       >
  //     //         <a>
  //     //           More <DownOutlined />
  //     //         </a>
  //     //       </Dropdown>
  //     //     </Space>
  //     //   ),
  //     // },
  //   ];
  //   const data = [];
  //   for (let i = 0; i < 3; ++i) {
  //     data.push({
  //       key: i.toString(),
  //       date: '2014-12-24 23:12:00',
  //       name: 'This is production name',
  //       upgradeNum: 'Upgraded: 56',
  //     });
  //   }
  //   // return <Table columns={columns} dataSource={data} pagination={false} />;
  //   return <div>asdsad</div>;
  // };

  return (
    <Content className="landing-second">
      <Title level={3} className="landing-label-primary" style={{fontWeight: "bolder"}}>
        Toolkits
      </Title>
      {/* <Title level={5} className="landing-label-primary" style={{fontWeight: "bolder"}}>
        Insert description for Toolkits
      </Title> */}
      <Row gutter={50} justify={"center"} style={{paddingTop: "2%"}}>
        <Col lg={{span: 20}} md={{span: 24}}>
          <Table
            style={{width: "100%"}}
            columns={columns}
            dataSource={data}
            pagination={false}
            showHeader={false}
            bordered
            scroll={{
              x: 500,
            }}
            // onRow={(record, index) => ({
            //   style: {
            //       // background: index === 0 ? 'red' : 'blue',
            //       padding: index === 0 ? '2%' : 0,
            //   }
            // })}
            // rowClassName={'table-row-dark'}
            // expandable={{
            //   expandedRowRender,
            //   defaultExpandedRowKeys: ['0'],
            // }}
          />
        </Col>
      </Row>
    </Content>
  )
};
