import React, { memo, useContext } from 'react';
import {
  Chart as ChartJS,
  // CategoryScale,
  // LinearScale,
  // BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { MapContext } from "../../../../Contexts/MapContext";

ChartJS.register(
  // CategoryScale,
  // LinearScale,
  // BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);


const CsvChart = (props) => {
  const { chartRefs } = useContext(MapContext)
  let dataSource = props.dataSource
  let columns = props.columns
  // let layerName = props.layer?.name

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      // title: {
      //   display: false,
      //   text: layerName,
      // },
    },
  };
  
  // Arrays to store dates, pm25 values, and pm10 values
  var dates = [];
  var pm10_24h_emb_aqi = [];
  var pm25_24h_emb_aqi = [];
  var pm10_emb = [];
  var pm25_emb = [];
  // var temperature_max = [];
  // var temperature_mean = [];
  // var relative_humidity = [];
  // var wind_speed = [];
  // var wind_direction = [];
  // var cloud = [];
  // var station_pressure = [];
  
  if (dataSource) {
      // Iterate over the array of objects
    for (var i = 0; i < dataSource.length; i++) {
      var obj = dataSource[i];
      // console.log("obj", obj)
      var date = obj.local_time;
      var pm10_24h_emb_aqi_value = obj.pm10_24h_emb_aqi;
      var pm25_24h_emb_aqi_value = obj.pm25_24h_emb_aqi;
      var pm10_emb_value = obj.pm10_emb;
      var pm25_emb_value = obj.pm25_emb;
      // var temperature_min_value = obj.temperature_min;
      // var temperature_max_value = obj.temperature_max;
      // var temperature_mean_value = obj.temperature_mean;
      // var relative_humidity_value = obj.relative_humidity;
      // var wind_speed_value = obj.wind_speed;
      // var wind_direction_value = obj.wind_direction;
      // var cloud_value = obj.cloud;
      // var station_pressure_value = obj.station_pressure;
  
      // Push values to respective arrays
      dates.push(date);
      pm10_24h_emb_aqi.push(pm10_24h_emb_aqi_value);
      pm25_24h_emb_aqi.push(pm25_24h_emb_aqi_value);
      pm10_emb.push(pm10_emb_value);
      pm25_emb.push(pm25_emb_value);
      // temperature_max.push(temperature_max_value);
      // temperature_mean.push(temperature_mean_value);
      // relative_humidity.push(relative_humidity_value);
      // wind_speed.push(wind_speed_value);
      // wind_direction.push(wind_direction_value);
      // cloud.push(cloud_value);
      // station_pressure.push(station_pressure_value);
    }
  }

  const newDataSource = []
  newDataSource.push({
    "pm10_24h_emb_aqi": pm10_24h_emb_aqi,
    "pm25_24h_emb_aqi": pm25_24h_emb_aqi,
    "pm10_emb": pm10_emb,
    "pm25_emb": pm25_emb,
    // "temperature_max": temperature_max,
    // "temperature_mean": temperature_mean,
    // "relative_humidity": relative_humidity,
    // "wind_speed": wind_speed,
    // "wind_direction": wind_direction,
    // "cloud": cloud,
    // "station_pressure": station_pressure,
  })

  // Create references for each chart
  const createRef = (chart) => {
    if (chart && !chartRefs.current.includes(chart)) {
      chartRefs.current.push(chart);
    }
  };
  
  // console.log("newDataSource", newDataSource)
  // console.log("dates", dates)

  return (
    <div>
      {newDataSource[0]["pm10_24h_emb_aqi"].length > 0 && columns.map((column) => {
        // console.log("columntitle", column.title)
        // console.log("key", column.key, newDataSource[0][column.key])
        const isNullish = newDataSource[0][column.key] && newDataSource[0][column.key].every(value => {
          if (value === null) {
            return true;
          }
          return false;
        });

        const data = {
          labels: dates,
          datasets: [
            {
              label: column.title,
              data: newDataSource[0][column.key],
              borderColor: '#263F6B',
              backgroundColor: 'rgba(255, 99, 132, 0)',
              borderWidth: 0.5
            },
          ],
        };
          
        return (
          data && newDataSource[0][column.key] && !isNullish &&
          <div style={{marginBottom:"10%"}} key={column.key}>
            {/* <Bar ref={createRef} options={options} data={data}/> */}
            <Line ref={createRef} options={options} data={data}/>
          </div>
        )
      })}

    </div>
  )
}
export default memo(CsvChart);
