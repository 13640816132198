import React, { createContext, useState, useRef } from 'react';

export const MapContext = createContext();

const MapContextProvider = (props) => {
    const [stationsData, setStationsData] = useState({}); // All Reference Grade Stations from API
    const [weatherStationsData, setWeatherStationsData] = useState({}); // All Reference Grade Stations from API
    const [mapRef, setMapRef] = useState() // Map instance
    const [viewDownloadableFileData, setViewDownloadableFileData] = useState() // Selected Station ID in number format (View downloadable files)
    const chartRefs = useRef([]);
    const [isGeoserverRunning, setIsGeoserverRunning] = useState(false)
    const [toggleValue, setToggleValue] = useState({})
    const [menu, setMenu] = useState([])
    const [lowCostSensorsData, setLowCostSensorsData] = useState({}); // All Reference Grade Stations from API

    return (
        <MapContext.Provider 
            value={{
                stationsData, setStationsData,
                weatherStationsData, setWeatherStationsData,
                mapRef, setMapRef,
                viewDownloadableFileData, setViewDownloadableFileData,
                chartRefs,
                isGeoserverRunning, setIsGeoserverRunning,
                toggleValue, setToggleValue,
                menu, setMenu,
                lowCostSensorsData, setLowCostSensorsData,
            }}
        >
            {props.children}
        </MapContext.Provider>
    );
}

export default MapContextProvider;