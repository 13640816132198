import React, { useContext } from "react"
import { MapContext } from "../../../Contexts/MapContext"
import "../../../Assets/Styles/PrintMapTitle.css"


const PrintMapTitle = (props) => {
    const position = props.position || "topleft"
    const { viewDownloadableFileData } = useContext(MapContext)
    return (
        <div leaflet-browser-print-pages="" className="legend-over-the-map-top">
            {/* VISIBLE ONLY WHEN PRINTING */}
            <div variant="h3" component="div" style={{fontWeight:700}}>{viewDownloadableFileData?.description}</div>
        </div> 
    )
}

export default (PrintMapTitle);
