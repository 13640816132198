import React, { createContext, useState } from 'react';

export const SatelliteImagesContext = createContext();

const SatelliteImagesContextProvider = (props) => {
    const [satelliteImagesData, setSatelliteImagesData] = useState();
    const [sliderValueSatelliteImages, setSliderValueSatelliteImages] = useState();

    return (
        <SatelliteImagesContext.Provider 
            value={{
                satelliteImagesData, setSatelliteImagesData,
                sliderValueSatelliteImages, setSliderValueSatelliteImages,
            }}
        >
            {props.children}
        </SatelliteImagesContext.Provider>
    );
}

export default SatelliteImagesContextProvider;