import { Typography, Row, Col, Image } from "antd";
import { Content } from "antd/lib/layout/layout";

const { Title } = Typography;

export const ContentOne = () => {

  return (
    <Content className="landing-first">
      <Row justify={"center"}>
        <Col lg={{span: 12}} sm={{span: 20}}>
          <Row align={"middle"}>
            <Col sm={{span: 8}} xs={{span:0}}>
              <Row>
                <Image
                  src={require("../../Assets/Images/logo-large.png")}
                  alt="airmove-logo"
                  preview={false}
                />
              </Row>
            </Col>
            <Col sm={{span: 16}} xs={{span:24}}>
              <Title level={1} className="landing-label-white" style={{fontWeight: "bolder"}}>
                AIRMOVE
              </Title>
              <Title level={4} className="landing-label-white">
                Ambient Air Remote Sensing, Modeling and Data Visualization Environment
              </Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </Content>
  )
};
