import React from 'react';
import { Typography } from "antd";
import { PhoneTwoTone, MailTwoTone, PushpinTwoTone } from '@ant-design/icons';

const { Text } = Typography;


export const CardItems = [
  {
    id: 1,
    title: "EMAIL",
    description: <Text className="pre-line-name">rvramos@up.edu.ph<br/><br/></Text>,
    icon: <MailTwoTone twoToneColor="#0072A4" style={{fontSize: 24}}/>,
  },
  {
    id: 2,
    title: "ADDRESS",
    description: <Text className="pre-line-name">University of the Philippines, Diliman, Quezon City</Text>,
    icon: <PushpinTwoTone twoToneColor="#0072A4" style={{fontSize: 24}}/>,
    coordinates: [14.656440682450325, 121.06967443147872],
  },
  {
    id: 3,
    title: "CALL US",
    description: <Text className="pre-line-name">(02) 8981 8500 (loc. 3123)<br/><br/></Text>,
    icon: <PhoneTwoTone twoToneColor="#0072A4" style={{fontSize: 24}}/>,
  }
]
