import React from "react";
import {
  TileLayer,
  LayersControl,
} from 'react-leaflet';

/**
 * 
 * defaults to defaultBasemapLayers, can be overridden by the 
 * see: src/Pages/Landing/MapComponent to know how to override basemaplayers
 */
const { basemapLayers: defaultBasemapLayers } = require("../Data/basemapLayers.json");


const BasemapLayer = ({layers}) => {
    let basemapLayers = layers || defaultBasemapLayers
    
    return (
        <div>
            {basemapLayers.map((layer) => {
                // console.log("layer.checked", layer.checked)
                return (
                    <LayersControl.BaseLayer name={layer.name} key={layer.name} checked={layer.checked}>
                        <TileLayer
                            attribution={layer.attribution}
                            url={layer.url}
                        />
                    </LayersControl.BaseLayer>
                )
            })}
        </div>
    )
};

export default BasemapLayer;
