import React, { 
  useState,
  memo,
  useEffect,
  useContext,
} from "react";
import {
  Space,
  Divider,
  Typography,
} from "antd";

// import contexts and components
import { SidebarSecondaryContext } from "../../../../Contexts/SidebarSecondaryContext";
import SidebarSecondaryBottom from "./SidebarSecondaryBottom";
import SidebarSecondaryFilters from "./SidebarSecondaryFilters";
import SidebarSecondaryTop from "./SidebarSecondaryTop";
import "../../../../Assets/Styles/SidebarSecondary.css";
import * as url from "../../../../Urls"
import { objectToQueryString } from "../../Functions";

const CARE_API_URL = url.CARE_API_URL
const { Text } = Typography

const SidebarSecondaryLowCostSensors = ({ layer }) => {
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceCount, setDataSourceCount] = useState([]);
  const [columns, setColumns] = useState([]);
  const [headers, setHeaders] = useState([]);
  const { lowCostParams } = useContext(SidebarSecondaryContext);
  // console.log("SidebarSecondaryLowCostSensors", layer)

  /**
   * 
   * @param {*} object - the data source from which the columns will be generated
   * @returns [
   * columns: for the displayed table,
   * headers: for the headers of the CSV to be downloaded
   * ]
   */
  function getColumnsFromObjectKeys(object) {    
    let listOfKeys = Object.keys(object)
    let headers = []
    let keysToRemove = [
      "station",
      "station_id",
      "station_name",
    ]

    let columns = []
    for (const key of listOfKeys) {
      if (keysToRemove.includes(key)) { continue }
      let col = {};
      col["title"] = key.toUpperCase().replace("_", " ");
      col["dataIndex"] = key.toLowerCase();
      col["key"] = key.toLowerCase();
      col["width"] = 80;
      columns.push(col)
      headers.push(key)
    }

    return [columns, headers]
  };

  useEffect(() => {
    /**
     * Fetches data from Airmove's API
     * 
     * What happens is that while in dev mode, the <React.StrictMode> tags located in our root index
     * stress tests our components hence rendering this useEffect TWICE
     * when in fact, what we actually want is to only run this effect ONCE
     * 
     * To do this, we set a new AbortController and cleanup this useEffect before unmounting
     * Source: https://javascript.plainenglish.io/why-should-you-use-cleanup-functions-in-reacts-useeffect-hook-bdff48bd9b3
     * Source: https://www.youtube.com/watch?v=MXSuOR2yRvQ
     */
    let controller = new AbortController();
    const signal = controller.signal;

    const loadData = async (API_URL) => {
      fetch(API_URL, {
        signal: signal,
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              // catches error for non-existent endpoints
              `This is an HTTP error: The status is ${res.status}`
            );
          }
          return res.json();
        })
        .then((body) => {
          if (body.count > 0) {
            // setDataSource(body)
            setColumns(getColumnsFromObjectKeys(body.results[0])[0]);
            setHeaders(getColumnsFromObjectKeys(body.results[0])[1]);
            // setDataSource(cleanDataSource(body));
            setDataSource(body);
          } else {
            setDataSource([]);
          }
        })
        .catch((e) => {
          setDataSource([]);
          console.log("error", e);
        })
        .finally(() => {
          // setLoading(false);
        });
    };
  
    if (layer) {
      const loadCareEndpoints = async (project_name, source) => {
        // console.log("lowCostParams", lowCostParams)
        var obj = {
          "source": source,
          "local_time_start": lowCostParams.period_start,
          "local_time_end": lowCostParams.period_end,
          "order_by": "local_time",
          "sort_order": "asc",
        }
        var queryString = objectToQueryString(obj)
        // console.log("qs", queryString)

        const CARE_URL = CARE_API_URL + "sensorinsights/" + project_name + `/data?${queryString}`

        // console.log("CARE_URL", CARE_URL)
    
        fetch(CARE_URL)
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              // catches error for non-existent endpoints
              `This is an HTTP error: The status is ${res.status}`
            );
          }
          return res.json();
        })
        .then((body) => {
          // console.log("CARE_URL LCS ss", body)
          // setCardInfo(body)
          if (body.dataCount > 0) {
            // setDataSource(body)
            setColumns(getColumnsFromObjectKeys(body.data[0])[0]);
            setHeaders(getColumnsFromObjectKeys(body.data[0])[1]);
            // setDataSource(cleanDataSource(body));
            setDataSource(body);
            // console.log("columns", getColumnsFromObjectKeys(body.data[0]))
          } else {
            setDataSource([]);
          }
          
        })
        .catch((e) => {
          console.log("error", e)
        })
        .finally(() => {
          // setLoading(false);
        });
      };
      const project_name = layer.mapping[0]["database_name"]
      const source = layer.mapping[0]["source"]
      loadCareEndpoints(project_name, source)
    };
    return () => {
      controller?.abort();
    }
    
  }, [layer, lowCostParams]);

  // useEffect(() => {
  //   console.log("ds", dataSource)
  //   console.log("ds2", layer)
  // }, [])
  

  return (
    <Space 
      direction="vertical" 
      className="sidebar-secondary-container"
    >
      <SidebarSecondaryTop layer={layer} title={"Small Sensors"}/>
      <Divider style={{margin: 0}}/>
      
      <SidebarSecondaryFilters/>
      
      <Text italic>Showing {Object.keys(dataSource).length > 0 && dataSource.dataCount}</Text>
      <SidebarSecondaryBottom
        dataSource={dataSource}
        columns={columns}
        headers={headers}
        layer={layer}
      />
    </Space>
  );
};

export default memo(SidebarSecondaryLowCostSensors);
