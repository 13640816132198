import {
    PlusOutlined,
    MinusCircleFilled,
    CheckCircleFilled,
} from "@ant-design/icons";
import {
    Space,
    Typography,
    Image,
} from "antd";

import * as url from "../Urls"

const { Title, Paragraph } = Typography;
const API_URL = url.REACT_APP_API_URL

// const checkIfActive = (filterCategory) => {
//   let isActive = false;
//   for (let index = 0; index < filterCategory.length; index++) {
//     const state = filterCategory[index]["context"][0];
//     if (Object.values(state).length == 0) {
//       isActive = true
//     } else {
//       isActive = Object.values(state).some(item => item === true)
//     }
//     // checks if at least one category is checked
//     if (isActive) {
//       break
//     }
//   }
//   return isActive;
// };

function checkValues(obj, filterCategory) {
  if (filterCategory) {
    for (let index = 0; index < filterCategory.length; index++) {
      let parent = filterCategory[index]["parent"].replace(/\s+/g, "")
      for (const key in obj) {
        if (key.startsWith(parent)) {
          if (!obj[key]) {
            return false;
          }
        }
      }
      return true;
    }
  }
};
  
export const getPanelHeader = (header, ...props) => {
  const submenuItem = props[0]
  const filterCategory = props[1]
  const state = props[2]

  return (
    <Space className="panel-header-container">
      <div className="image-container">
        <Image
          // src={require(`../Assets/Images/v2-icons/${header.logo}.png`)}
          src={API_URL+header.logo_image}
          alt={`${header.logo_image}`}
          preview={false}
          className="list-logo"
        />
      </div>
      <div>
        <Title className="label">
          {header.label}
          {/*(
            // (header.name === submenuItem && checkIfActive(filterCategory))
            (header.name === submenuItem && checkValues(state, filterCategory))
          )
            && <CheckCircleFilled className="checked-icon" />
          */}
        </Title>
        <Paragraph className="description">{header.description}</Paragraph>
      </div>
    </Space>
  )
};
  
/*
*** use for determining which type of icon should be shown when collapse is clicked.
*** key.isActive is included in the properties of collapse.
*/
export const dropDownIconHandler = (key) =>
    key.isActive ? <MinusCircleFilled className="collapse-icon"/> : <PlusOutlined className="collapse-icon"/>;
