import React, { 
  useState,
  memo,
  useEffect,
  useContext,
} from "react";
import {
  Space,
  Divider,
} from "antd";

// import contexts and components
import { SidebarSecondaryContext } from "../../../../Contexts/SidebarSecondaryContext";
import SidebarSecondaryBottom from "./SidebarSecondaryBottom";
import SidebarSecondaryFilters from "./SidebarSecondaryFilters";
import SidebarSecondaryTop from "./SidebarSecondaryTop";
import "../../../../Assets/Styles/SidebarSecondary.css";

import { roundTo } from "../../Functions";


const SidebarSecondary = ({ layer }) => {
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [headers, setHeaders] = useState([]);
  const { params } = useContext(SidebarSecondaryContext);

  /**
   * 
   * @param {*} object - the data source from which the columns will be generated
   * @returns [
   * columns: for the displayed table,
   * headers: for the headers of the CSV to be downloaded
   * ]
   */
  function getColumnsFromObjectKeys(object) {    
    let listOfKeys = Object.keys(object)
    let headers = []
    let keysToRemove = [
      "station",
      "period_start",
      "period_end",
      "computed_time",
    ]

    let columns = []
    for (const key of listOfKeys) {
      if (keysToRemove.includes(key)) { continue }
      let col = {};
      col["title"] = key.toUpperCase().replace("_", " ");
      col["dataIndex"] = key.toLowerCase();
      col["key"] = key.toLowerCase();
      col["width"] = 80;
      columns.push(col)
      if (col["title"].includes("25")) {
        col["title"] = col["title"].replace("25","2.5")
      }
      if (col["title"].includes("PM")) {
        col["title"] = col["title"].replace("PM","PM ")
      }
      headers.push(key)
    }

    return [columns, headers]
  };

  /**
   * 
   * @param {*} dataSource 
   * @returns updated dataSource
   */
  function cleanDataSource(dataSource) {
    /**
     * 
     * @param {*} number - the input number
     * @param {*} len - the no. of decimal places
     * @returns the rounded off number to specified decimal places
     */

    // function roundTo(number, decimalPlaces) {
    //   let e_plus = "e+" + decimalPlaces
    //   let e_minus = "e-" + decimalPlaces
    //   return +(Math.round(number + e_plus) + e_minus);
    // }
    
    let dataSourceList = dataSource.results
    dataSourceList.forEach((item, index) => {
      for (const key in item) {
        if (Number(item[key])) {
          item[key] = roundTo(item[key], 3)
          // item["id"] = index + 1
        }
      }
    })

    return dataSource
  }

  useEffect(() => {
    /**
     * Fetches data from Airmove's API
     * 
     * What happens is that while in dev mode, the <React.StrictMode> tags located in our root index
     * stress tests our components hence rendering this useEffect TWICE
     * when in fact, what we actually want is to only run this effect ONCE
     * 
     * To do this, we set a new AbortController and cleanup this useEffect before unmounting
     * Source: https://javascript.plainenglish.io/why-should-you-use-cleanup-functions-in-reacts-useeffect-hook-bdff48bd9b3
     * Source: https://www.youtube.com/watch?v=MXSuOR2yRvQ
     */
    let controller = new AbortController();
    const signal = controller.signal;

    const loadData = async (API_URL) => {
      fetch(API_URL, {
        signal: signal,
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              // catches error for non-existent endpoints
              `This is an HTTP error: The status is ${res.status}`
            );
          }
          return res.json();
        })
        .then((body) => {
          if (body.count > 0) {
            // setDataSource(body)
            setColumns(getColumnsFromObjectKeys(body.results[0])[0]);
            setHeaders(getColumnsFromObjectKeys(body.results[0])[1]);
            setDataSource(cleanDataSource(body));
          } else {
            setDataSource([]);
          }
        })
        .catch((e) => {
          setDataSource([]);
          console.log("error", e);
        })
        .finally(() => {
          // setLoading(false);
        });
    };
  
    if (layer) {
      // console.log("queryParams: ", params)
      var queryString = Object.keys(params).map(key => {
        if (key !== "granularity" && params[key]) {
          return key + '=' + params[key]
        }
      })
      .filter(val => val).join('&');
      // console.log("queryString: ", queryString)
      var CONST_API_URL = layer["aq_station_"+params.granularity] + "&" + queryString
      console.log("CONST_API_URL", CONST_API_URL)
      loadData(CONST_API_URL);
    };
    return () => {
      controller?.abort();
    }
    
  }, [layer, params]);

  return (
    <Space 
      direction="vertical" 
      className="sidebar-secondary-container"
    >
      <SidebarSecondaryTop layer={layer} title={"Reference Grade Stations"}/>
      <Divider style={{margin: 0}}/>

      <SidebarSecondaryFilters/>
      
      <SidebarSecondaryBottom
        dataSource={dataSource}
        columns={columns}
        headers={headers}
        layer={layer}
      />
    </Space>
  );
};

export default memo(SidebarSecondary);
