import { Typography, Row, Col } from "antd";
import { Content } from "antd/lib/layout/layout";

const { Title } = Typography;


export const ContentOne = () => {
  
    return (
      <Content className="landing-first">
        <Row justify={"center"}>
          <Col lg={{span: 12}} sm={{span: 20}}>
            <Title level={1} className="landing-label-white" style={{fontWeight: "bolder"}}>
              Data Hub
            </Title>
            <Title level={4} className="landing-label-white">
              This page contains toolkits, publication materials, and technical documentations developed by Project AiRMoVE
            </Title>
          </Col>
        </Row>
      </Content>
    )
};
  