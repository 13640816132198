import React, { 
  memo,
  useContext,
} from "react";
import {
  Space,
  Typography,
  Tooltip,
} from "antd";
import {
  ZoomInOutlined,
  CloseCircleFilled
} from "@ant-design/icons";

// import contexts and component
import { MapContext } from "../../../../Contexts/MapContext";
import { transformLocation } from "../../Functions";

const { Text } = Typography;


const SidebarSecondaryTop = ({ layer, title }) => {
  const { 
    stationsData,
    weatherStationsData,
    setViewDownloadableFileData,
    mapRef
  } = useContext(MapContext);

  function zoomToSelectedPoint(e, item) {
    // console.log("item", item)
    let item_id = item.id
    let zoom = 13
    let data
    if (item.hasOwnProperty("aq_station_daily")) {
      data = stationsData.results.find(m => m.id === item_id)
    } else if (item.hasOwnProperty("met_station_daily")) {
      data = weatherStationsData.results.find(m => m.id === item_id)
    }
    let center = transformLocation(data.location)
    mapRef.invalidateSize()
    mapRef.flyTo(center, zoom)
  }

  return (
    <Space style={{display: "flex", justifyContent: "space-between"}}>
      <Space style={{display: "flex", justifyContent: "space-between"}}>
        <Text className="secondary-sidebar-title">
          Data from <span style={{textDecoration: "underline"}}>{layer?.description}</span>
        </Text>
        <Tooltip placement="top" title="Zoom to layer">
          <Typography>
            <ZoomInOutlined 
              onClick={(e) => zoomToSelectedPoint(e, layer)}
            />
          </Typography>
        </Tooltip>
      </Space>
      <Tooltip placement="top" title="Close">
        <CloseCircleFilled
          style={{color: "gray"}}
          onClick={() => {
            setViewDownloadableFileData()
            setTimeout(function(){ mapRef.invalidateSize()}, 400);
          }}
        />
      </Tooltip>
    </Space>
  );
};

export default memo(SidebarSecondaryTop);
