import React, { memo, useState, useEffect, useContext } from "react";
import { Layout, Button, Drawer } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { Typography, Image, Space } from "antd";
import { getWindowDimensions } from "./Functions";
import { MenuOutlined } from '@ant-design/icons';
import { sm_width } from "../../Components/Layout/Constants";
import { WindowContext } from "../../Contexts/WindowContext";
import "../../Assets/Styles/NavComponent.css";

const { Title } = Typography;
const { Header } = Layout;
const windowWidthLimit = sm_width;
const headerTitle = {
  complete: "AiRMoVE Dashboard",
  shortened: "AiRMoVE"
};
const navbarItems = [
  // {
  //   label: "Overview",
  //   path: "/",
  // },
  // {
  //   label: "Cities/Municipalities",
  //   path: "/location",
  // },
  {
    label: "About Us",
    path: "/aboutus",
  },
  {
    label: "Data Hub",
    path: "/datahub",
  },
  {
    label: "Map",
    path: "/map",
  },
];


function NavComponent() {
  const [drawer, setDrawer] = useState(false);
  const { windowDimensions, setWindowDimensions } = useContext(WindowContext);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setWindowDimensions]);

  useEffect(() => {
    if (windowDimensions.width < windowWidthLimit) {
        setDrawer(true)
    } else {
        setDrawer(false)
    }
  }, [windowDimensions])

  if (useLocation().pathname != "/map") {
    return (
      <Layout>
        {drawer ? <NavBarDrawer windowDimensions={windowDimensions}/>: <NavBar windowDimensions={windowDimensions}/>}
      </Layout>
    )
  };
}
export default memo(NavComponent);


const HeaderTitleAndLogo = ({windowDimensions}) => {
  return (
    <Space className="title-and-logo">
      {/* <Image
        src={require("../../Assets/Images/logo.png")}
        alt="airmove-logo"
        preview={false}
      /> */}
      <Title
        level={4}
        className="logo-name"
        onClick={() => window.location = "/"}
        style={{cursor: "pointer"}}
      > 
        {windowDimensions.width < windowWidthLimit? headerTitle.shortened: headerTitle.complete}
      </Title>
    </Space>
  )
};

const NavBar = ({windowDimensions}) => {
  // sets the style for an active link router. 
  const activeLinkHandler = (isActive) => "navlink " + (isActive? "navlink-active": "") 
  return (
    <Header className="navbar">
      <HeaderTitleAndLogo windowDimensions={windowDimensions}/>
      <Space direction="horizontal" size={22}>
        {navbarItems.map((item) => {
          return (
            <NavLink
              key={item.path}
              to={item.path}
              className={(link) => activeLinkHandler(link.isActive)}
            >
              {item.label}
            </NavLink>
          )
        })}
      </Space>
    </Header>
  ) 
};

const NavBarDrawer = ({windowDimensions}) => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const activeLinkHandler = (isActive) => "navlink-drawer" + (isActive? "navlink-active-drawer": "") 

  return (
    <>
      <Header className="_navbar_drawer_">
        <HeaderTitleAndLogo windowDimensions={windowDimensions}/>
        <Space>
          <MenuOutlined onClick={showDrawer} className="rotate-el-ccw menu-button"/>
        </Space>
      </Header>
     
      <Drawer
        title="Airmove Dashboard"
        placement="right"
        width={windowDimensions.width}
        onClose={onClose}
        open={open}
      >
        {navbarItems.map((item) => {
          return (
            <NavLink to={item.path} key={item.path}>
              <p>
                <Button block className={(link) => activeLinkHandler(link.isActive)} onClick={onClose}>
                  {item.label}
                </Button>
              </p>
            </NavLink>
          )
        })}
      </Drawer>
    </>
  );
};
