import React, { memo, useContext, useEffect } from "react";
import _ from 'lodash';
import {
  Space,
  Checkbox,
  Collapse,
  Slider,
} from "antd";
import * as url from "../../../Urls"
import { GeographicalParametersContext } from "../../../Contexts/GeographicalParametersContext";
import { dropDownIconHandler } from "../../../Components/Sidebar";
import "../../../Assets/Styles/CheckboxFilter.css";

const GEOSERVER_URL = url.GEOSERVER_URL
const { Panel } = Collapse;

// created a copy just in case we want to completely change the way it looks in other sections
const GeographicalParameters = ({ filters }) => {
  const parent = JSON.parse(JSON.stringify(filters.name)).replace(/\s+/g, "_")
  // console.log("GeographicalParameters parent", parent)
  const { 
    geographicalParametersData, setGeographicalParametersData,
    sliderValueGeographicalParameters, setSliderValueGeographicalParameters,
  } = useContext(GeographicalParametersContext)

  useEffect(() => {
    let controller = new AbortController();
    const signal = controller.signal;
    const username = "admin";
    const password = "airmovegeoserver";

    const loadDataFromWorkspace = (workspaceName) => {
      const WORKSPACE_URL = GEOSERVER_URL + "/rest/workspaces/" + workspaceName + "/layers.json"
      // console.log("fetching from WORKSPACE_URL", WORKSPACE_URL)
      // console.log("fetching from workspaceName", workspaceName)
      // console.log("checking geographicalParametersData from workspaceName", geographicalParametersData)
      fetch(WORKSPACE_URL, {
        signal: signal,
        headers: new Headers({
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        })
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              // catches error for non-existent endpoints
              `This is an HTTP error: The status is ${res.status}`
            );
          }
          return res.json();
        })
        .then((body) => {
          setGeographicalParametersData(geographicalParametersData => ({
            ...geographicalParametersData,
            [workspaceName]: body
          }))
        })
        .catch((e) => {
          console.log("error", e)
        })
        .finally(() => {
          // setLoading(false);
        });
    };

    loadDataFromWorkspace(parent);
    return () => {
      controller?.abort();
    }
  }, [parent]);

  // Function to set all values of a specific key in all objects
  const onParentCheckboxChange = (e) => {
    setGeographicalParametersData(geographicalParametersData => ({
      ...geographicalParametersData,
      [parent]: {
        ...geographicalParametersData[parent],
        "layers": {
          ...geographicalParametersData[parent]["layers"],
          "layer" : 
            // this is a list
            geographicalParametersData[parent]["layers"]["layer"].map(item => {
              return {
                ...item,
                "checked": e.target.checked
              }
            })
        }
      },
    }));
  }

  const onChildCheckboxChange = (e, child) => {
    setGeographicalParametersData(geographicalParametersData => ({
      ...geographicalParametersData,
      [parent]: {
        ...geographicalParametersData[parent],
        "layers": {
          ...geographicalParametersData[parent]["layers"],
          "layer" : 
            // this is a list
            geographicalParametersData[parent]["layers"]["layer"].map(item => {
              if (item.name == child) {
                return {
                  ...item,
                  "checked": e.target.checked
                }
              }
              return item
            })
        }
      },
    }));
  };

  // async function zoomToLayer(e, parent, item) {
  //   const bboxAndSrs = await GeoServerService.getBoundingBox(item)
  //   const bbox = [
  //     [bboxAndSrs["minY"], bboxAndSrs["minX"]],
  //     [bboxAndSrs["maxY"], bboxAndSrs["maxX"]]
  //   ]
  //   // console.log("click zoomtoLayer bboxAndSrs", bboxAndSrs)
  //   // console.log("click zoomtoLayer bbox", bbox)
  //   mapRef.invalidateSize()
  //   mapRef.fitBounds(bbox)
  // }

  const ChildCheckbox = ({data}) => {
    let item = data?.name
    let isChecked = geographicalParametersData[parent]["layers"]["layer"].find(item2 => item2.name === item )?.checked

    // function getMonthAbbreviation(monthNumber) {
    //   // Ensure monthNumber is within the valid range (1 to 12)
    //   if (monthNumber < 1 || monthNumber > 12) {
    //     return "Invalid month number";
    //   }
    //   // Create a Date object with the first day of the specified month
    //   let date = new Date(2000, monthNumber - 1, 1);
    //   // Use toLocaleString with 'en-us' locale to get the month abbreviation
    //   let monthAbbreviation = date.toLocaleString('en-us', { month: 'short' });
    //   return monthAbbreviation
    // }
    
    // const reformatName = (name) => {
    //   const [satellite, date, month, year] = name.split("_")
    //   const monthAbbreviation = getMonthAbbreviation(month)
    //   const updatedName = satellite + "_" + date + monthAbbreviation + year
    //   return updatedName
    // }

    return (
      <Checkbox
        checked={isChecked}
        value={item}
        onChange={(e)=>onChildCheckboxChange(e, item)}
        style={{ paddingLeft: "25px" }}
        className="custom-checkbox"
      >
        {item}
      </Checkbox>
    )
  }

  // Function to check if all values of a specific key are true
  const checkValues = (listToCheck, key) => {
    return listToCheck.every(item => item[key] === true);
  };

  const checkAnyValue = (listToCheck, key) => {
    return listToCheck.some(item => item[key] === true);
  };

  // const downloadTiff = async (workspace, name) => {
  //   try {
  //     GeoServerService.downloadTiff(workspace, name)
  //     .then(GET_COVERAGE_URL => {
  //       try {
  //         fetch(GET_COVERAGE_URL)
  //         .then((res) => {
  //           if (res.ok) {
  //             const a = document.createElement('a');
  //             a.href = GET_COVERAGE_URL;
  //             a.target = '_new'
  //             document.body.appendChild(a);
  //             a.click();
  //             document.body.removeChild(a);
  //             URL.revokeObjectURL(GET_COVERAGE_URL);
  //           } else {
  //             console.error('Error fetching TIFF:', res.statusText);
  //           }
  //         })
          
  //       } catch (error) {
  //         console.error('Error fetching TIFF:', error.message);
  //       }
  //     })

  //   } catch (error) {
  //     console.error('Error fetching TIFF:', error.message);
  //   }
  // };

  return (
    <Space direction="vertical" style={{width: "100%"}}>
      <Collapse
        expandIcon={(key) => dropDownIconHandler(key)}
        expandIconPosition="end"
        ghost
        collapsible="icon"
      >
        <Panel
          header={
            <Checkbox
              checked={geographicalParametersData && geographicalParametersData[parent] && checkValues(geographicalParametersData[parent]["layers"]["layer"], "checked")}
              indeterminate={
                geographicalParametersData && geographicalParametersData[parent] &&
                !checkValues(geographicalParametersData[parent]["layers"]["layer"], "checked") &&
                checkAnyValue(geographicalParametersData[parent]["layers"]["layer"], "checked")
              }
              onChange={(e)=>onParentCheckboxChange(e, filters.parent)}
              className="custom-checkbox"
            >
              {parent}
            </Checkbox>
          }
          key={"header-checkbox"}
        >
          <div className="collapsible-panel">
            {geographicalParametersData && geographicalParametersData[parent] && geographicalParametersData[parent].layers.layer.map((item) => {
              return (
                <div key={item.name}>
                  <ChildCheckbox data={item} item={item}/>
                  <div className="custom-checkbox-with-zoom-and-download">
                    <Slider
                      defaultValue={100}
                      style={{width: "40%"}}
                      onChange={(value) => setSliderValueGeographicalParameters({...sliderValueGeographicalParameters, [item.name]: value})}
                      disabled={!item.checked}
                      trackStyle={{backgroundColor: "#263F6B"}}
                      railStyle={{backgroundColor: "#fff"}}
                      className="custom-slider"
                    />
                    {/* <Space>
                      <Tooltip placement="top" title={`Zoom to ${item.name}`}>
                        <Typography>
                          <ZoomInOutlined 
                            onClick={(e) => zoomToLayer(e, parent, item.name)}
                          />
                        </Typography>
                      </Tooltip>
                      <Tooltip placement="top" title={`Download ${item.name}.tiff`}>
                        <Typography>
                          <DownloadOutlined
                            onClick={() => downloadTiff(parent, item.name)}
                          />
                        </Typography>
                      </Tooltip>
                    </Space> */}
                  </div>
                  {/* <br/> */}

                </div>
              )
            })}
          </div>
        </Panel>
      </Collapse>
    </Space>
  );
};

export default memo(GeographicalParameters);
