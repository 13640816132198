import React, { 
  memo,
  useContext,
} from "react";
import {
  Row,
  Col,
  DatePicker,
  Select,
  Space,
  Typography,
} from "antd";
import moment from "moment";

// import contexts
import { SidebarSecondaryContext } from "../../../../Contexts/SidebarSecondaryContext";

const { Paragraph, Text } = Typography


const SidebarSecondaryFilters = () => {
  const { params, setParams, dateFormat } = useContext(SidebarSecondaryContext)
  const granularity_options = [
    {
      value: 'daily',
      label: `Daily`,
    },
    {
      value: 'monthly',
      label: `Monthly`,
    },
    {
      value: 'yearly',
      label: `Yearly`,
    },
  ];

  const pollutant_options = [
    {
      value: 'pm25',
      label: `PM 25`,
    },
    {
      value: 'pm10',
      label: `PM 10`,
    },
  ];

  /**
   * 
   * @param {*} value - the value of the property or filter
   * @param {*} prop - the property name
   */
  const handleChange = (value, prop) => {
    setParams({
      ...params,
      [prop]: value,
    })
  };

  return (
    <>
      <Space style={{display: "flex", justifyContent: "space-between"}}>
        <Text className="item-list-header">
          Downloadable Files
        </Text>
      </Space>
      <Space style={{display: "flex", justifyContent: "space-between"}}>
        <Paragraph className="description">
          Listed below are the files that are available to download.
        </Paragraph>
      </Space>
      <br/>
      <Row className="filter-row">
        <Col span={12}>Start Date:</Col>
        <Col span={12}>
          <DatePicker
            size="small"
            style={{width: "100%"}}
            value={params.period_start ? moment(params.period_start, dateFormat) : null}
            format={dateFormat}
            // onChange={e => setDate({...date, start: e ? moment(e).format(dateFormat) : null})}
            onChange={e => handleChange(e ? moment(e).format(dateFormat) : null, "period_start")}
            disabledDate={(current) => {
              if (params.period_end) {
                return current > moment(params.period_end).endOf('day')
              }
              return false
            }}
            showTime={{ format: 'HH:mm' }}
          />
        </Col>
      </Row>
      <Row className="filter-row">
        <Col span={12}>End Date:</Col>
        <Col span={12}>
          <DatePicker
            size="small"
            style={{width: "100%"}}
            value={params.period_end ? moment(params.period_end, dateFormat) : null}
            format={dateFormat}
            // onChange={e => setDate({...date, end: e ? moment(e).format(dateFormat) : null})}
            onChange={e => handleChange(e ? moment(e).format(dateFormat) : null, "period_end")}
            disabledDate={(current) => {
              if (params.period_start) {
                return current < moment(params.period_start).startOf('day')
              }
              return false
            }}
            showTime={{ format: 'HH:mm' }}
          />
        </Col>
      </Row>
      <Row className="filter-row">
        <Col span={12}>Granularity:</Col>
        <Col span={12}>
          <Select
            size="small"
            style={{width: "100%"}}
            defaultValue="daily"
            onChange={(value) => handleChange(value, "granularity")}
            options={granularity_options}
          />
        </Col>
      </Row>
      <Row className="filter-row">
        <Col span={12}>Pollutant:</Col>
        <Col span={12}>
          <Select
            size="small"
            style={{width: "100%"}}
            defaultValue=""
            onChange={(value) => handleChange(value, "show_pollutant")}
            options={pollutant_options}
            allowClear
          />
        </Col>
      </Row>
    </>
  );
};

export default memo(SidebarSecondaryFilters);
