import { Typography, Carousel, Row, Image, Card, Tag, Col } from "antd";
import { Content } from "antd/lib/layout/layout";

const { Title } = Typography;
const { pubmats } = require("../../Data/pubmats.json");


export const CarouselItem = ({item}) => {

  return (
    <div>
      <Row justify={"center"} align={"middle"} style={{padding: "2%"}}>
        <Card
          hoverable
          cover={
            <Image
              src={require(`../../Assets/Images/datahub/pubmats/${item.logo}.jpg`)}
              alt="airmove-logo"
              preview={false}
            />
          }
        >
          {item.tags.map((tag)=><Tag>{tag}</Tag>)}
        </Card>
      </Row>
    </div>
  )
};


export const Partnerships = () => {
  
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  return (
    <Content className="landing-third">
      <Title level={3} className="landing-label-white" style={{fontWeight: "bolder"}}>
        Partnerships and Collaborations
      </Title>
      {/* <Title level={5} className="landing-label-white" style={{fontWeight: "bolder"}}>
        Insert description for SDGs
      </Title> */}
      <Row justify={"center"}>
        <Col xl={{span: 8}} sm={{span: 20}}>
          <Carousel afterChange={onChange} style={{paddingTop: "2%", paddingBottom: "5%"}}>
            {pubmats.map((item) => {
              return (
                <CarouselItem item={item}/>
              )
            })}
          </Carousel>
        </Col>
      </Row>
    </Content>
  )
};
