/**
 * Returns an array of coordinates in the form: [Lat, Lng]
 * transformLocation takes a location argument in the form: SRID=4326;POINT (121.0080555555556 14.52833333333333).
 * @param {string} location - SRID=4326;POINT (121.0080555555556 14.52833333333333)
 */
export const transformLocation = function (location) {
    let coords = location.split(";")[1].replace(/[^\d.-]+/g, ' ').trim().split(" ")
    let transformedLocation = []
    // array of number coordinates
    transformedLocation[0] = coords.map(Number)[1]
    transformedLocation[1] = coords.map(Number)[0]
    return transformedLocation
};


export const download = function (data, filename) {
    // source: https://www.geeksforgeeks.org/how-to-create-and-download-csv-file-in-javascript/
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], { type: 'text/csv' });
 
    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)
 
    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')
 
    // Passing the blob downloading url
    a.setAttribute('href', url)
 
    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', filename);
 
    // Performing a download with click
    a.click()
};


export const objectToCsv = function (data, headers) {
    // source: https://www.geeksforgeeks.org/write-a-program-to-convert-an-array-of-objects-to-a-csv-string-that-contains-only-the-columns-specified-using-javascript/
    const csvRows = [];
 
    /* Get headers as every csv data format
    has header (head means column name)
    so objects key is nothing but column name
    for csv data using Object.key() function.
    We fetch key of object as column name for
    csv */
    // const headers = Object.keys(data[0]);
 
    /* Using push() method we push fetched
       data into csvRows[] array */
    csvRows.push(headers.join(','));
 
    // Loop to get value of each objects key
    for (const row of data) {
        const values = headers.map(header => {
            const val = row[header]
            return `"${val}"`;
        });
 
        // To add, separator between each value
        csvRows.push(values.join(','));
    }
 
    /* To add new line for each objects values
       and this return statement array csvRows
       to this function.*/
    return csvRows.join('\n');
};


export const getCsv = async function (data, headers, filename="table") {
    const csvdata = objectToCsv(data, headers)
    download(csvdata, filename);
};


export const downloadImage = function (chartRefs, filename="chart") {
    chartRefs.current.forEach((chart, index) => {
        if (chart.canvas) {
            var canvas_title = chart.config._config.data.datasets[0]['label']
            var canvas_type = chart.config._config.type
            canvas_type = canvas_type.charAt(0).toUpperCase() + canvas_type.slice(1)
            // console.log("chart.filename", filename)
            // console.log("chart.config", chart.config)

            const link = document.createElement("a");
            link.download = `${canvas_title} (${canvas_type}).png`;
            link.href = chart.toBase64Image();
            link.click();
        }
    });
};

// Truncate value based on number of decimals
export const roundTo = function (number, len) {
    return Math.round(number*(Math.pow(10, len)))/(Math.pow(10, len));
};

export function objectToQueryString(obj) {
    const queryString = Object.keys(obj)
      .map(key => {
        if (obj[key] !== null && obj[key] !== undefined) {
          return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
        }
        return '';
      })
      .filter(Boolean)
      .join('&');
    return queryString;
}