import L from 'leaflet';
import $ from 'jquery';
import "../../../Assets/Styles/StationMarkerLegend.css";
import * as url from "../../../Urls"

const AQI_LEGEND = url.REACT_APP_AQI_LEGEND_URL;
const GEOSERVER_URL = url.GEOSERVER_URL

var legendValues = []

// const mainLegendContainer = L.DomUtil.create("div", "main-legend-div");

// adding legendRGS on map
export var legendRGS = L.control({ position: "bottomright" });
legendRGS.onAdd = () => {
  const legendContainer = L.DomUtil.create("div", "legend-div");
  const symbolsContainer = L.DomUtil.create("div", "symbols-div");
  const symbolsContainer2 = L.DomUtil.create("div", "symbols-div-second");

  const loadAQILegend = () => {
    fetch(AQI_LEGEND)
    .then((res) => {
      if (!res.ok) {
        throw new Error(
          // catches error for non-existent endpoints
          `This is an HTTP error: The status is ${res.status}`
        );
      }
      return res.json();
    })
    .then((body) => {
      legendValues = body.results
      // Use the forEach method to add the new key-value pair to each object
      if (legendValues) {
        legendValues.forEach(obj => {
          obj["key"] = obj["label"];
        });
      }
      // console.log("legendValues", legendValues)
      legendValues.forEach((value, index) => {
        let last_index = legendValues.length - 1
        // console.log("keme", index, value)
        $(symbolsContainer).append("<i class='tooltip' style='background:" + value.fill_color + "'><span class='tooltiptext'>" + value.label + "</span></i>")
        if (legendValues.length % 2 === 0) {
          if ([0,last_index].includes(index)) {
            $(symbolsContainer2).append("<h6>" + value.label + "</h6>")
          }
        } else {
          let mid_index = last_index / 2
          if ([0,mid_index,last_index].includes(index)) {
            $(symbolsContainer2).append("<h6>" + value.label + "</h6>")
          }
        }
      })
    })
    .catch((e) => {
      console.log("error", e)
    })
    .finally(() => {
      // setLoading(false);
    });
  };

  loadAQILegend()

  $(legendContainer).append("<strong><span style='font-size:12px'>Reference Grade Stations</span></strong>")
  $(legendContainer).append(symbolsContainer2); 
  $(legendContainer).append(symbolsContainer); 

  return legendContainer;
};


// adding legendRGS on print map
export var legendRGS_2 = L.control({ position: "bottomright" });
legendRGS_2.onAdd = () => {
  const legendContainer = L.DomUtil.create("div", "legend-div");
  const symbolsContainer = L.DomUtil.create("div", "symbols-div");
  const symbolsContainer2 = L.DomUtil.create("div", "symbols-div-second");

  // legendValues.forEach((value, index) => {
  //   $(symbolsContainer).append("<i class='tooltip' style='background:" + value.FillColor + "'><span class='tooltiptext'>" + value.Label + "</span></i>")
  //   if ([0,3,6].includes(index)) {
  //     $(symbolsContainer2).append("<h6>" + value.Label + "</h6>")
  //   }
  // })

  const loadAQILegend = () => {
    fetch(AQI_LEGEND)
    .then((res) => {
      if (!res.ok) {
        throw new Error(
          // catches error for non-existent endpoints
          `This is an HTTP error: The status is ${res.status}`
        );
      }
      return res.json();
    })
    .then((body) => {
      legendValues = body.results
      // Use the forEach method to add the new key-value pair to each object
      if (legendValues) {
        legendValues.forEach(obj => {
          obj["key"] = obj["label"];
        });
      }
      legendValues.forEach((value, index) => {
        let last_index = legendValues.length - 1
        $(symbolsContainer).append("<i class='tooltip' style='background:" + value.fill_color + "'><span class='tooltiptext'>" + value.label + "</span></i>")
        if (legendValues.length % 2 === 0) {
          if ([0,last_index].includes(index)) {
            $(symbolsContainer2).append("<h6>" + value.label + "</h6>")
          }
        } else {
          let mid_index = last_index / 2
          if ([0,mid_index,last_index].includes(index)) {
            $(symbolsContainer2).append("<h6>" + value.label + "</h6>")
          }
        }
      })
    })
    .catch((e) => {
      console.log("error", e)
    })
    .finally(() => {
      // setLoading(false);
    });
  };

  loadAQILegend()

  $(legendContainer).append("<strong><span style='font-size:12px'>Reference Grade Stations</span></strong>")
  $(legendContainer).append(symbolsContainer2); 
  $(legendContainer).append(symbolsContainer); 

  return legendContainer;
};

var params = {
  "REQUEST": "GetLegendGraphic",
  "VERSION": "1.0.0",
  "FORMAT": "image/png",
  "WIDTH": "30",
  "HEIGHT": "12",
  "LEGEND_OPTIONS": "forceLabels:on"
}

// Convert the object to a URL-encoded query string
const generalQueryString = new URLSearchParams(params).toString();

var layerName = "&LAYER=Land_Use_And_Land_Cover:land_cover_ncr_2020"

// adding legendRGS on map
export var legendLandCoverNcr = L.control({ position: "bottomright" });
legendLandCoverNcr.onAdd = () => {
  const legendContainer = L.DomUtil.create("div", "img-div");
  const imgContainer = L.DomUtil.create("img");

  $(legendContainer).append("<strong><span style='font-size:12px'>Land Use and Land Cover</span></strong>")
  imgContainer.src = GEOSERVER_URL + "/wms?" + generalQueryString + layerName
  console.log("LEGEND KINEME", GEOSERVER_URL + "/wms?" + generalQueryString + layerName)
  $(legendContainer).append(imgContainer)

  return legendContainer;
};

// adding legendRGS on print map
export var legendLandCoverNcr_2 = L.control({ position: "bottomright" });
legendLandCoverNcr_2.onAdd = () => {
  const legendContainer = L.DomUtil.create("div", "img-div");
  const imgContainer = L.DomUtil.create("img");

  $(legendContainer).append("<strong><span style='font-size:12px'>Land Use and Land Cover</span></strong>")
  imgContainer.src = GEOSERVER_URL + "/wms?" + generalQueryString + layerName
  $(legendContainer).append(imgContainer)

  return legendContainer;
};


var layerNameRoadNetwork = "&LAYER=Road_Network:road_network_2020"
// adding legendRGS on map
export var legendRoadNetwork = L.control({ position: "bottomright" });
legendRoadNetwork.onAdd = () => {
  const legendContainer = L.DomUtil.create("div", "img-div");
  const imgContainer = L.DomUtil.create("img");

  $(legendContainer).append("<strong><span style='font-size:12px'>Roads</span></strong>")
  imgContainer.src = GEOSERVER_URL + "/wms?" + generalQueryString + layerNameRoadNetwork
  $(legendContainer).append(imgContainer)
  return legendContainer;
};

// adding legendRGS on print map
export var legendRoadNetwork_2 = L.control({ position: "bottomright" });
legendRoadNetwork_2.onAdd = () => {
  const legendContainer = L.DomUtil.create("div", "img-div");
  const imgContainer = L.DomUtil.create("img");

  $(legendContainer).append("<strong><span style='font-size:12px'>Roads</span></strong>")
  imgContainer.src = GEOSERVER_URL + "/wms?" + generalQueryString + layerNameRoadNetwork
  $(legendContainer).append(imgContainer)
  return legendContainer;
};

var layerNameNCRBoundary = "&LAYER=NCR_Boundaries:ncr_boundary"
// adding legend on map
export var legendNCRBoundary = L.control({ position: "bottomright" });
legendNCRBoundary.onAdd = () => {
  const legendContainer = L.DomUtil.create("div", "img-div");
  const imgContainer = L.DomUtil.create("img");

  $(legendContainer).append("<strong><span style='font-size:12px'>Boundaries</span></strong>")
  imgContainer.src = GEOSERVER_URL + "/wms?" + generalQueryString + layerNameNCRBoundary
  $(legendContainer).append(imgContainer)
  return legendContainer;
};

// adding legend on print map
export var legendNCRBoundary_2 = L.control({ position: "bottomright" });
legendNCRBoundary_2.onAdd = () => {
  const legendContainer = L.DomUtil.create("div", "img-div");
  const imgContainer = L.DomUtil.create("img");

  $(legendContainer).append("<strong><span style='font-size:12px'>Boundaries</span></strong>")
  imgContainer.src = GEOSERVER_URL + "/wms?" + generalQueryString + layerNameNCRBoundary
  $(legendContainer).append(imgContainer)
  return legendContainer;
};
