import { Typography, Row, Col, Image } from "antd";
import { Footer } from "antd/lib/layout/layout";
import { CopyOutlined, MailFilled, FacebookOutlined } from "@ant-design/icons";
import FooterDost from "../../Assets/Images/landing/footer-dost.png"
import FooterPcieerd from "../../Assets/Images/landing/footer-pcieerd.png"
import FooterUpd from "../../Assets/Images/landing/footer-upd.png"
import FooterTcagpWhite from "../../Assets/Images/landing/footer-tcagp-white.png"

const { Title, Text, Paragraph } = Typography;

const FooterIcons = [FooterDost, FooterPcieerd, FooterUpd, FooterTcagpWhite]

export const LandingFooter = () => {

  return (
    <Footer className="landing-footer">
      <Row>
        <Col lg={{span: 12}} sm={{span: 24}}>
          <Title level={4} className="landing-label-white">
            AiRMoVE
          </Title>
          <Paragraph className="landing-label-white landing-footer-description">
            The <span style={{fontWeight: "bolder"}}>Ambient Air Remote Sensing, Modeling and Visualization Environment (AiRMoVE)</span> project is a two-year research undertaking supported by the Department of Science and Technology (DOST) and monitored by the Philippine Council for Industry, Energy, and Emerging Technology Research and Development (PCIEERD). In partnership with the following:
          </Paragraph>

          {FooterIcons.map(imgSrc => {
            return (
              <Image
                key={imgSrc}
                src={imgSrc}
                alt={"airmove"}
                preview={false}
                className="landing-footer-icon"
                height={30}
              />
            )
          })}
        </Col>
          
        <Col span={5}></Col>
        
        <Col lg={{span: 7}} sm={{span: 24}} style={{paddingBlock: "2rem"}}>
          <Text className="landing-label-white" style={{fontSize: 16, fontWeight: "bolder"}}>
            Have a Question?
          </Text>
          <Paragraph className="landing-footer-question">
            Do you have any query on mind? Or anyting you wanna share for improvement you can reach us at:
          </Paragraph>
          <Text className="landing-label-white" style={{fontWeight: "bolder"}}>
            <a target="_blank" href="https://www.facebook.com/projectairmove" style={{color: "white"}}>
              <FacebookOutlined className="landing-footer-icon" />
            </a>
            <MailFilled className="landing-footer-icon"/>
            <Text
              className="landing-label-white landing-footer-email"
              copyable={{
                tooltips: "Copy email",
                icon: <CopyOutlined style={{color: "#fff", paddingLeft: "25%"}}/>
              }}
            >
              {/* airmove.upd@up.edu.ph */}
              rvramos@up.edu.ph
            </Text>
          </Text>
        </Col>
      </Row>

      <Paragraph className="landing-label-white landing-label-center" style={{paddingTop: "2rem"}}>
        © 2022 Project AiRMoVE. All Rights Reserved.
      </Paragraph>
    </Footer>
  )
};
