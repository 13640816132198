import L from 'leaflet';
import $ from 'jquery';

import northArrow from '../../../Assets/northArrowIcon.svg'

export const northArrowControl = L.control({ position: "bottomleft" });
northArrowControl.onAdd = () => {
    const container = L.DomUtil.create("div", "info legend leaflet-control");
    $(container).append("<img src=" + northArrow + ">")
    return container;
};

export const northArrowControl_2 = L.control({ position: "bottomleft" });
northArrowControl_2.onAdd = () => {
    const container = L.DomUtil.create("div", "info legend leaflet-control");
    $(container).append("<img src=" + northArrow + ">")
    return container;
};
