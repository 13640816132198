import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MapContextProvider from './Contexts/MapContext';
import WindowContextProvider from './Contexts/WindowContext';
import SidebarSecondaryContextProvider from './Contexts/SidebarSecondaryContext';
import SatelliteImagesContextProvider from './Contexts/SatelliteImagesContext';
import GeographicalParametersContext from './Contexts/GeographicalParametersContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GeographicalParametersContext>
      <SatelliteImagesContextProvider>
        <SidebarSecondaryContextProvider>
          <WindowContextProvider>
            <MapContextProvider>
              <App />
            </MapContextProvider>
          </WindowContextProvider>
        </SidebarSecondaryContextProvider>
      </SatelliteImagesContextProvider>
    </GeographicalParametersContext>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
