import React, { 
  memo,
  useContext,
} from "react";
import {
  Space,
  Typography,
  Tooltip,
} from "antd";
import {
  ZoomInOutlined,
  CloseCircleFilled
} from "@ant-design/icons";

// import contexts and component
import { MapContext } from "../../../../Contexts/MapContext";

const { Text } = Typography;


const SidebarSecondaryTop = ({ layer }) => {
  const { 
    setViewDownloadableFileData,
    mapRef
  } = useContext(MapContext);
  
  function zoomToSelectedPoint(e, item) {
    // console.log("zoomToSelectedPoint item", item)
    let zoom = 13
    let center = [item.latitude, item.longitude]
    mapRef.invalidateSize()
    mapRef.flyTo(center, zoom)
  }

  return (
    <Space style={{display: "flex", justifyContent: "space-between"}}>
      <Space style={{display: "flex", justifyContent: "space-between"}}>
        <Text className="secondary-sidebar-title">
          Data from <span style={{textDecoration: "underline"}}>{layer?.location_name} ({layer?.mapping[0]["source"]})</span>
        </Text>
        <Tooltip placement="top" title="Zoom to layer">
          <Typography>
            <ZoomInOutlined 
              onClick={(e) => zoomToSelectedPoint(e, layer)}
            />
          </Typography>
        </Tooltip>
      </Space>
      <Tooltip placement="top" title="Close">
        <CloseCircleFilled
          style={{color: "gray"}}
          onClick={() => {
            setViewDownloadableFileData()
            setTimeout(function(){ mapRef.invalidateSize()}, 400);
          }}
        />
      </Tooltip>
    </Space>
  );
};

export default memo(SidebarSecondaryTop);
