import React, { 
  useState,
  memo,
  useEffect,
  useContext,
} from "react";
import {
  Space,
  Divider,
  // Typography,
  Button,
  Tooltip,
  // Checkbox,
  Switch,
  Slider,
  Col,
  Typography,
} from "antd";
import { 
  // FileSearchOutlined,
  ZoomInOutlined,
  DownloadOutlined,
  // ToolFilled,
  // MinusCircleFilled,
  // PlusOutlined,
} from "@ant-design/icons";

// import contexts and components
import { SidebarSecondaryContext } from "../../../../Contexts/SidebarSecondaryContext";
import { SatelliteImagesContext } from "../../../../Contexts/SatelliteImagesContext";
import { MapContext } from "../../../../Contexts/MapContext";
import SidebarSecondaryBottom from "./SidebarSecondaryBottom";
import SidebarSecondaryFilters from "./SidebarSecondaryFilters";
import SidebarSecondaryTop from "./SidebarSecondaryTop";
import "../../../../Assets/Styles/SidebarSecondary.css";
import GeoServerService from "../../GeoserverService";

const { Text } = Typography;


const SidebarSecondarySatelliteImages = ({ layer }) => {
  const [dataSource, setDataSource] = useState({});
  const [dataSourceShow, setDataSourceShow] = useState({});
  const [columns, setColumns] = useState([]);
  const [headers, setHeaders] = useState([]);
  const { satelliteParams } = useContext(SidebarSecondaryContext);
  const {
    satelliteImagesData, setSatelliteImagesData,
    sliderValueSatelliteImages, setSliderValueSatelliteImages,
  } = useContext(SatelliteImagesContext);
  const {
    mapRef,
  } = useContext(MapContext);
  // console.log("SidebarSecondarySatelliteImages", layer)
  // console.log("satelliteImagesData", satelliteImagesData)
  const parent = layer?.workspace

  useEffect(() => {
    // sort table data by date
    layer.layers.layer.sort((a,b) => new Date(a.date) - new Date(b.date));
    setDataSource(layer)
  }, [layer])

  // useEffect(() => {
  //   console.log("dataSource", dataSource)
  // }, [dataSource])

  // useEffect(() => {
  //   console.log("dataSourceShow", dataSourceShow)
  // }, [dataSourceShow])

  // useEffect(() => {
  //   if (layer && dataSource && layer.workspace === dataSource.workspace) {
  //     if (Object.keys(satelliteParams).length > 0) {
  //       if (satelliteParams.map_action !== undefined) {
  //         // DEFINED, value is true/false
  //         let filteredObjects
  //         if (satelliteParams.map_action) {
  //           filteredObjects = dataSource.layers.layer.filter(obj => obj.checked === satelliteParams.map_action);
  //         } else {
  //           filteredObjects = dataSource.layers.layer.filter(obj => obj.checked === undefined || obj.checked === satelliteParams.map_action);
  //         }
  //         // console.log("filteredd satelliteParams.map_action", satelliteParams.map_action)
  //         setDataSourceShow(dataSource => ({
  //           ...dataSource,
  //           "layers": {
  //             ...dataSource["layers"],
  //             "layer" : filteredObjects
  //           }
  //         }));
  //       } else {
  //         // console.log("undefined satelliteParams.map_action", satelliteParams.map_action)
  //         setDataSourceShow(dataSource)
  //       }
  //     }
  //   }
  // }, [satelliteParams, dataSource])

  function findIntersection(arrays) {
    // Use the first array as a base for comparison
    const baseArray = arrays[0];
    
    return baseArray.filter(obj1 => 
        arrays.every((arr, index) => 
            index === 0 || arr.some(obj2 => 
                // For demonstration, checking only based on 'id'
                obj2.name === obj1.name
            )
        )
    );
  }
  useEffect(() => {
    if (layer && dataSource && layer.workspace === dataSource.workspace) {
      if (Object.keys(satelliteParams).length > 0) {
        let filteredObjects1
        if (satelliteParams.period_start !== null && satelliteParams.period_end !== null) {
          var startDate = new Date(satelliteParams.period_start)
          // console.log("both dates ONLY")
          var endDate = new Date(satelliteParams.period_end)
          filteredObjects1 = dataSource.layers.layer.filter(a => {
            var date = new Date(a.date);
            return (date >= startDate && date <= endDate);
          });
        } else if (satelliteParams.period_start !== null) {
          // console.log("start date only")
          var startDate = new Date(satelliteParams.period_start)
          filteredObjects1 = dataSource.layers.layer.filter(a => {
            var date = new Date(a.date);
            return (date >= startDate);
          });
        } else if (satelliteParams.period_end !== null) {
          // console.log("end date only")
          var endDate = new Date(satelliteParams.period_end)
          filteredObjects1 = dataSource.layers.layer.filter(a => {
            var date = new Date(a.date);
            return (date <= endDate);
          });
        }
        // console.log("filteredObjects1", filteredObjects1)

        let filteredObjects2
        if (satelliteParams.map_action !== undefined) {
          // DEFINED, value is true/false
          if (satelliteParams.map_action) {
            filteredObjects2 = dataSource.layers.layer.filter(obj => obj.checked === satelliteParams.map_action);
          } else {
            filteredObjects2 = dataSource.layers.layer.filter(obj => obj.checked === undefined || obj.checked === satelliteParams.map_action);
          }
        } else {
          filteredObjects2 = dataSource.layers.layer
        }

        if (filteredObjects1 && filteredObjects2) {
          let intersection = findIntersection([filteredObjects1, filteredObjects2]);
          // console.log("intersection", intersection)
          setDataSourceShow(dataSource => ({
            ...dataSource,
            "layers": {
              ...dataSource["layers"],
              "layer" : intersection
            }
          }));
        } else if (filteredObjects1) {
          setDataSourceShow(dataSource => ({
            ...dataSource,
            "layers": {
              ...dataSource["layers"],
              "layer" : filteredObjects1
            }
          }));
        } else if (filteredObjects2) {
          setDataSourceShow(dataSource => ({
            ...dataSource,
            "layers": {
              ...dataSource["layers"],
              "layer" : filteredObjects2
            }
          }));
        }
      }
    }
  }, [satelliteParams, dataSource])
  
  function getColumnsFromObjectKeys(object) {    
    let listOfKeys = Object.keys(object)
    let headers = []
    let keysToRemove = [
    //   // "station",
    //   // "station_id",
    //   // "station_name",
      "key",
      "href",
      "checked",
      "date",
      "name",
    ]

    let columns = []
    for (const key of listOfKeys) {
      if (keysToRemove.includes(key)) { continue }
      let col = {};
      col["title"] = key.toUpperCase().replace("_", " ");
      col["dataIndex"] = key.toLowerCase();
      col["key"] = key.toLowerCase();
      // col["width"] = "40%";
      columns.push(col)
      headers.push(key)
    }
    
    // console.log("columns", columns)
    // console.log("headers", headers)
    return [columns, headers]
  };
  
  
  function addActionsToColumns(columns, sliderValueSatelliteImages) {
    const dateColumn = {
      title: 'DATE',
      dataIndex: 'Date',
      render: (_, record) => {
        return (
          <Typography>{record.date.format("YYYY-MM-DD HH:mm:ss")}</Typography>
        )
      }
    };
    const downloadAction = {
      title: 'DOWNLOAD',
      dataIndex: 'download',
      // width: "30%",
      // flex: 1,
      render: (_, record) => {
        return (
          <Space>
            <Tooltip placement="top" title={`Download ${record.name}.tiff`}>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                size={"small"}
                onClick={() => downloadTiff(parent, record.name)}
              />
            </Tooltip>
          </Space>
        )
      }
    };
    
    const mapAction = {
      title: 'MAP ACTIONS',
      dataIndex: 'mapActions',
      // width: "30%",
      render: (_, record) => {
        let matchItem
        let isChecked
        
        if (satelliteImagesData) {
          matchItem = satelliteImagesData[parent]["layers"]["layer"].find(item2 => item2.name === record.name)
          isChecked = matchItem?.checked
        }
        return (
          <Space direction="vertical">
              <Tooltip placement="top" title={!isChecked ? `Add to map` : "Remove from map"}>
              <Switch
                // needed so that when removing, checkbox value turns to false
                checked={isChecked ? isChecked : false}
                onChange={(e) => onSwitchChange(e, record)}
                checkedChildren="Remove" unCheckedChildren="Add"
                // handleBg={"#263F6B"}
                // handleShadow={"10px 2px 4px 0 rgba(0, 35, 11, 1)"}
              />
            </Tooltip>
            {isChecked &&
              <Slider
                defaultValue={sliderValueSatelliteImages && sliderValueSatelliteImages[record.name]? sliderValueSatelliteImages[record.name]: 100}
                onChange={(value) => setSliderValueSatelliteImages({...sliderValueSatelliteImages, [record.name]: value})}
                disabled={!isChecked}
                trackStyle={{backgroundColor: "#263F6B"}}
                railStyle={{backgroundColor: "#fff"}}
              />
            }
          </Space>
        )
      }
    };

    const zoomToLayerAction = {
      title: 'ZOOM',
      dataIndex: 'download',
      // width: "30%",
      flex: 4,
      render: (_, record) => {
        return (
          <Space>
            <Tooltip placement="top" title={`Zoom to ${record.name}`}>
              <Button
                type="primary"
                icon={<ZoomInOutlined />}
                size={"small"}
                onClick={(e) => zoomToLayer(e, layer?.layers.layer[0].name)}
              />
            </Tooltip>
          </Space>
        )
      }
    };
    
    columns.push(dateColumn)
    columns.push(mapAction)
    columns.push(zoomToLayerAction)
    columns.push(downloadAction)

    return columns
  };

  useEffect(() => {
    const initialColumns = (getColumnsFromObjectKeys(layer.layers.layer[0])[0])
    setColumns(addActionsToColumns(initialColumns, sliderValueSatelliteImages))
    setHeaders(getColumnsFromObjectKeys(layer.layers.layer[0])[1])
  }, [layer, satelliteImagesData, sliderValueSatelliteImages])

  function onSwitchChange (checked, child) {
    // console.log("onSwitchChange", checked, child)'
    setSatelliteImagesData(satelliteImagesData => ({
      ...satelliteImagesData,
      [parent]: {
        ...satelliteImagesData[parent],
        "layers": {
          ...satelliteImagesData[parent]["layers"],
          "layer" : 
            // this is a list
            satelliteImagesData[parent]["layers"]["layer"].map(item => {
              if (item.name == child.name) {
                return {
                  ...item,
                  "checked": checked
                }
              }
              return item
            })
        }
      },
    }));
    setDataSource(dataSource => ({
      ...dataSource,
      "layers": {
        ...dataSource["layers"],
        "layer" : 
          // this is a list
          dataSource["layers"]["layer"].map(item => {
            if (item.name == child.name) {
              return {
                ...item,
                "checked": checked
              }
            }
            return item
          })
      }
    }));
  };


  async function zoomToLayer(e, item) {
    const bboxAndSrs = await GeoServerService.getBoundingBox(item)
    const bbox = [
      [bboxAndSrs["minY"], bboxAndSrs["minX"]],
      [bboxAndSrs["maxY"], bboxAndSrs["maxX"]]
    ]
    // console.log("click zoomtoLayer bboxAndSrs", bboxAndSrs)
    // console.log("click zoomtoLayer bbox", bbox)
    mapRef.invalidateSize()
    mapRef.fitBounds(bbox)
  };

  async function downloadTiff (workspace, name) {
    try {
      GeoServerService.downloadTiff(workspace, name)
      .then(GET_COVERAGE_URL => {
        try {
          fetch(GET_COVERAGE_URL)
          .then((res) => {
            if (res.ok) {
              const a = document.createElement('a');
              a.href = GET_COVERAGE_URL;
              a.target = '_new'
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              URL.revokeObjectURL(GET_COVERAGE_URL);
            } else {
              console.error('Error fetching TIFF:', res.statusText);
            }
          })
        } catch (error) {
          console.error('Error fetching TIFF:', error.message);
        }
      })
    } catch (error) {
      console.error('Error fetching TIFF:', error.message);
    }
  };
  
  return (
    <Space 
      direction="vertical" 
      className="sidebar-secondary-container"
    >
      <SidebarSecondaryTop layer={layer} title={"Satellite Images"}/>
      <Divider style={{margin: 0}}/>
      
      <SidebarSecondaryFilters/>
      
      <br/>
      <Text italic>Showing {Object.keys(dataSourceShow).length > 0 && dataSourceShow.layers.layer.length + " / " + dataSource.layers.layer.length}</Text>
      {Object.keys(dataSourceShow).length > 0 ?
        <SidebarSecondaryBottom
          dataSource={dataSourceShow}
          columns={columns}
        /> :
        Object.keys(dataSource).length > 0 ?
        <SidebarSecondaryBottom
          dataSource={dataSource}
          columns={columns}
        />
        : null
      }
    </Space>
  );
};

export default memo(SidebarSecondarySatelliteImages);
