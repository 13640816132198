import React, { useState, useRef } from "react";
import {
  Button,
  Carousel,
  Col,
  Image,
  Modal,
  Row,
  Typography,
 } from "antd";
import { Content } from "antd/lib/layout/layout";
import { FaImages } from "react-icons/fa";

const { Title } = Typography;
const { iecs } = require("../../Data/iecs.json");

export const CarouselItem = ({item, folder_name}) => {
  const filename = folder_name + " " + item
  return (
    <Row justify={"center"} align={"middle"} style={{backgroundColor: "cyan"}}>
      <Image
        src={require(`../../Assets/Images/iecs/${filename}.png`)}
        alt={filename}
        preview={false}
      />
    </Row>
  )
};

const IecCard = (props) => {
  let folder_name = props.folder_name
  let items = props.items
  let filename = items.length > 0 ? folder_name + " " + items[0] : folder_name
  
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        backgroundColor: isHovered ? 'lightblue' : 'transparent',
        padding: isHovered ? '10px' : '0px',
      }}
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave}
    >
      <Image
        src={require(`../../Assets/Images/iecs/${filename}.png`)}
        alt={folder_name}
        preview={false}
      />
      
      {/* icon to display if there are more than 1 image */}
      {items.length > 1 &&
        <div style={{ position: 'absolute', top: '5%', right: '5%' }}>
          <FaImages
            fontSize={"24px"}
            color="#263F6B"
            style={{padding: 3, backgroundColor: "#fff", borderRadius: 2 }}
          />
        </div>
      }
    </div>
  )
};

export const MediaFiles = () => {
  const carouselRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalIec, setModalIec] = useState({});

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  const showModal = (iec) => {
    // console.log("showModal", iec)
    setModalIec(iec)
    setIsModalOpen(!isModalOpen);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const nextSlide = () => {
    carouselRef.current.next();
  };

  const prevSlide = () => {
    carouselRef.current.prev();
  };

  return (
    <Content className="landing-second">
      <Row justify={"center"}>
        <Col>
          <Title level={3} className="landing-label-primary landing-label-center" style={{fontWeight: "bolder"}}>
            Information, Education and Communication
          </Title>
          {/* <Title level={5} className="landing-label-white landing-label-center" style={{fontWeight: "bolder"}}>
            These are what we can do to serve you
          </Title> */}
        </Col>
      </Row>

      <Row justify={"center"}>
        {iecs.map((iec, index) => {
          return (
            <Col
              lg={{span: 6}} sm={{span: 12}} xs={{span: 24}}
              className="landing-card-third"
              onClick={() => showModal(iec)}
              style={{ 
                cursor: iec.items.length > 1 && "pointer",
              }}
              
            >
              <IecCard key={iec.folder_name + "-" + index} folder_name={iec.folder_name} items={iec.items}/>
            </Col>
          )
        })}
        <Modal
          open={isModalOpen && modalIec.items.length > 1}
          footer={null}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Carousel afterChange={onChange}
            dotPosition="top"
            ref={carouselRef}
          >
            {Object.keys(modalIec).length > 0 &&
              modalIec.items.map((item) => {
                return (
                  <CarouselItem folder_name={modalIec.folder_name} item={item}/>
                )
              })
            }
          </Carousel>
          <Button onClick={prevSlide} style={{ position: 'absolute', top: '50%', left: -50, transform: 'translateY(-50%)' }}>
            {"<"}
          </Button>
          <Button onClick={nextSlide} style={{ position: 'absolute', top: '50%', right: -50, transform: 'translateY(-50%)' }}>
            {">"}
          </Button>
        </Modal>
      </Row>
    </Content>
  )
};
