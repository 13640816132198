import React, { 
  memo,
  useContext,
  // useEffect,
} from "react";
import {
  Row,
  Col,
  DatePicker,
  Space,
  Typography,
  Select,
} from "antd";
import moment from "moment";

// import contexts
import { SidebarSecondaryContext } from "../../../../Contexts/SidebarSecondaryContext";
// import { SatelliteImagesContext } from "../../../../Contexts/SatelliteImagesContext";

const { Paragraph, Text } = Typography;


const SidebarSecondaryFilters = () => {
  const { satelliteParams, setSatelliteParams, dateFormat } = useContext(SidebarSecondaryContext)
  // const { satelliteImagesData } = useContext(SatelliteImagesContext)
  const mapVisualizationOptions = [
    {
      value: true,
      label: `Added to the map`,
    },
    {
      value: false,
      label: `Removed from the map`,
    },
  ];

  /**
   * 
   * @param {*} value - the value of the property or filter
   * @param {*} prop - the property name
   */
  const handleChange = (value, prop) => {
    setSatelliteParams({
      ...satelliteParams,
      [prop]: value,
    })
  };

  // useEffect(() => {
  //   console.log("Satellite params", satelliteParams)
  // }, [satelliteParams])

  return (
    <>
      <Space style={{display: "flex", justifyContent: "space-between"}}>
        <Text className="item-list-header">
          Downloadable Files
        </Text>
      </Space>
      <Space style={{display: "flex", justifyContent: "space-between"}}>
        <Paragraph className="description">
          Listed below are the files that are available to download.
        </Paragraph>
      </Space>
      <br/>

      <Row className="filter-row">
        <Col span={12}>Start Date:</Col>
        <Col span={12}>
          <DatePicker
            size="small"
            style={{width: "100%"}}
            value={satelliteParams.period_start ? moment(satelliteParams.period_start, dateFormat) : null}
            format={dateFormat}
            onChange={e => handleChange(e ? moment(e).format(dateFormat) : null, "period_start")}
            disabledDate={(current) => {
              if (satelliteParams.period_end) {
                return current > moment(satelliteParams.period_end).endOf('day')
              }
              return false
            }}
            showTime={{ format: 'HH:mm' }}
          />
        </Col>
      </Row>
      <Row className="filter-row">
        <Col span={12}>End Date:</Col>
        <Col span={12}>
          <DatePicker
            size="small"
            style={{width: "100%"}}
            value={satelliteParams.period_end ? moment(satelliteParams.period_end, dateFormat) : null}
            format={dateFormat}
            onChange={e => handleChange(e ? moment(e).format(dateFormat) : null, "period_end")}
            disabledDate={(current) => {
              if (satelliteParams.period_start) {
                return current < moment(satelliteParams.period_start).startOf('day')
              }
              return false
            }}
            showTime={{ format: 'HH:mm' }}
          />
        </Col>
      </Row>

      <Row className="filter-row">
        <Col span={12}>Map Action:</Col>
        <Col span={12}>
          <Select
            size="small"
            style={{width: "100%"}}
            value={satelliteParams.map_action}
            onChange={(value) => handleChange(value, "map_action")}
            options={mapVisualizationOptions}
            allowClear
          />
        </Col>
      </Row>
    </>
  );
};

export default memo(SidebarSecondaryFilters);
