import React, { useContext, useEffect, useState } from "react";
import {
  Marker,
  Tooltip as MapTooltip,
} from 'react-leaflet';
import { Row, Col, Typography, Card } from 'antd';

// import constants and functions, context, components
import { transformLocation, roundTo } from "../Functions";
import { MapContext } from "../../../Contexts/MapContext";
import { CloudIconSquare } from "../../../Components/MapIconSquare";
import '../../../Assets/Styles/StationMarker.css';
import _ from 'lodash';


const WeatherStationMarker = ({data}) => {
  const { 
    viewDownloadableFileData, setViewDownloadableFileData,
    weatherStationsData,
  } = useContext(MapContext)

  var isViewed = _.isEqual(data, viewDownloadableFileData)

  const CardContent = ({denrData}) => {
    return (
      <>
        {Object.entries(denrData).map(([key, value]) => {
          if (["name", "id", "id_and_name", "met_station_daily", "description"].includes(key)) { return }
            return (
              <Row className="card-row" key={value}>
                <Col><Typography>{key.charAt(0).toUpperCase() + key.slice(1)}</Typography></Col>
                <Col>
                  <Typography>
                    {key == "location" ? roundTo(transformLocation(value)[0], 3) + ", " + roundTo(transformLocation(value)[1], 3) : value}
                  </Typography>
                </Col>
              </Row>
            )
          })
        }
      </>
    )
  };

  return (
    <Marker
      position={transformLocation(data.location)} 
      icon={CloudIconSquare("red", isViewed)}
      key={data.id}
      eventHandlers={{
        click: (e) => {
          if (isViewed) {
            setViewDownloadableFileData()
          } else {
            setViewDownloadableFileData(weatherStationsData.results.find(d => d.id === data.id))
          }
        }
      }}
    >
      {/* MapTooltip is for guide only */}
      <MapTooltip direction="right" offset={[0, 0]}>
        <Card title={`${data.id} - ${data.name}`}>
          <div className="card-div">
            {Object.keys(data).length > 0 ? <CardContent denrData={data}/> : <Col><Typography>hm</Typography></Col>}
          </div>
        </Card>
      </MapTooltip>
    </Marker>
  )
};

export default WeatherStationMarker;
