import { useEffect } from "react";
import { useMap } from 'react-leaflet'
import L from 'leaflet';
import $ from 'jquery';
import "/node_modules/leaflet-geosearch/dist/geosearch.css";
import "../../../Assets/Styles/Geolocation.css";

// make new leaflet element
const Geolocation = (props) => {
    const position = props.position || "topleft"
    const map = useMap(); // access to leaflet map
    const getLocation = () => {
        map.locate().on("locationfound", function (e) {
            map.flyTo(e.latlng, map.getZoom());
        });
    };

    useEffect(() => {
       
        const geolocationControl = L.control({ position: position});
        geolocationControl.onAdd = () => {
        const legendContainer = L.DomUtil.create("button", "leaflet-control leaflet-bar leaflet-control-layers custom-button");
            $(legendContainer).append("<i class='geolocation-tooltip material-icons' style='font-size:14px'>my_location<span class='geolocation-tooltip-text'>Locate me</span></i>")
            legendContainer.onclick = function() {
                // console.log('buttonClicked');
                getLocation()
            }
            return legendContainer;
        }
        geolocationControl.addTo(map)

        return () => {
            if (map && geolocationControl instanceof L.Control) { 
                map.removeControl(geolocationControl);
            }
        }
    }, [props])

    return null // don't want anything to show up from this comp
}
export default (Geolocation);
