import React, { 
  useState,
  memo,
  useContext,
} from "react";
import {
  Button,
  Tooltip,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";

// import constants and functions, context, components
import { downloadImage, getCsv } from "../../Functions"
import { MapContext } from "../../../../Contexts/MapContext";
import CsvTab from "./CsvTab";
import CsvTable from "./CsvTable";
import CsvChart from "./CsvChart";


const SidebarSecondaryBottom = ({ dataSource, layer, columns, headers }) => {
  const [activeTab, setActiveTab] = useState("table");
  const { chartRefs } = useContext(MapContext)

  const tab_items = [
    {
      key: 'table',
      label: `Table`,
      children: <CsvTable dataSource={dataSource?.results} columns={columns} />,
    },
    {
      key: 'chart',
      label: `Chart`,
      children: <CsvChart dataSource={dataSource?.results} columns={columns} layer={layer}/>
    },
  ];


  const ExportTable = <Tooltip title="Export to CSV">
    <Button
      shape="circle" icon={<DownloadOutlined />}
      disabled={dataSource.length==0}
      onClick={() => getCsv(
        dataSource?.results, 
        headers,
        layer?.name,
      )}
    />
  </Tooltip>


  const ExportChart = <Tooltip title="Export to PNG">
  <Button
    shape="circle" icon={<DownloadOutlined />}
    disabled={dataSource.length==0}
    onClick={() => downloadImage(
      chartRefs,
      layer?.name,
    )}
  />
  </Tooltip>

  return (
    <CsvTab
      items={tab_items}
      tabBarExtraContent={activeTab === "table" ? ExportTable : ExportChart}
      onChange={(tab)=>(setActiveTab(tab))}
    />
  );
};

export default memo(SidebarSecondaryBottom);
