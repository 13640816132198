import React, { memo, useMemo, useContext } from "react";
import {
  Space,
  Checkbox,
  Tooltip,
  Typography,
  Collapse,
} from "antd";
import { 
  FileSearchOutlined,
  // MinusCircleFilled,
  // PlusOutlined,
} from "@ant-design/icons";
import { MapContext } from "../../../Contexts/MapContext";
import { dropDownIconHandler } from "../../../Components/Sidebar";
import "../../../Assets/Styles/CheckboxFilter.css";
import _ from 'lodash';

const { Panel } = Collapse;


// created a copy just in case we want to completely change the way it looks in other sections
const CheckboxFilterStations = ({ filters }) => {
  const hasPropertyChild = filters.hasOwnProperty('child') && filters.child.length > 0
  const parent = JSON.parse(JSON.stringify(filters.parent)).replace(/\s+/g, "")
  const { 
    weatherStationsData,
    viewDownloadableFileData, setViewDownloadableFileData,
    toggleValue, setToggleValue,
  } = useContext(MapContext)

  /**
   * If the filter has children,
   * this useMemo will initialize all child values to true 
   * 
   * Note:
   * We use useMemo, instead of useEffect, because 
   * useEffect is called AFTER the sidebar is rendered.
   * 
   * Hence, if useEffect is used, the checkboxes are initially set to false,
   * what we actually want is to set them to true by default.
   */
  // useMemo(()=>{
  //   if (hasPropertyChild) {
  //     if (Object.keys(toggleValue).length === 0) {
  //       // populates initially
  //       filters.child.forEach(v => toggleValue[parent+v] = true)
  //     }
  //     if (Object.keys(toggleValue).length > 0) {
  //       // succeeding populations
  //       filters.child.forEach(v => {
  //         if (!toggleValue.hasOwnProperty(parent+v)) {
  //           toggleValue[parent+v] = true
  //         }
  //       })
  //     }
  //   }
  // }, [filters.child, parent, hasPropertyChild, toggleValue])

  const onParentCheckboxChange = (e) => {
    for (const key in toggleValue) {
      if (key.startsWith(parent)) {
        toggleValue[key] = e.target.checked
      }
    };
   
    setToggleValue((prevToggle) => {
      return {
        ...prevToggle,
        ...toggleValue,
      };
    });
  }

  const onChildCheckboxChange = (e, child) => {
    setToggleValue({
      ...toggleValue, 
      [parent+child]: e.target.checked
    })
  };

  /**
   * Sets the selected id in number format: (zoomToSelectedId)
   * and its corresponding data in json format: (zoomToSelectedData)
   * Used for zooming the map to the item's location
   * @param {Event} e 
   * @param {string} item - The label of the item (e.g. "1 - Pasay")
   * @param {string} isViewed - True if data selected is same as the point
   */

  function selectDataPoint(e, item, isViewed) {
    let item_id = parseInt(item.split(" - ")[0])
    if (isViewed) {
      setViewDownloadableFileData()
    } else {
      setViewDownloadableFileData(weatherStationsData.results.find(m => m.id === item_id))
    }
  }

  const ChildCheckbox = ({data}) => {
    let item = data.id_and_name
    let isViewed = _.isEqual(data, viewDownloadableFileData)
    return (
      <div className="custom-checkbox-with-download">
        <Checkbox
          checked={toggleValue[parent+item]}
          value={item}
          onChange={(e)=>onChildCheckboxChange(e, item)}
          style={{ paddingLeft: "25px" }}
          className="custom-checkbox"
        >
          {item}
        </Checkbox>
        <Tooltip placement="top" title="View downloadable files">
          <Typography>
            <FileSearchOutlined
              onClick={(e) => selectDataPoint(e, item, isViewed)}
              style={{color: isViewed ? "#1890ff": "gray" }}
            />
          </Typography>
        </Tooltip>
      </div>
    )
  }

  function checkValues(obj, prefix) {
    for (const key in obj) {
      if (key.startsWith(prefix)) {
        if (!obj[key]) {
          return false;
        }
      }
    }
    return true;
  }

  function checkAnyTrue(obj, prefix) {
    for (const key in obj) {
      if (key.startsWith(prefix) && obj[key]) {
        return true;
      }
    }
    return false;
  }

  return (
    <Space direction="vertical" style={{width: "100%"}}>
      {hasPropertyChild? 
      <Collapse
        // defaultActiveKey={category.subMenu[0].name}
        expandIcon={(key) => dropDownIconHandler(key)}
        expandIconPosition="end"
        ghost
        collapsible="icon"
      >
        <Panel
          header={
            <Checkbox
              checked={checkValues(toggleValue, parent)}
              indeterminate={checkAnyTrue(toggleValue, parent) && !checkValues(toggleValue, parent)}
              onChange={(e)=>onParentCheckboxChange(e, filters.parent)}
              className="custom-checkbox"
            >
              {filters.parent}
            </Checkbox>
          }
          key={"header-checkbox"}
        >
          {/* {toggleValue ? hasPropertyChild ? filters.child.map((item) => {
            return (
              <ChildCheckbox item={item} key={item}/>
            )
          }):null:null} */}
          {toggleValue ? hasPropertyChild ? filters.child.map((item) => 
            weatherStationsData.results.map((item2) => {
              return (
                item == item2.id_and_name &&
                <ChildCheckbox data={item2} item={item2.id_and_name} key={item2.id_and_name}/>
              )
            })
          ):null:null}
        </Panel>
      </Collapse>
      :null}
    </Space>
  );
};

export default memo(CheckboxFilterStations);
