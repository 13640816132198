import L from 'leaflet';
import $ from 'jquery';
import * as url from "../../../Urls"
import "../../../Assets/Styles/FeatureInfo.css"

const GEOSERVER_URL = url.GEOSERVER_URL


// Function to capitalize the first letter of a string
const capitalizeString = (string) => {
    return string.replace("_", " ").toUpperCase();
};

// Function to check if a string is in camel case
const isCamelCase = (string) => {
    return /^[a-z][a-zA-Z0-9]*$/.test(string);
};

const splitCamelCase = (string) => {
    return string.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
};

const GENERATE_FEATURE_INFO_URL = (e, layerName, map) => {
    const WMSGeneralParameters = {
        "service": "WMS",
        "version": "1.1.1",
    }
    WMSGeneralParameters["REQUEST"] = "GetFeatureInfo"
    WMSGeneralParameters["LAYERS"] = layerName
    WMSGeneralParameters["QUERY_LAYERS"] = layerName
    WMSGeneralParameters["BBOX"] = map.getBounds().toBBoxString()
    WMSGeneralParameters["HEIGHT"] = map.getSize().y
    WMSGeneralParameters["WIDTH"] = map.getSize().x
    WMSGeneralParameters["FORMAT"] = "image/png"
    WMSGeneralParameters["SRS"] = "EPSG:4326"
    WMSGeneralParameters["INFO_FORMAT"] = "application/json"
    WMSGeneralParameters["X"] = parseInt(e.containerPoint.x, 10)
    WMSGeneralParameters["Y"] = parseInt(e.containerPoint.y, 10)

    // const { lat, lng } = e.latlng;
    // const viewResolution = map.getZoom();
    const queryString = new URLSearchParams(WMSGeneralParameters).toString();
    const GET_FEATURE_INFO_URL = `${GEOSERVER_URL}/wms?${queryString}`

    return GET_FEATURE_INFO_URL
}


export const featureInfoControl = L.control({position: 'bottomleft'});

featureInfoControl.onAdd = (map) => {
    const container = L.DomUtil.create("div", "leaflet-info-control leaflet-control-middle");
    const defaultString = "Click a layer on the map"
    $(container).append("<h4>" + defaultString +"</h4>")

    var lat
    var lng
    var previousLayerId
    var previousPolygonArrays = []

    const displayPropertiesInContainer = (properties) => {
        // console.log("displaying properties on container")
        // Object.entries(properties).forEach((value) => {
        //     if (value[1]) {
        //         // Check if the property name is in camel case
        //         const isCamel = isCamelCase(value[0]);
        //         // Split the camel-cased property name into words
        //         const formattedPropertyName = isCamel ? splitCamelCase(value[0]) : value[0];
        //         const propertyName = capitalizeString(formattedPropertyName)
        //         $(container).append("<h4>" + propertyName + ": " + value[1] + "</h4>")
        //     }
        // })
        var table = $('<table>').addClass('table');

        // Iterate over the data and append rows to the table
        $.each(properties, function(key, value) {
            if (value) {
                // Check if the property name is in camel case
                const isCamel = isCamelCase(key);
                // Split the camel-cased property name into words
                const formattedPropertyName = isCamel ? splitCamelCase(key) : key;
                const propertyName = capitalizeString(formattedPropertyName)
                var row = $('<tr>');
                row.append($('<td>').text(propertyName));    // Key column
                row.append($('<td>').text(value));  // Value column
                table.append(row);
            }

        });

        // Append the table to the container
        $(container).append(table);
        
    }

    const drawFeatureOnMap = (polygonArrays) => {
        polygonArrays.forEach((polygonArray) => {
            var polygonArray2 = polygonArray.map(function(point) {
                return [point[1], point[0]];  // Swap the coordinates
            });
            var polygon = L.polygon(polygonArray2);
            polygon.setStyle({
                weight: 5,
                color: 'yellow',
                dashArray: '',
                fillOpacity: 0
            }).addTo(map);
            previousPolygonArrays.push(polygon);
        })
    }

    const drawMultilineOnMap = (polylineArrays) => {
        // console.log("drawing multiline", polylineArrays)
        let polylineArrays2 = polylineArrays.map(([lng, lat]) => [lat, lng]);
        // console.log("polylineArrays2", polylineArrays2)
        var polyline =  L.polyline(polylineArrays2);
        polyline.setStyle({
            weight: 5,
            color: 'yellow'
        }).addTo(map);
        previousPolygonArrays.push(polyline); 
    }

    const drawPointOnMap = (latlng) => {
        var point = L.circle(latlng).addTo(map);
        point.setStyle({
            color: 'yellow',
            radius: 2
        }).addTo(map);
        previousPolygonArrays.push(point);
    }

    const removePreviousFeatureFromMap = (array) => {
        // removes previously selected feature
        // console.log("removes previously selected feature")
        array.forEach((polygonArray) => {
            map.removeLayer(polygonArray)
        })
    }

    map.on('click', function(e) {
        let props = {}
        let description = {
            "description": "No data selected."
        }
        lat = L.Util.formatNum(e.latlng.lat, 5)
        lng = L.Util.formatNum(e.latlng.lng, 5)
        L.DomEvent.disableClickPropagation(container);
        
        let layers = map?._layers || []
        let rasterLayers = Object.values(layers).filter(layer => layer instanceof L.TileLayer);
        let rasterLayersLen = rasterLayers.length;
        let topmostRaster
        let topmostRasterLayerName

        try {
            topmostRaster = rasterLayers[rasterLayersLen-1]
            topmostRasterLayerName = topmostRaster["options"]["layers"]
            // console.log("mapRef.rasterLayers", topmostRasterLayerName)
            let [workspace, layerName] = topmostRasterLayerName.split(":")
            props["lat"] = lat
            props["lng"] = lng
            props["workspace"] = workspace
            props["layerName"] = layerName
            
            const GET_FEATURE_INFO_URL = GENERATE_FEATURE_INFO_URL(e, layerName, map)
            console.log("GET_FEATURE_INFO_URL", GET_FEATURE_INFO_URL)

            // Clear the content of the container
            $(container).empty();

            fetch(GET_FEATURE_INFO_URL)
            .then((response) => response.json())
            .then((data) => {
                let features = data["features"][0]
                let properties = {...props, ...features["properties"]}

                // console.log("features", features);
                // console.log("properties", properties["layerName"], properties);
                
                // HIGHLIGHT A LAYER
                let layerId = features["id"]
                let geometry = features["geometry"]

                try {
                    // console.log("check if there is type")
                    let geometryType = geometry["type"]
                    let coordinates = geometry["coordinates"]

                    // console.log("geometryType", geometryType)
                    // note, coordinates' length can be greater than 1
                    if (previousLayerId !== layerId) {
                        // console.log("SELECTED LAYER IS THE NOT THE SAME AS PREVIOUS LAYER")
                        coordinates.forEach((polygonArrays, index) => {
                            if (index === 0 && !previousLayerId) {
                                // INITIAL 
                                // console.log("CHECK INITIAL", index)
                                if (geometryType === "MultiPolygon") {
                                    drawFeatureOnMap(polygonArrays)
                                } else if (geometryType === "MultiLineString") {
                                    drawMultilineOnMap(polygonArrays)
                                }
                                previousLayerId = layerId
                            } else if (index !== 0 && previousLayerId) {
                                // SUCCEEDING
                                // console.log("CHECK SUCCEEDING", index)
                                if (geometryType === "MultiPolygon") {
                                    drawFeatureOnMap(polygonArrays)
                                } else if (geometryType === "MultiLineString") {
                                    drawMultilineOnMap(polygonArrays)
                                }
                            } else if (index === 0 && previousLayerId !== layerId && previousPolygonArrays.length > 0) {
                                // SUCCEEDING
                                // removes previously selected feature, if selected layer is different from previous layer
                                removePreviousFeatureFromMap(previousPolygonArrays)
                                $(container).empty();
                                // console.log("CHECK new feature", index)
                                if (geometryType === "MultiPolygon") {
                                    drawFeatureOnMap(polygonArrays)
                                } else if (geometryType === "MultiLineString") {
                                    drawMultilineOnMap(polygonArrays)
                                }
                                previousLayerId = layerId
                            }
                            if (index === 0) {
                                displayPropertiesInContainer(properties)
                            }
                        })
                        
                    } else {
                        // console.log("SELECTED LAYER IS THE SAME AS PREVIOUS LAYER")
                        if (previousPolygonArrays.length > 0) {
                            // removes previously selected feature, if selected layer is same as previous layer
                            removePreviousFeatureFromMap(previousPolygonArrays)
                            $(container).empty();
                            $(container).append("<h4>" + defaultString +"</h4>")
                            previousLayerId = ""
                        }
                        
                    }
                } catch (err) {
                    console.log("For raster datasets (satellite images)", err, properties)
                    // console.log("catching properties", properties)
                    displayPropertiesInContainer(properties)
                    removePreviousFeatureFromMap(previousPolygonArrays)
                    // add point data for raster datasets
                    drawPointOnMap([properties["lat"], properties["lng"]]);
                    previousLayerId = properties["layerName"]
                }
                
            })
            .catch((error) => {
                let properties = {...props, ...description}
                console.log("Error on click", error, properties["lat"])
                displayPropertiesInContainer(properties)
                removePreviousFeatureFromMap(previousPolygonArrays)
                // add point data
                drawPointOnMap([properties["lat"], properties["lng"]]);
                previousLayerId = properties["description"]
            })
        } catch (err) {
            console.log("err", err)
            $(container).empty();
            $(container).append("<h4> No layer on the map </h4>")
            removePreviousFeatureFromMap(previousPolygonArrays)
            previousLayerId = ""
        }
    });
    
    return container;
};
