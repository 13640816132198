import React, { useState, memo, useEffect, useContext, useMemo } from "react";
import {
  Input,
  Space,
  Collapse,
  Typography,
} from "antd";

// import constants and functions, contexts, components
import * as url from "../../../Urls"
import { getPanelHeader, dropDownIconHandler } from "../../../Components/Sidebar";
import { MapContext } from "../../../Contexts/MapContext";
import CheckboxFilterStations from "./CheckboxFilterStations";
import CheckboxFilterWeatherStations from "./CheckboxFilterWeatherStations";
import CheckboxFilterSatelliteImages from "./CheckboxFilterSatelliteImages";
import CheckboxFilterGeographicalParameters from "./CheckboxFilterGeographicalParameters";
import "../../../Assets/Styles/Sidebar.css";
import CheckboxFilterLowCostSensors from "./CheckboxFilterLowCostSensors";


const { Panel } = Collapse;
const { Text } = Typography;
const API_URL = url.REACT_APP_API_URL
const STATION_URL = `${API_URL}/station/`
const TYPE_1_STATION_URL = `${API_URL}/station/?station_type=1`
const TYPE_2_STATION_URL = `${API_URL}/station/?station_type=2`
const WEATHER_STATION_URL = `${API_URL}/weather_station`
// station_type=1 only returns station_type = continuous

const CARE_API_URL = url.CARE_API_URL


const Sidebar = () => {
  const [search, setSearch] = useState("");
  const [filterMenu, setFilterMenu] = useState([]);
  const [allStationsChild, setAllStationsChild] = useState([]);
  const [allWeatherStationsChild, setAllWeatherStationsChild] = useState([]);
  const [manualStationsChild, setManualStationsChild] = useState([]);
  const [allLowCostSensorsChild, setAllLowCostSensorsChild] = useState([]);

  const {
    setStationsData,
    setWeatherStationsData,
    toggleValue,
    menu,
    setLowCostSensorsData
  } = useContext(MapContext);

  const airQualityItems = [
    {
      "name": "referenceGradeStations",
      "filterCategory": [
        {
          "parent": "Particulate Matter System", 
          "child": allStationsChild,
        },
        {
          "parent": "Manual - High Volume Samplers", 
          "child": manualStationsChild,
        },
      ]
    },
  ]

  const meteorologicalDataItems = [
    {
      "name": "weatherStations",
      "filterCategory": [
        {
          "parent": "Weather Stations Daily Data", 
          "child": allWeatherStationsChild,
        },
      ]
    },
  ]

  const lowCostSensorsDataItems = [
    {
      "name": "smallSensors",
      "filterCategory": [
        {
          "parent": "Small Sensors", 
          "child": allLowCostSensorsChild,
        },
      ]
    },
  ]


  useEffect(() => {
    if (search) {
      // console.log("searchhhh", search)
      const filteredMenu = [];
      menu.results.forEach(m => {
        const filteredSubMenu = [];
        const list =  m.submenus.map(sm => {
          if (sm.label.toLowerCase().includes(search.toLowerCase()) || sm.description.toLowerCase().includes(search.toLowerCase())) {
            filteredSubMenu.push(sm);
            return true;
          }
        });
        if (list.filter(Boolean).length) {
          filteredMenu.push({
            data_type: m.data_type,
            submenus: filteredSubMenu
          });
        }
      })
      setFilterMenu({
        "count": filteredMenu.length,
        "results": filteredMenu
      });
    } else {
      setFilterMenu(menu);
      // console.log("filterMenu", menu)
    }
  }, [search, menu]);

  useEffect(() => {
    /**
     * Fetches data from Airmove's API
     * 
     * What happens is that while in dev mode, the <React.StrictMode> tags located in our root index
     * stress tests our components hence rendering this useEffect TWICE
     * when in fact, what we actually want is to only run this effect ONCE
     * 
     * To do this, we set a new AbortController and cleanup this useEffect before unmounting
     * Source: https://javascript.plainenglish.io/why-should-you-use-cleanup-functions-in-reacts-useeffect-hook-bdff48bd9b3
     * Source: https://www.youtube.com/watch?v=MXSuOR2yRvQ
     */
    let controller = new AbortController();
    const signal = controller.signal;

    const loadData = async () => {
      // console.log("STATION_URL", STATION_URL)
      fetch(STATION_URL, {
        signal: signal,
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              // catches error for non-existent endpoints
              `This is an HTTP error: The status is ${res.status}`
            );
          }
          return res.json();
        })
        .then((body) => {
          body.results.forEach(obj => {
            obj["id_and_name"] = obj.id + " - " + obj.name; 
          });          
          setStationsData(body)
          // console.log("booboo", body)
        })
        .catch((e) => {
          console.log("error", e)
        })
        .finally(() => {
          // setLoading(false);
        });
    };

    loadData();
    return () => {
      controller?.abort();
    }
  }, [setStationsData]);

  useEffect(() => {
    let controller = new AbortController();
    const signal = controller.signal;

    const loadData = async () => {
      // console.log("TYPE_1_STATION_URL", TYPE_1_STATION_URL)
      fetch(TYPE_1_STATION_URL, {
        signal: signal,
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            // catches error for non-existent endpoints
            `This is an HTTP error: The status is ${res.status}`
          );
        }
        return res.json();
      })
      .then((body) => {
        // setStationsData(body)
        let allStationsChild = body.results.map((data) => (
          data.id + " - " + data.name
        ))
        setAllStationsChild(allStationsChild)
      })
      .catch((e) => {
        console.log("error", e)
      })
      .finally(() => {
        // setLoading(false);
      });
    };

    loadData();
    return () => {
      controller?.abort();
    }
  }, []);

  useEffect(() => {
    let controller = new AbortController();
    const signal = controller.signal;

    const loadData = async () => {
      // console.log("TYPE_2_STATION_URL", TYPE_2_STATION_URL)
      fetch(TYPE_2_STATION_URL, {
        signal: signal,
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              // catches error for non-existent endpoints
              `This is an HTTP error: The status is ${res.status}`
            );
          }
          return res.json();
        })
        .then((body) => {
          // setStationsData(body)
          let manualChild = body.results.map((data) => (
            data.id + " - " + data.name
          ))
          setManualStationsChild(manualChild)
        })
        .catch((e) => {
          console.log("error", e)
        })
        .finally(() => {
          // setLoading(false);
        });
    };

    loadData();
    return () => {
      controller?.abort();
    }
  }, []);

  useEffect(() => {
    let controller = new AbortController();
    const signal = controller.signal;

    const loadData = async () => {
      // console.log("WEATHER_STATION_URL", WEATHER_STATION_URL)
      fetch(WEATHER_STATION_URL, {
        signal: signal,
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              // catches error for non-existent endpoints
              `This is an HTTP error: The status is ${res.status}`
            );
          }
          return res.json();
        })
        .then((body) => {
          // console.log("body weather stations", body)
          body.results.forEach(obj => {
            obj["id_and_name"] = obj.id + " - " + obj.name; 
          }); 
          let child = body.results.map((data) => (
            data.id + " - " + data.name
          ))
          // console.log("child weather", child)
          setWeatherStationsData(body)
          setAllWeatherStationsChild(child)
        })
        .catch((e) => {
          console.log("error", e)
        })
        .finally(() => {
          // setLoading(false);
        });
    };

    loadData();
    return () => {
      controller?.abort();
    }
  }, []);

  useEffect(() => {
    let controller = new AbortController();
    const signal = controller.signal;

    const LOCATION_URL = CARE_API_URL + "location"
    // console.log("LOCATION_URL", LOCATION_URL)
    const loadData = async () => {
      // console.log("WEATHER_STATION_URL", WEATHER_STATION_URL)
      fetch(LOCATION_URL , {
        signal: signal,
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              // catches error for non-existent endpoints
              `This is an HTTP error: The status is ${res.status}`
            );
          }
          return res.json();
        })
        .then((body) => {
          var data = {
            "count": body.length,
            "results": body
          }
          // console.log("setting data LOCATION_URL", data)
          setLowCostSensorsData(data)
          // const child = body.map(entry => entry.mapping[0]["database_name"] + " - " + entry.mapping[0]["source"]);
          const child = body.map(entry => entry.location_name);
          // console.log("child", child)
          setAllLowCostSensorsChild(child)
        })
        .catch((e) => {
          console.log("error", e)
        })
        .finally(() => {
          // setLoading(false);
        });
    };

    loadData();
    return () => {
      controller?.abort();
    }
  }, []);




  /**
   * If the filter has children,
   * this useMemo will initialize all child values to true 
   * 
   * Note:
   * We use useMemo, instead of useEffect, because 
   * useEffect is called AFTER the sidebar is rendered.
   * 
   * Hence, if useEffect is used, the checkboxes are initially set to false,
   * what we actually want is to set them to true by default.
   */

  useEffect(() => {
    airQualityItems.map((item) => {
      // console.log("population item", item.filterCategory)
      if (item.filterCategory) {
        item.filterCategory.map((fc) => {
          const hasPropertyChild = fc.hasOwnProperty('child') && fc.child.length > 0
          const parent = JSON.parse(JSON.stringify(fc.parent)).replace(/\s+/g, "")
            if (hasPropertyChild) {
              if (Object.keys(toggleValue).length === 0) {
                // populates initially
                // console.log("populates initially")
                fc.child.forEach(v => toggleValue[parent+v] = true)
              }
              if (Object.keys(toggleValue).length > 0) {
                // succeeding populations
                // console.log("succeeding populations")
                fc.child.forEach(v => {
                  if (!toggleValue.hasOwnProperty(parent+v)) {
                    toggleValue[parent+v] = true
                  }
                })
              }
            }
        })
      }
    })
    meteorologicalDataItems.map((item) => {
      // console.log("population item", item.filterCategory)
      if (item.filterCategory) {
        item.filterCategory.map((fc) => {
          const hasPropertyChild = fc.hasOwnProperty('child') && fc.child.length > 0
          const parent = JSON.parse(JSON.stringify(fc.parent)).replace(/\s+/g, "")
            if (hasPropertyChild) {
              if (Object.keys(toggleValue).length === 0) {
                // populates initially
                // console.log("populates initially")
                fc.child.forEach(v => toggleValue[parent+v] = true)
              }
              if (Object.keys(toggleValue).length > 0) {
                // succeeding populations
                // console.log("succeeding populations")
                fc.child.forEach(v => {
                  if (!toggleValue.hasOwnProperty(parent+v)) {
                    toggleValue[parent+v] = true
                  }
                })
              }
            }
        })
      }
    })
    // console.log("meteorologicalDataItems", meteorologicalDataItems)
    // console.log("lowCostSensorsDataItems", lowCostSensorsDataItems)
    lowCostSensorsDataItems.map((item) => {
      // console.log("population item", item.filterCategory)
      if (item.filterCategory) {
        item.filterCategory.map((fc) => {
          const hasPropertyChild = fc.hasOwnProperty('child') && fc.child.length > 0
          const parent = JSON.parse(JSON.stringify(fc.parent)).replace(/\s+/g, "")
            if (hasPropertyChild) {
              if (Object.keys(toggleValue).length === 0) {
                // populates initially
                // console.log("populates initially")
                fc.child.forEach(v => toggleValue[parent+v] = true)
              }
              if (Object.keys(toggleValue).length > 0) {
                // succeeding populations
                // console.log("succeeding populations")
                fc.child.forEach(v => {
                  if (!toggleValue.hasOwnProperty(parent+v)) {
                    toggleValue[parent+v] = true
                  }
                })
              }
            }
        })
      }
    })
  
  }, [toggleValue, airQualityItems, meteorologicalDataItems, lowCostSensorsDataItems])
  // }, [toggleValue, airQualityItems, meteorologicalDataItems])
  
  return (
    <Space direction="vertical" className="sidebar-container" style={{height: "100vh"}}>
      <Input
        placeholder="Search dataset..."
        className="search-bar"
        onChange={e => setSearch(e.target.value)}
      />
     
     {filterMenu.count > 0 && filterMenu.results.map(category => (
        <Space direction="vertical" size={12} style={{ marginBottom: 51, width: "100%" }} key={category.data_type}>
          <Text className="item-list-header">{category.data_type}</Text>
          <Collapse
            // defaultActiveKey={category.subMenu[0].name}
            expandIcon={(key) => dropDownIconHandler(key)}
            expandIconPosition="end"
            ghost
          >
            {category.submenus.map((subsub) => (
              <Panel
                header={
                  getPanelHeader(
                    subsub,
                    subsub.name,
                    subsub.workspaces,
                    toggleValue
                  )
                }
                key={subsub.name}
                className="panel-container"
              >
                {
                  category.data_type === "Air Quality" &&
                  subsub.name !== "referenceGradeStations" &&
                  subsub.workspaces && subsub.workspaces.length > 0 ?
                   subsub.workspaces.map((workspace) => (
                      <CheckboxFilterSatelliteImages key={workspace.name} filters={workspace}/>
                    ))
                  :
                  category.data_type === "Air Quality" &&
                  subsub.name === "referenceGradeStations" ?
                  airQualityItems.map((item) => 
                    item.name === subsub.name &&
                    item.filterCategory.map((fc) => (
                      <CheckboxFilterStations key={fc.parent} filters={fc}/>
                    ))
                  ):
                  category.data_type === "Air Quality" &&
                  subsub.name === "smallSensors" ?
                  // "asdsad"
                  lowCostSensorsDataItems.map((item) => 
                    item.name === subsub.name &&
                    item.filterCategory.map((fc) => (
                      <CheckboxFilterLowCostSensors key={fc.parent} filters={fc}/>
                    ))
                  )
                  :
                  category.data_type === "Meteorological Data" &&
                  subsub.name === "weatherStations" ?
                  meteorologicalDataItems.map((item) => 
                    item.name === subsub.name &&
                    item.filterCategory.map((fc) => (
                      <CheckboxFilterWeatherStations key={fc.parent} filters={fc}/>
                    ))
                  )
                  :
                  category.data_type === "Meteorological Data" &&
                  subsub.name === "meteorologicalModels" &&
                  subsub.workspaces && subsub.workspaces.length > 0 ?
                   subsub.workspaces.map((workspace) => (
                      <CheckboxFilterSatelliteImages key={workspace.name} filters={workspace}/>
                    ))
                  :
                  category.data_type === "Geographical Parameters" &&
                  subsub.name !== "elevation" &&
                  subsub.workspaces && subsub.workspaces.length > 0 ?
                   subsub.workspaces.map((workspace) => (
                      <CheckboxFilterGeographicalParameters key={workspace.name} filters={workspace}/>
                    ))
                  :
                  category.data_type === "Geographical Parameters" &&
                  subsub.name === "elevation" &&
                  subsub.workspaces && subsub.workspaces.length > 0 ?
                   subsub.workspaces.map((workspace) => (
                      <CheckboxFilterSatelliteImages key={workspace.name} filters={workspace}/>
                    ))
                  :
                  category.data_type === "Emissions Inventory" &&
                  subsub.name === "lineSources" && 
                  subsub.workspaces && subsub.workspaces.length > 0 ?
                   subsub.workspaces.map((workspace) => (
                      <CheckboxFilterGeographicalParameters key={workspace.name} filters={workspace}/>
                    ))
                  :
                  "NO DATA"
                }
              </Panel>
            ))}
          </Collapse>
        </Space>
        
      ))}
    </Space>
  );
};

export default memo(Sidebar);
