import React, { 
  memo,
  useContext,
} from "react";
import {
  Space,
  Typography,
  Tooltip,
} from "antd";
import {
  // ZoomInOutlined,
  CloseCircleFilled
} from "@ant-design/icons";

// import contexts and component
import { MapContext } from "../../../../Contexts/MapContext";
// import { transformLocation } from "../../Functions";
// import GeoServerService from "../../GeoserverService";

const { Text } = Typography;


const SidebarSecondaryTop = ({ layer, title }) => {
  const { 
    setViewDownloadableFileData,
    mapRef
  } = useContext(MapContext);

  // console.log("SidebarSecondaryTop", layer)
  
  return (
    <Space style={{display: "flex", justifyContent: "space-between"}}>
      <Text className="secondary-sidebar-title">
        Data from <span style={{textDecoration: "underline"}}>{layer?.workspace}</span>
      </Text>
      <Tooltip placement="top" title="Close">
        <CloseCircleFilled
          style={{color: "gray"}}
          onClick={() => {
            setViewDownloadableFileData()
            setTimeout(function(){ mapRef.invalidateSize()}, 400);
          }}
        />
      </Tooltip>
    </Space>
  );
};

export default memo(SidebarSecondaryTop);
