import React, { createContext, useState } from 'react';

export const GeographicalParametersContext = createContext();

const GeographicalParametersContextProvider = (props) => {
    const [geographicalParametersData, setGeographicalParametersData] = useState();
    const [sliderValueGeographicalParameters, setSliderValueGeographicalParameters] = useState();

    return (
        <GeographicalParametersContext.Provider 
            value={{
                geographicalParametersData, setGeographicalParametersData,
                sliderValueGeographicalParameters, setSliderValueGeographicalParameters,
            }}
        >
            {props.children}
        </GeographicalParametersContext.Provider>
    );
}

export default GeographicalParametersContextProvider;
