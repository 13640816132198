import React, { memo } from "react";
import { Table } from "antd";
  
  
const CsvTable = (props) => {
  let dataSource
  let columns = props.columns
  if (props.dataSource) {
    props.dataSource.forEach((data) => {
      data.key = data.id
    })
    dataSource = props.dataSource
  }

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      size="small"
      style={{
        //   height: "100%",
          zIndex: 1000
      }}
      scroll={{ y: "100%" }}
      {...props}
    />
  );
};

export default memo(CsvTable);
