import React from "react";
import {
  MapContainer,
  // TileLayer,
  Marker,
  Popup,
  ZoomControl,
  GeoJSON,
  LayersControl
} from 'react-leaflet';
import L from "leaflet";
import BasemapLayer from "../../Components/BasemapLayer";

const { markers: markerData } = require("../../Data/markers.json");

const MapComponent = ({ mapStyle, footprint }) => {
  const BasemapLayers = [
    {
      "name": "CartoDB-Light",
      "attribution": "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors | <a href='https://carto.com/basemaps/' class='attribution'>Carto light_all</a>",
      "url": "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
      "checked": true
    },
  ]
  var greenIcon = color => new L.Icon({
    iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`,
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  return (
    <MapContainer
      style={mapStyle}
      center={[14.6014382,121.0498376]}
      zoom={12}
      scrollWheelZoom={false}
      zoomControl={false}
    >
      <LayersControl style={{zIndex: 10}}>
        <BasemapLayer layers={BasemapLayers}/>
      </LayersControl>
      <ZoomControl position="topright"/>
      {markerData.map((m, index) => (
        <Marker position={m.coordinates} icon={greenIcon(m.color)} key={index}>
          <Popup>
            {m.message}
          </Popup>
        </Marker>
      ))}
      {footprint && (
        <GeoJSON data={footprint} />
      )}
    </MapContainer>
  )
}

export default MapComponent;
