import React from "react";
import {
  MapContainer,
  // TileLayer,
  Marker,
  Popup,
  Tooltip,
  ZoomControl,
  GeoJSON,
  LayersControl
} from 'react-leaflet';
import L from "leaflet";
import BasemapLayer from "../../Components/BasemapLayer";
import { CardItems } from './Constants';


const MapComponent = ({ mapStyle, footprint }) => {
  const BasemapLayers = [
    {
      "name": "CartoDB-Light",
      "attribution": "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors | <a href='https://carto.com/basemaps/' class='attribution'>Carto light_all</a>",
      "url": "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
      "checked": false
    },
    {
      "name": "ESRI",
      "attribution": "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
      "url": "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      "checked": true
    }
  ]
  var greenIcon = color => new L.Icon({
    iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`,
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
  return (
    <MapContainer
      style={mapStyle}
      center={CardItems[1].coordinates}
      zoom={15}
      scrollWheelZoom={false}
      zoomControl={false}
    >
      <LayersControl style={{zIndex: 10}}>
        <BasemapLayer layers={BasemapLayers}/>
      </LayersControl>
      <ZoomControl position="topright"/>
      {/* {markerData.map((m, index) => ( */}
        {CardItems && <Marker position={CardItems[1].coordinates} icon={greenIcon("red")} key={CardItems[0].title}>
          <Tooltip style={{width: 400}}>
            {CardItems[1].description}
          </Tooltip>
        </Marker>
        }
      {/* ))} */}
      {footprint && (
        <GeoJSON data={footprint} />
      )}
    </MapContainer>
  )
}

export default MapComponent;
