// AWS
// export const REACT_APP_API_URL="https://api.airmove.xyz"
// export const REACT_APP_DENR_API_URL="http://203.160.167.131:3003/airManual"
export const REACT_APP_DENR_API_URL="http://103.240.122.18:3003/airManual"

// LOCAL
//export const REACT_APP_API_URL="http://localhost:8000"
// export const REACT_APP_API_URL="http://192.168.56.104:8000"
export const REACT_APP_API_URL="https://airmove.tcagp.upd.edu.ph:8000"
export const REACT_APP_AQI_LEGEND_URL=REACT_APP_API_URL+"/legends/aqi_legend/"

// GEOSERVER
// export const GEOSERVER_URL="http://192.168.56.104:8080/geoserver"
export const GEOSERVER_URL="https://airmove.tcagp.upd.edu.ph/geoserver"
// export const GEOSERVER_URL="http://localhost:8080/geoserver"

export const CARE_API_URL = "https://api.upcare.ph/api/"

