import { useContext, useEffect, useState } from "react";
import { useMap } from 'react-leaflet'
import L from 'leaflet';
import $ from 'jquery';
import { WindowContext } from "../../../Contexts/WindowContext";
import "/node_modules/leaflet-geosearch/dist/geosearch.css";

// make new leaflet element
const ShowLegendsButton = (props) => {
    const position = props.position || "topleft"
    const map = useMap(); // access to leaflet map
    const { legendsOpen, setLegendsOpen } = useContext(WindowContext)
    
    useEffect(() => {
       
        const control = L.control({ position: position });
        control.onAdd = () => {
            const container = L.DomUtil.create("button", "leaflet-control leaflet-bar title-and-logo-on-map");
            legendsOpen? $(container).append("<i>Hide Legends</i>") : $(container).append("<i>Show Legends</i>")
            // l$(container).append("<i> Close Datasets</i>") : $(container).append("<i>Show Legends</i>")
            container.onclick = function(e) {
                e.stopPropagation()
                setLegendsOpen(!legendsOpen)
            }
            return container;
        }
        control.addTo(map)
        // if (!legendsOpen) {
        //     control.addTo(map)
        // }

        return () => {
            if (map && control instanceof L.Control) { 
                map.removeControl(control);
            }
        }
    }, [props])

    return null // don't want anything to show up from this comp
}
export default (ShowLegendsButton);
